import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { ProductListResponse, ProductQueryParams } from '@frontend/product/types';

const endpoint = '/product-api/v1';

export class ProductRequirementClient extends APIClient {
    public static async fetchProductsNotInRequirement(
        accountId: string,
        spotId: string,
        queryParams?: ApiQueryParams<DefaultQueryParams | ProductQueryParams>
    ): Promise<ProductListResponse> {
        return await this.apiPaginated<ProductListResponse, DefaultQueryParams | ProductQueryParams>(
            `${endpoint}/accounts/${accountId}/spots/${spotId}/products`,
            queryParams
        );
    }
}
