import { EntityType } from '@frontend/common';
import { jobRequest } from '@frontend/import/events';
import { Job } from '@frontend/import/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const JobBadge = (props: ObjectBadgeProps<Job>) => {
    return (
        <ObjectBadge<Job>
            id={props.id}
            type={EntityType.JOB}
            mapDisplayName={(ac: Job) => ac.id}
            resolve={jobRequest}
            key={props.id}
            {...props}
        />
    );
};
