import { AuthorizationClient, UserPermissions } from '@frontend/authorization';
import { EntityType } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { UserGroupClient } from '@frontend/group/api';
import { useAccountRepository } from '@frontend/repository';
import { RoleTemplateClient } from '@frontend/role/api';
import { EntityTypePermissionTemplate } from '@frontend/role/types';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface ViewProps {
    object: any | undefined;
    mappedPermissions?: {
        result: Map<string, any>;
        mappedUids: Map<string, string[]>;
    };
    showExtraRights?: { [key: string]: boolean };
    changeShowExtraRights: React.Dispatch<React.SetStateAction<{ [key: string]: boolean }>>;
    entityTypePermissions: EntityTypePermissionTemplate;
    label: string;
}

const usePermissionsList = (): ViewProps => {
    const { entityType, entityId } = useParams();
    const { store } = useAccountRepository();
    const [object, changeObject] = useState<any | undefined>(undefined);
    const [mappedPermissions, changeMappedPermissions] = useState<{
        result: Map<string, any>;
        mappedUids: Map<string, string[]>;
    }>();
    const [showExtraRights, changeShowExtraRights] = useState<{ [key: string]: boolean }>({});
    const [entityTypePermissions, changeEntityTypePermissions] = useState<EntityTypePermissionTemplate>({});
    useEffect(() => {
        if (!store.currentAccount || !entityId || !entityType) return;
        fetch(store.currentAccount, entityId, entityType)?.then(changeObject);
        AuthorizationClient.fetchEntityPermissions(store.currentAccount, entityType.replace('-', '_').slice(0, -1) as EntityType, entityId).then((res) => {
            changeMappedPermissions(transformData(res));
        });
        RoleTemplateClient.fetchEntityTypePermissions().then(changeEntityTypePermissions).catch(ErrorHandler.handleError);
    }, [store.currentAccount, entityId, entityType]);

    return {
        object,
        mappedPermissions,
        changeShowExtraRights,
        showExtraRights,
        entityTypePermissions,
        label: label(object, entityType)
    };
};

export default usePermissionsList;

function transformData(data: UserPermissions) {
    const result = new Map<string, any>();
    const mappedUids = new Map<string, string[]>();

    for (const key in data) {
        for (const i in data[key]) {
            if (!result.get(i)) {
                result.set(i, data[key][i]);
            } else {
                result.set(i, {
                    ...combineObjects(result.get(i), data[key][i])
                });
            }
            for (const j in data[key][i]) {
                for (const uid in data[key][i][j]) {
                    if (!mappedUids.get(key)) {
                        mappedUids.set(key, [data[key][i][j][uid]]);
                    } else {
                        if (!mappedUids.get(key)!.includes(data[key][i][j][uid])) {
                            mappedUids.set(key, [...mappedUids.get(key)!, data[key][i][j][uid]]);
                        }
                    }
                }
            }
        }
    }
    return { result, mappedUids };
}

function combineObjects(a: { [key: string]: string[] }, b: { [key: string]: string[] }) {
    const temp = {};

    Object.keys(a).forEach((key) => {
        (temp as any)[key] = [...a[key]];
    });
    Object.keys(b).forEach((key) => {
        if (!(temp as any)[key]) {
            (temp as any)[key] = [...b[key]];
        } else {
            (temp as any)[key] = [...(temp as any)[key], ...b[key]];
        }
    });
    return temp;
}

const fetch = (accountId: string, entityId: string, entityType: string) => {
    switch (entityType) {
        case 'user-groups':
            return UserGroupClient.fetchUserGroup(accountId, entityId);
    }
};

const label = (object: any, entityType?: string) => {
    if (!object || !entityType) return;
    switch (entityType) {
        case 'user-groups':
            return object.name;
        case 'users':
            return object.first_name + ' ' + object.last_name;
        default:
            return object.id;
    }
};
