import React from 'react';
import { FormattedMessage } from 'react-intl';

export function isWeekend(date: Date): boolean {
    return [6, 0].includes(new Date(date).getDay());
}

export function isValidDate(dateString: string) {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{6}Z$/;
    if (!dateFormatRegex.test(dateString)) {
        return false;
    }
    const dateObject = new Date(dateString);
    return !isNaN(dateObject.getTime());
}

export function calculateDaysBetweenDates(startDate: Date, endDate: Date): number {
    const millisecondsPerDay = 24 * 60 * 60 * 1000;
    const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
    const daysDiff = Math.ceil(timeDiff / millisecondsPerDay);
    return daysDiff;
}

export function isLeapYear(year: number): boolean {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export enum DateStep {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    YEARLY = 'yearly',
    NONE = 'none'
}

export function convertDateStep(step: DateStep): string | undefined {
    switch (step) {
        case DateStep.WEEKLY:
            return 'week';
        case DateStep.MONTHLY:
            return 'month';
        default:
            return undefined;
    }
}

export function calculateTimeDifference(dateString: string): React.ReactNode {
    const now = new Date();
    const pastDate = new Date(dateString);
    const differenceInMilliseconds = now.getTime() - pastDate.getTime();

    const seconds = Math.floor(differenceInMilliseconds / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return (
            <FormattedMessage
                id='dates.days-ago'
                defaultMessage='{count, plural, one {# day} other {# days}} ago'
                values={{ count: days }}
            />
        );
    } else if (hours > 0) {
        return (
            <FormattedMessage
                id='dates.hours-ago'
                defaultMessage='{count, plural, one {# hour} other {# hours}} ago'
                values={{ count: hours }}
            />
        );
    } else if (minutes > 0) {
        return (
            <FormattedMessage
                id='dates.minutes-ago'
                defaultMessage='{count, plural, one {# minute} other {# minutes}} ago'
                values={{ count: minutes }}
            />
        );
    } else {
        return (
            <FormattedMessage
                id='dates.less-than-1-minute'
                defaultMessage='Less than 1 minute ago'
            />
        );
    }
}
