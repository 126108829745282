import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { CreateDevice, Device, DeviceListResponse, DeviceLogs, DeviceQueryParams, UpdateDevice } from '@frontend/device/types';

const endpoint = '/device-api/v1';

export class DeviceClient extends APIClient {
    public static async fetchDevices(queryParams?: ApiQueryParams<DefaultQueryParams | DeviceQueryParams>): Promise<DeviceListResponse> {
        return await this.apiPaginated<DeviceListResponse, DefaultQueryParams | DeviceQueryParams>(`${endpoint}/devices`, queryParams);
    }

    public static async resolveDevices(deviceIds: string[]): Promise<DeviceListResponse> {
        const response = await this.post(`${endpoint}/devices-resolve`, { ids: deviceIds });
        return await this.handleResponse<DeviceListResponse>(response);
    }

    public static async deleteDevices(deviceIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/devices-delete`, { ids: deviceIds });
        return await this.handleVoidResponse(response);
    }

    public static async postDevice(accountId: string, iotId: string, device: CreateDevice): Promise<Device> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/devices`, device);
        return await this.handleResponse<Device>(response);
    }

    public static async fetchDevice(accountId: string, iotId: string, deviceId: string): Promise<Device> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/iots/${iotId}/devices/${deviceId}`);
        return await this.handleResponse<Device>(response);
    }

    public static async patchDevice(accountId: string, iotId: string, deviceId: string, device: UpdateDevice): Promise<Device> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/iots/${iotId}/devices/${deviceId}`, device);
        return await this.handleResponse<Device>(response);
    }

    public static async deleteDevice(accountId: string, iotId: string, deviceId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/iots/${iotId}/devices/${deviceId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postHeartbeat(accountId: string, iotId: string, deviceId: string): Promise<Device> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/devices/${deviceId}/heartbeat`);
        return await this.handleResponse<Device>(response);
    }

    public static async postDeviceLogs(accountId: string, iotId: string, deviceId: string, logs: DeviceLogs): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/devices/${deviceId}/logs`, logs);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
