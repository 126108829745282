import { ApiQueryParams } from '@frontend/api-utils';
import { useEffect, useState } from 'react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

import { FilterModalProps } from './filter-modal.component';

interface ViewProps {
    params: ApiQueryParams<string | number>;
    changeParams: React.Dispatch<React.SetStateAction<ApiQueryParams<string | number>>>;
    setSearchParams: SetURLSearchParams;
    width: number;
}

const useFilterModal = <T extends object & { id: string }>(props: FilterModalProps<T>): ViewProps => {
    const [params, changeParams] = useState<ApiQueryParams<string | number>>(props.params);
    const [searchParams, setSearchParams] = useSearchParams();
    const [width, changeWidth] = useState<number>(window.innerWidth);
    useEffect(() => {
        window.addEventListener('resize', () => {
            changeWidth(window.innerWidth);
        });

        return () => {
            window.removeEventListener('resize', () => {});
        };
    }, []);
    return {
        params,
        changeParams,
        setSearchParams,
        width
    };
};

export default useFilterModal;
