import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Statistics = {
    VENDING: {
        TOTAL_FILL_RATE: (
            <FormattedMessage
                id='statistics.vending.totat-fill-rate'
                defaultMessage='Total Fill Rate %'
            />
        ),
        FILL_RATE_BY_PRODUCT: (
            <FormattedMessage
                id='statistics.vending.fill-rate-by-product'
                defaultMessage='Fill Rate % By Product'
            />
        ),
        FILL_RATE_BY_SPOT: (
            <FormattedMessage
                id='statistics.vending.fill-rate-by-spot'
                defaultMessage='Fill Rate % By Spot'
            />
        ),
        ACTIVITY_PER_HOUR: (
            <FormattedMessage
                id='statistics.vending.activity-per-hour'
                defaultMessage='Activity per hour'
            />
        ),
        ACTIVITY_PER_DAY: (
            <FormattedMessage
                id='statistics.vending.activity-per-day'
                defaultMessage='Activity per day'
            />
        ),
        ALL_EQUIPMENT_STATE: (
            <FormattedMessage
                id='statistics.vending.all-equipment-state'
                defaultMessage='All Equipment State'
            />
        ),
        AVAILABLE_EQUIPMENT_STATE: (
            <FormattedMessage
                id='statistics.vending.available-equipment-state'
                defaultMessage='Available Equipment State'
            />
        ),
        MACHINE_CONTENT: (
            <FormattedMessage
                id='statistics.vending.machine-content'
                defaultMessage='Machine Content'
            />
        ),
        REPLENISHMENT: (
            <FormattedMessage
                id='statistics.vending.replenishment'
                defaultMessage='Replenishment'
            />
        ),
        CONSUMPTION: (
            <FormattedMessage
                id='statistics.vending.consumption'
                defaultMessage='Consumption'
            />
        ),
        USER_CONSUMPTION: (
            <FormattedMessage
                id='statistics.vending.user-consumption'
                defaultMessage='User Consumption'
            />
        ),
        PRODUCT_CONSUMPTION: (
            <FormattedMessage
                id='statistics.vending.product-consumption'
                defaultMessage='Product Consumption'
            />
        )
    },
    LENDING: {
        AVAILABLE_EQUIPMENT: (
            <FormattedMessage
                id='statistics.lending.available-equipment'
                defaultMessage='Available Equipment'
            />
        ),
        EXPIRED_EQUIPMENT: (
            <FormattedMessage
                id='statistics.lending.expired-equipment'
                defaultMessage='Expired Equipment'
            />
        ),
        ALL_EQUIPMENT: (
            <FormattedMessage
                id='statistics.lending.all-equipment'
                defaultMessage='All Equipment'
            />
        ),
        IN_USE_EQUIPMENT: (
            <FormattedMessage
                id='statistics.lending.in-use-equipment'
                defaultMessage='In Use Equipment'
            />
        )
    }
};
