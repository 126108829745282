import { BooleanBadge } from '@frontend/elements';
import React from 'react';

export const BooleanCell = (props: { value: boolean, className?: string }) => {
    return (
        <td className={props.className || 'text-xs font-weight-bolder align-items-center'}>
            <BooleanBadge value={props.value} />
        </td>
    );
};
