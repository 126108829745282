import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { slotRequest } from '@frontend/slot/events';
import { Slot } from '@frontend/slot/types';
import React from 'react';

export const SlotBadge = (props: ObjectBadgeProps<Slot>) => {
    return (
        <ObjectBadge<Slot>
            id={props.id}
            type={EntityType.SLOT}
            mapDisplayName={(slot: Slot) => slot.display ?? ''}
            resolve={slotRequest}
            key={props.id}
            navigateString={(object: Slot) => `/accounts/${object.account_id}/spots/${object.spot_id}/modules/${object.module_id}/slots/${object.id}`}
            {...props}
        />
    );
};
