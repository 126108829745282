import { EntityType, Rights } from '@frontend/common';
import { CommonMessage } from '@frontend/lang';
import { ObjectList } from '@frontend/rendering/list';
import { RequirementClient } from '@frontend/stock/api';
import { Requirement } from '@frontend/stock/types';
import { lazy } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch } from '../redux/store';

const SpotBadge = lazy(() => import('@frontend/spot/badge').then((module) => ({ default: module.SpotBadge })));

const ID = 'requirement-overview';

interface RequirementOverviewProps {
    spot_id?: string;
}

export const RequirementOverview = (props: RequirementOverviewProps) => {
    const { spotId } = useParams();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return (
        <ObjectList<Requirement>
            id={ID}
            type={EntityType.STOCK_REQUIREMENT}
            dispatch={dispatch}
            label={CommonMessage.OBJECTS.REQUIREMENT.PLURAL}
            fetch={(arg) => RequirementClient.fetchRequirements({ spot_id: props.spot_id ?? spotId, ...arg })}
            eventListener={EntityType.STOCK}
            sortingColumns={['min', 'max', 'fill_percentage']}
            onSelectElement={(o) => navigate(`../requirements/${o.id}/detail`)}
            create={{
                onCreate: () => navigate('../requirements/create'),
                label: CommonMessage.OBJECTS.REQUIREMENT.DEFAULT,
                requiredPermissions: [{ item: EntityType.STOCK_REQUIREMENT, source: EntityType.ACCOUNT, right: Rights.CREATE, uid: '?' }]
            }}
            columns={{
                columns: ['product_id', 'variant_id', 'min', 'max', 'fill_percentage', 'fill_algorithm', 'location'],
                deleteAction: (accountId: string, spotId: string, requirementId: string) =>
                    RequirementClient.deleteRequirement(accountId, spotId, requirementId),
                headerOverwrite: new Map([
                    ['product_id', CommonMessage.OBJECTS.PRODUCT.DEFAULT],
                    ['variant_id', CommonMessage.OBJECTS.VARIANT.DEFAULT]
                ]),
                deleteKeys: ['account_id', 'spot_id', 'id'],
                displayName: (o) => o.id,
                customCells: new Map([
                    [
                        'location',
                        (o) => {
                            return (
                                <td className='align-items-center'>
                                    <SpotBadge id={o.row.original.spot_id} />
                                </td>
                            );
                        }
                    ]
                ]),
                updateAction: (o) => navigate(`../requirements/${o.id}/update`)
            }}
            multiSelectOptions={{
                delete: {
                    enabled: (t) => t.length > 0,
                    delete: (ids: string[]) => RequirementClient.deleteRequirements(ids)
                }
            }}
            filter={{
                default: true,
                keys: ['product_id'],
                type: 'platform'
            }}
            showCount
        />
    );
};

export default RequirementOverview;
