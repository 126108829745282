import { EntityType, useAppSelector } from '@frontend/common';
import { iconByType } from '@frontend/icons';
import { useLocale } from '@frontend/lang';
import { useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ResolveState, resolveStore } from '../resolve/resolve-slice';

export interface BadgeProps<T> {
    className?: string;
    callback?: (object: T) => void;
    type: EntityType;
    mapDisplayName: (entity: T) => string | undefined;
    resolve: (id: string) => string;
    id?: string;
    icon?: IconType | null;
    overWriteIcon?: (entity: T) => IconType;
    navigateString?: (entity: T) => string;
}

export function useBadgeRepository<T>(props: BadgeProps<T>) {
    const navigate = useNavigate();
    // const location = useLocation();
    const { locale } = useLocale();
    const resolveState = useAppSelector(useSelector, resolveStore);
    const [object, changeObject] = useState<T | null>(null);
    const [requested, changeRequested] = useState<boolean>(false);
    const [disabled, changeDisabled] = useState<boolean>(true);
    const [displayName, changeDisplayName] = useState<string | undefined>();
    const [resolverKey, changeResolverKey] = useState<string | undefined>();
    const [resolverPending, changeResolverPending] = useState<boolean>(true);
    const isPlatform = process.env['NX_PUBLIC_TASK_TARGET_PROJECT'] === 'platform';
    useEffect(() => {
        //Bit of a weird construct but prevents not found from showing prematurely
        let interval: string | number | NodeJS.Timeout | undefined = undefined;
        if (resolverKey && !object) {
            const check = () => {
                const value = localStorage.getItem(resolverKey);
                if (value != null && JSON.parse(value!) === true) {
                    changeResolverPending(true);
                } else {
                    changeResolverPending(false);
                    clearInterval(interval);
                }
            };

            check();
            interval = setInterval(check, 1000);
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [resolverKey]);

    useEffect(() => {
        if (props.id != undefined) {
            const found = findEntity(resolveState, props.type, props.id);
            if (found) {
                changeDisplayName(props.mapDisplayName(found as T));
                changeObject(found as T);
                changeDisabled(false);
                props.callback && props.callback(found as T);
            } else if (requested == false) {
                changeRequested(true);
                changeResolverKey(props.resolve(props.id));
            }

            if (requested && !found && !resolverPending) {
                changeDisabled(true);
                changeDisplayName('Not found');
            }
        } else {
            changeDisabled(true);
            changeDisplayName('None');
        }
    }, [resolveState, props.id, locale, resolverPending]);

    useEffect(() => {
        if (props.id != undefined) {
            const found = findEntity(resolveState, props.type, props.id);
            if (!found && requested) {
                changeResolverKey(props.resolve(props.id));
                changeDisplayName(undefined);
            }
        }
    }, [props.id]);

    const onClick = (e: any, s: string) => {
        e.stopPropagation();
        if (disabled) return;
        // const urlValue = props.type + '-' + props.id;
        // const queryParams = new URLSearchParams(location.search);
        // const detail = queryParams.get('details');
        // if (detail) {
        //     queryParams.set('details', JSON.stringify([...(JSON.parse(detail) as string[]), urlValue]));
        // } else {
        //     queryParams.set('details', JSON.stringify([urlValue]));
        // }
        // navigate({
        //     pathname: location.pathname,
        //     search: queryParams.toString()
        // });
        navigate(`/admin${s}/detail`);
    };

    return {
        displayName,
        disabled: disabled || !isPlatform,
        object,
        onClick,
        icon: iconByType(props.type),
        isPlatform
    };
}

const findEntity = (resolveState: ResolveState, type: EntityType, id: string) => {
    if (!resolveState.resolved) return null;
    if (!resolveState.resolved[type]) return null;
    if (!resolveState.resolved[type][id]) return null;
    return resolveState.resolved[type][id];
};
