import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const ProductCategoryOverview = lazy(() => import('./product-category-overview/product-category-overview'));
const ProductCategoryCreate = lazy(() => import('./product-category-create/product-category-create.component'));
const ProductCategoryDetail = lazy(() => import('./product-category-detail/product-category-detail.component'));
const ProductCategoryUpdate = lazy(() => import('./product-category-update/product-category-update.component'));
const ProductCategoryMemberAdd = lazy(() => import('./members/members-add/product-category-members-add.component'));
const ProductCategoryCategoryAdd = lazy(() => import('./members/category-add/category-add.component'));

export function ProductCategoryRoutes() {
    return (
        <Route path='product-categories'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <ProductCategoryOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ProductCategoryCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':groupId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <ProductCategoryDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ProductCategoryUpdate />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='products/add'
                    element={
                        <AsyncComponent>
                            <ProductCategoryMemberAdd />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='groups/add'
                    element={
                        <AsyncComponent>
                            <ProductCategoryCategoryAdd />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}

export default ProductCategoryRoutes;
