import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { spotRequest } from '@frontend/spot/events';
import { Spot } from '@frontend/spot/types';
import React from 'react';

export const SpotBadge = (props: ObjectBadgeProps<Spot>) => {
    return (
        <ObjectBadge<Spot>
            id={props.id}
            type={EntityType.SPOT}
            mapDisplayName={(spot: Spot) => spot.name}
            resolve={spotRequest}
            navigateString={(object: Spot) => `/accounts/${object.account_id}/spots/${object.id}`}
            {...props}
        />
    );
};
