import { useAggregatedListRepository, useListRepository } from '@frontend/repository';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Column, SortingRule } from 'react-table';

import { ObjectColumns } from '../object-column-mapper';
import { AggregatedObjectListProps } from './aggregated-object-list.component';

interface ViewProps<T extends object> extends ReturnType<typeof useListRepository<T>> {
    data: T[];
    columns: Column<T>[];
    changeSelectedObjects: Dispatch<SetStateAction<T[]>>;
    selectedObjects: T[];
    changeSortState: Dispatch<SetStateAction<SortingRule<object>[]>>;

    showMultiDeleteModal: boolean;
    changeShowMultiDeleteModal: Dispatch<SetStateAction<boolean>>;
    showImportModal: boolean;
    changeShowImportModal: Dispatch<SetStateAction<boolean>>;
    showExportModal: boolean;
    changeShowExportModal: Dispatch<SetStateAction<boolean>>;
}

const useAggregatedObjectList = <T extends { id: string }>(props: AggregatedObjectListProps<T>): ViewProps<T> => {
    const repository = useAggregatedListRepository<T>((arg) => props.fetch(arg), props.dispatch, props.eventListener, props.queryParams);
    const [selectedObjects, changeSelectedObjects] = useState<T[]>([]);
    const [showMultiDeleteModal, changeShowMultiDeleteModal] = useState<boolean>(false);
    const [showImportModal, changeShowImportModal] = useState<boolean>(false);
    const [showExportModal, changeShowExportModal] = useState<boolean>(false);

    const columns = useMemo(() => ObjectColumns<T>({ ...props.columns, selectEnabled: !!props.multiSelectOptions }), []);

    useEffect(() => {
        if (props.selectedObjects) {
            props.selectedObjects(selectedObjects);
        }
    }, [selectedObjects]);

    return {
        ...repository,
        data: repository.data ?? [],
        columns: columns,
        selectedObjects,
        changeSelectedObjects,
        showMultiDeleteModal,
        changeShowMultiDeleteModal,
        showImportModal,
        changeShowImportModal,
        showExportModal,
        changeShowExportModal
    };
};

export default useAggregatedObjectList;
