import { FaCheckCircle } from 'react-icons/fa';
import { FaCircleArrowDown, FaCircleArrowUp } from 'react-icons/fa6';
import { TbCancel } from 'react-icons/tb';

export const PUDO_ICONS = {
    DROP_OFF: FaCircleArrowDown,
    PICK_UP: FaCircleArrowUp,
    CANCEL: TbCancel,
    COMPLETE: FaCheckCircle
};
