import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { workflowRequest } from '@frontend/workflow/events';
import { Workflow } from '@frontend/workflow/types';
import React from 'react';

export const WorkflowBadge = (props: ObjectBadgeProps<Workflow>) => {
    return (
        <ObjectBadge<Workflow>
            id={props.id}
            type={EntityType.WORKFLOW}
            mapDisplayName={(w: Workflow) => w.name}
            resolve={workflowRequest}
            navigateString={(object: Workflow) => `/accounts/${object.account_id}/workflows/${object.id}`}
            key={props.id}
            {...props}
        />
    );
};
