import { Account } from '@frontend/account/types';
import { ErrorHandler } from '@frontend/error-handler';
import { FormatTypeName } from '@frontend/impex/types';
import { ToastUtil } from '@frontend/toast-utils';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ImportProps } from './import.component';

interface ViewProps {
    format: FormatTypeName;
    changeFormat: Dispatch<SetStateAction<FormatTypeName>>;
    accountId: string | null | undefined;
    changeAccountId: Dispatch<SetStateAction<string | null | undefined>>;
    importFile: File | null | undefined;
    changeImportFile: Dispatch<SetStateAction<File | null | undefined>>;
    onSubmit: (e: any) => void;
    errors: string[];
    dryRunPassed: boolean;
    resolveErrors: (e: any) => void;
    loading: boolean;
    changeAccount: Dispatch<SetStateAction<Account | null | undefined>>;
}

const useImport = (props: ImportProps): ViewProps => {
    const [format, changeFormat] = useState<FormatTypeName>(props.defaultValue ? props.defaultValue : FormatTypeName.XLSX);
    const [account, changeAccount] = useState<Account | null | undefined>(null);
    const [accountId, changeAccountId] = useState<string | null | undefined>(props.accountId);
    const [importFile, changeImportFile] = useState<File | null | undefined>(undefined);
    const [dryRunPassed, changeDryRunPassed] = useState<boolean>(false);
    const [errors, changeErrors] = useState<string[]>([]);
    const [loading, changeLoading] = useState<boolean>(false);
    const onSubmit = (e: any) => {
        e.preventDefault();
        if (!importFile || !accountId) return;
        if (dryRunPassed) {
            changeLoading(true);
        }
        props
            .import({ accountId: accountId, file: importFile, dry_run: dryRunPassed ? false : true })
            .then((res) => {
                if (res.status === 'succeeded') {
                    if (dryRunPassed) {
                        ToastUtil.success('Success', importMessage(props.type, account?.name || ''));
                        props.onClose();
                    }
                    changeDryRunPassed(true);
                } else {
                    changeErrors(res.errors);
                }
            })
            .catch(ErrorHandler.handleError);
    };

    const resolveErrors = (e: any) => {
        e.preventDefault();
        changeErrors([]);
        changeImportFile(undefined);
    };

    return {
        format,
        changeFormat,
        accountId,
        changeAccountId,
        changeImportFile,
        importFile,
        onSubmit,
        errors,
        dryRunPassed,
        resolveErrors,
        loading,
        changeAccount
    };
};
export default useImport;

const importMessage = (type: string, name: string) => {
    return (
        <FormattedMessage
            id='Import.Success.Message'
            description='Message for when a file has been exported'
            defaultMessage='Imported {type} for {name} successfully'
            values={{
                name: name,
                type: type
            }}
        />
    );
};
