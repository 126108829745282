import { APIClient } from '@frontend/api-utils';

import { DeviceDataTemplate } from '@frontend/device/types';

const endpoint = '/device-api/v1';

export class DeviceTemplateClient extends APIClient {
    public static async fetchDeviceDataTemplates(): Promise<DeviceDataTemplate> {
        const response = await this.fetch(`${endpoint}/device-data-templates`);
        return this.handleResponse<DeviceDataTemplate>(response);
    }
}
