import { useEffect, useState } from 'react';
import { SetURLSearchParams, useSearchParams } from 'react-router-dom';

import { Props } from './header-filter';

interface ViewProps {
    showModal: boolean;
    changeShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    setSearchParams: SetURLSearchParams;
}

const useHeaderFilter = <T extends object & { id: string }>(props: Props<T>): ViewProps => {
    const [showModal, changeShowModal] = useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(() => {
        if (!props.filter || !props.filter.keys) return;
        const params: { [key: string]: any } = { ...props.params };
        props.filter.keys.forEach((key) => {
            const override = props.filter?.queryParamKeyOverwrite?.get(key as string);
            if (typeof key === 'number') return;
            const found = searchParams.get(key);
            if (!found) return;
            params[override ?? key] = found;
        });
        if (Object.keys(params).length > 0) {
            props.changeParams((prev) => ({ ...prev, ...params }));
        }
    }, []);

    return {
        changeShowModal,
        showModal,
        setSearchParams
    };
};

export default useHeaderFilter;
