import { EntityType } from "@frontend/common";
import { ObjectBadge, ObjectBadgeProps } from "@frontend/rendering/badge";
import { slotTemplateRequest } from "@frontend/slot/events";
import { SlotTemplate } from "@frontend/slot/types";
import React from "react";

export const SlotTemplateBadge = (props: ObjectBadgeProps<SlotTemplate>) => {
    return (
        <ObjectBadge<SlotTemplate>
            id={props.id}
            type={EntityType.TEMPLATE}
            mapDisplayName={(template: SlotTemplate) => template.name}
            resolve={slotTemplateRequest}
            {...props}
        />
    );
};
