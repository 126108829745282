import { PaginatedResponse } from "@frontend/api-utils";
import { EntityType } from "@frontend/common";
import { DocumentInfo } from "@frontend/document/types";


export enum ImpexAction {
    EXPORT = 'export',
    IMPORT = 'import',
}

export enum ImpexChannel {
    FILE = 'file',
    EMAIL = 'email',
}

export enum ImpexFormatTypeEnum {
    XML = 'xml',
    XLSX = 'xlsx',
    CSV = 'csv',
    JSON = 'json',
    PDF = 'pdf'
}

export enum ImpexStateEnum {
    INIT = 'init',
    PROCESSING = 'processing',
    ERROR = 'error',
    DONE = 'done'
}

export interface ImpexLogs {
    detail: []
}

export interface Impex {
    format_type: ImpexFormatTypeEnum;
    entity_type: EntityType;
    channel: ImpexChannel;
    fields: string[];
    action: ImpexAction;
    state: ImpexStateEnum;
    file_info?: DocumentInfo | null;
    message?: string | null;
    logs?: ImpexLogs | null;
    id: string;
    account_id: string;
    creation_timestamp: string;
}

export type ImpexListResponse = PaginatedResponse<Impex>;

export interface CreateImpex {
    format_type?: ImpexFormatTypeEnum;
    entity_type?: EntityType;
    channel?: ImpexChannel | null;
    fields?: string[] | null;
    action?: ImpexAction;
    state?: ImpexStateEnum;
}

export interface UpdateImpex {
    format_type?: ImpexFormatTypeEnum | null;
    entity_type?: EntityType | null;
    channel?: ImpexChannel | null;
    fields?: string[] | null;
    action?: ImpexAction | null;
    state?: ImpexStateEnum | null;
}

export interface ImportResponse {
    status: 'error' | 'succeeded'
    errors: string[]
}

export enum ImpexQueryParams {
    ACCOUNT_ID = "account_id",
    USER_ID = "user_id",
    ENTITY_TYPE = "entity_type",
    ACTION = "action",
    IMPEX_ID = "impex_id",
}

export interface CreateImpexFile {
    file: File | null;
}
