import React from "react";
import { IconType } from "react-icons";

export interface DetailItemProps {
    order?: number;
    label?: React.ReactNode;
    icon?: IconType;
    identifier: string;
    children: React.ReactNode;
}

export const DetailItem = (props: DetailItemProps) => { 
    return (
        <>
            {props.children}
        </>
    )
}