import { ErrorHandler } from "@frontend/error-handler";
import React, { useEffect, useState } from "react";

interface Props<T> {
    object: T;
    fetchBlob: (object: T) => Promise<Blob>
}
export const AsyncBlobCell = <T,>(props: Props<T>) => {
    const [url, changeUrl] = useState<string | null>(null);
    useEffect(() => {
        let blobURL = "";
        props.fetchBlob(props.object)
            .then((res) => {
                if(res && res.size > 0) {
                    blobURL = URL.createObjectURL(res);
                    changeUrl(blobURL);
                }
            })
            .catch(ErrorHandler.handleError);
        return () => URL.revokeObjectURL(blobURL);
    }, [])

    return (
        <td className='align-items-center'>
            {url ? (
                <img
                    src={url}
                    alt={url}
                    style={{ height: '45px' }}
                />
            ) : <span style={{ height: '45px', width: '45px' }} className="placeholder-glow"></span>}
        </td>
    );
}