import { EntityType } from '@frontend/common';
import { ErrorHandler } from '@frontend/error-handler';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { EventListener, MqttBroker } from '@frontend/pub-sub';
import { clear, useAccountRepository } from '@frontend/repository';
import { ToastUtil } from '@frontend/toast-utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { DetailItem, DetailItemProps } from './detail-item/detail-item.component';
import { ObjectDetailProps2 } from './object-detail-2.component';

interface ViewProps<T> {
    object: T | null;
    showDeleteModal: boolean;
    changeShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    onDeleteConfirmed: () => void;
    onUpdate: () => void;
    subSections: DetailItemProps[];
    filteredSubSections: DetailItemProps[];
    activeSubSection: string | undefined;
    changeActiveSubSection: React.Dispatch<React.SetStateAction<string | undefined>>;
    main: React.ReactElement[];
}

const useObjectDetail2 = <T>(props: ObjectDetailProps2<T>): ViewProps<T> => {
    const navigate = useNavigate();
    const params = useParams();
    const { accountId } = useParams();
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const [object, changeObject] = useState<T | null>(null);
    const [showDeleteModal, changeShowDeleteModal] = useState<boolean>(false);
    const urlParams = useMemo(() => props.urlParamKeys.map((key) => params[key]), [props.urlParamKeys, params]);
    const [id] = useState<string>(crypto.randomUUID());
    const [subSections, changeSubSections] = useState<DetailItemProps[]>([
        { identifier: 'Main', label: CommonMessage.DETAIL.MAIN, icon: ICONS.DETAIL.MAIN, children: [] }
    ]);
    const [main, changeMain] = useState<React.ReactElement[]>([]);
    const [activeSubSection, changeActiveSubSection] = useState<string | undefined>(subSections ? subSections[0].identifier : undefined);

    useEffect(() => {
        if (object && props.children) {
            React.Children.forEach(props.children(object), (child) => {
                if (!child) return;
                if (!React.isValidElement(child) || !(child as React.ReactElement).props) return;
                if ((child as React.ReactElement).type == DetailItem) {
                    changeSubSections((prev) => {
                        const existingChild = prev.findIndex((section) => section.identifier === (child as React.ReactElement).props.identifier);
                        if (existingChild !== -1) {
                            const updatedSections = [...prev];
                            updatedSections[existingChild] = (child as React.ReactElement).props as DetailItemProps;
                            return updatedSections;
                        }
                        return [...prev, (child as React.ReactElement).props as DetailItemProps];
                    });
                    return;
                }
                if (!Array.isArray((child as React.ReactElement).props.children)) {
                    if ((child as React.ReactElement).type === DetailItem) {
                        changeSubSections((prev) => {
                            const existingIndex = prev.findIndex((section) => section.identifier === (child.props as DetailItemProps).identifier);
                            if (existingIndex !== -1) {
                                const updatedSections = [...prev];
                                updatedSections[existingIndex] = child.props as DetailItemProps;
                                return updatedSections;
                            }
                            return [...prev, child.props as DetailItemProps];
                        });
                    } else {
                        changeMain((prev) => {
                            const existingChild = prev.findIndex((c) => c.key === child.key);
                            if (existingChild !== -1) {
                                const updatedChildren = [...prev];
                                updatedChildren[existingChild] = child;
                                return updatedChildren;
                            }
                            return [...prev, child];
                        });
                    }
                    return;
                }
                const children = ((child as React.ReactElement).props.children as React.ReactElement[]).filter((child) => React.isValidElement(child));
                if (React.isValidElement(child) && children.length > 0) {
                    children.forEach((child) => {
                        if (child.type === DetailItem) {
                            changeSubSections((prev) => {
                                const existingIndex = prev.findIndex((section) => section.identifier === (child.props as DetailItemProps).identifier);
                                if (existingIndex !== -1) {
                                    const updatedSections = [...prev];
                                    updatedSections[existingIndex] = child.props as DetailItemProps;
                                    return updatedSections;
                                }
                                return [...prev, child.props as DetailItemProps];
                            });
                        } else {
                            changeMain((prev) => {
                                const existingChild = prev.findIndex((c) => c.key === child.key);
                                if (existingChild !== -1) {
                                    const updatedChildren = [...prev];
                                    updatedChildren[existingChild] = child;
                                    return updatedChildren;
                                }
                                return [...prev, child];
                            });
                        }
                    });
                }
            });
        }
    }, [props.children, object]);

    useEffect(() => {
        if (object) {
            props.object && props.object(object);
        }
    }, [object]);

    const onFetch = () => {
        if (urlParams.includes(undefined)) {
            ToastUtil.error('Something went wrong trying to get the correct information.');
            navigate(props.baseRoute.startsWith('/') ? props.baseRoute : '/admin/' + props.baseRoute);
        } else
            props
                .fetch(...urlParams)
                .then(changeObject)
                .catch(ErrorHandler.handleError);
    };

    useEffect(() => {
        onFetch();
        if (props.eventListener == undefined || !props.dispatch) return;

        if (props.eventListener instanceof EventListener) {
            const onEvent = (message: string) => {
                if (message.includes('created') || message.includes('deleted') || message.includes('multi') || message.includes('update')) onFetch();
            };

            props.eventListener.getInstance(props.dispatch).subscribe(onEvent);
            return () => {
                if (props.eventListener instanceof EventListener) props.eventListener.getInstance(props.dispatch!).unsubscribe(onEvent);
                props.dispatch!(clear(id));
            };
        } else {
            const onEvent = (message: string, data: any) => {
                if (message.includes('created') || message.includes('deleted') || message.includes('multi') || message.includes('update')) onFetch();
            };
            const topic = props.eventListener === EntityType.ACCOUNT ? 'account' : 'accounts/' + currentAccount + '/' + props.eventListener;
            MqttBroker.getInstance().subscribe(id, topic, onEvent);
            return () => {
                MqttBroker.getInstance().unSubscribe(id, props.eventListener as EntityType);
                props.dispatch!(clear(id));
            };
        }
    }, [currentAccount]);

    useEffect(() => {
        if (accountId !== undefined && currentAccount && accountId !== currentAccount) {
            navigate(props.baseRoute.startsWith('/') ? props.baseRoute : '/admin/' + props.baseRoute);
        }
    }, [currentAccount]);

    const onDeleteConfirmed = () => {
        props.onDelete(...urlParams).then(() => {
            changeShowDeleteModal(false);
            if (props.onDeleteSuccess) props.onDeleteSuccess();
            else navigate('../../');
        });
    };

    const onUpdate = () => {
        if (props.onUpdate) props.onUpdate();
        else {
            const urlKeys = props.updateKeys ? props.updateKeys : props.urlParamKeys;
            const urlParts = urlKeys.map(
                (key) =>
                    '/' +
                    (props.navigationKeyReplacements && props.navigationKeyReplacements[key]
                        ? props.navigationKeyReplacements[key] + '/'
                        : key.replace('Id', 's/')) +
                    params[key]
            );
            if (location.pathname.startsWith('/admin')) navigate('/admin' + urlParts.join('') + '/update');
            else navigate(urlParts.join('') + '/update');
        }
    };

    return {
        object,
        showDeleteModal,
        changeShowDeleteModal,
        onDeleteConfirmed,
        onUpdate,
        subSections,
        changeActiveSubSection,
        activeSubSection,
        filteredSubSections: subSections.filter((section) => section.identifier !== 'Main'),
        main
    };
};

export default useObjectDetail2;
