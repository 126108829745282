import { isUUID } from '@frontend/rendering/details';
import { useListRepository } from '@frontend/repository';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Column, SortingRule } from 'react-table';

import { ObjectColumns } from '../object-column-mapper';
import { ObjectListProps } from './object-list.component';

interface ViewProps<T extends object> extends ReturnType<typeof useListRepository<T>> {
    data: T[] | null;
    columns: Column<T>[];
    changeSelectedObjects: Dispatch<SetStateAction<T[]>>;
    selectedObjects: T[];
    changeSortState: Dispatch<SetStateAction<SortingRule<object>[]>>;

    showMultiDeleteModal: boolean;
    changeShowMultiDeleteModal: Dispatch<SetStateAction<boolean>>;
    showImportModal: boolean;
    changeShowImportModal: Dispatch<SetStateAction<boolean>>;
    showExportModal: boolean;
    changeShowExportModal: Dispatch<SetStateAction<boolean>>;
    filterKeys: string[];
    sortKeys: string[];
    mergeMaps: <K, V>(map1: Map<K, V>, map2?: Map<K, V>) => Map<K, V>;

    collapsed: boolean;
    changeCollapsed: Dispatch<SetStateAction<boolean>>;
}

const useObjectList = <T extends { id: string }>(props: ObjectListProps<T>): ViewProps<T> => {
    const repository = useListRepository<T>({
        fetch: (arg) => props.fetch(arg),
        dispatch: props.dispatch,
        eventListener: props.eventListener,
        filters: props.queryParams,
        refreshRef: props.refreshRef
    });
    const [selectedObjects, changeSelectedObjects] = useState<T[]>([]);
    const [showMultiDeleteModal, changeShowMultiDeleteModal] = useState<boolean>(false);
    const [showImportModal, changeShowImportModal] = useState<boolean>(false);
    const [showExportModal, changeShowExportModal] = useState<boolean>(false);
    const [filterKeys, changeFilterKeys] = useState<string[]>([]);
    const [sortKeys, changeSortKeys] = useState<string[]>([]);
    const [collapsed, changeCollapsed] = useState<boolean>((props.collapse && props.collapse.default && props.collapse.default === 'closed') ? true : false);

    const columns = useMemo(() => ObjectColumns<T>({ ...props.columns, selectEnabled: !!props.multiSelectOptions, managedSelect: props.managedSelect }), []);

    useEffect(() => {
        if (props.selectedObjects) {
            props.selectedObjects(selectedObjects);
        }
    }, [selectedObjects]);

    useEffect(() => {
        if (repository.data) {
            const obj = repository.data[0];
            if (!obj) return;
            else {
                const filters: string[] = [];
                const sorting: string[] = [];
                Object.keys(obj).forEach((key) => {
                    if (
                        (typeof (obj as any)[key] === 'string' ||
                        (obj as any)[key] === null) && (isUUID((obj as any)[key]) || key.includes('_id'))
                    ) {
                        if (key === 'account_id') return;
                        filters.push(key);
                    } else {
                        sorting.push(key);
                    }
                });
                changeFilterKeys(filters);
                changeSortKeys(sorting);
            }
        }
    }, [repository.data]);

    function mergeMaps<K, V>(map1: Map<K, V>, map2?: Map<K, V>): Map<K, V> {
        if (!map2) return map1;
        const mergedMap = new Map(map1);
        map2.forEach((value, key) => {
            mergedMap.set(key, { ...map1.get(key), ...value });
        });
        return mergedMap;
    }

    return {
        ...repository,
        data: repository.data,
        columns: columns,
        selectedObjects,
        changeSelectedObjects,
        showMultiDeleteModal,
        changeShowMultiDeleteModal,
        showImportModal,
        changeShowImportModal,
        showExportModal,
        changeShowExportModal,
        filterKeys,
        sortKeys,
        mergeMaps,
        collapsed,
        changeCollapsed
    };
};

export default useObjectList;
