import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { Token } from '@frontend/authentication-v2';
import { Impex, ImpexListResponse, CreateImpex, UpdateImpex, ImpexQueryParams, CreateImpexFile } from '@frontend/impex/types';

const endpoint = '/impex-api/v1';
export class ImpexClient extends APIClient {
    public static async fetchImpexs(queryParams?: ApiQueryParams<DefaultQueryParams | ImpexQueryParams>): Promise<ImpexListResponse> {
        return await this.apiPaginated<ImpexListResponse, DefaultQueryParams>(`${endpoint}/impexs`, queryParams);
    }

    public static async resolveImpexs(ids: string[]): Promise<ImpexListResponse> {
        const response = await this.post(`${endpoint}/impexs-resolve`, { ids: ids });
        return await this.handleResponse<ImpexListResponse>(response);
    }

    public static async deleteImpexs(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/impexs-delete`, { ids: ids });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountImpexs(accountId: string, queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<ImpexListResponse> {
        return await this.apiPaginated<ImpexListResponse, DefaultQueryParams>(`${endpoint}/accounts/${accountId}/impexs`, queryParams);
    }

    public static async postImpex(accountId: string, body: CreateImpex): Promise<Impex> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/impexs`, body);
        return await this.handleResponse<Impex>(response);
    }

    public static async fetchAccountImpex(accountId: string, impexId: string): Promise<Impex> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/impexs/${impexId}`);
        return await this.handleResponse<Impex>(response);
    }

    public static async patchImpex(accountId: string, impexId: string, body: UpdateImpex): Promise<Impex> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/impexs/${impexId}`, body);
        return await this.handleResponse<Impex>(response);
    }

    public static async deleteImpex(accountId: string, impexId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/impexs/${impexId}`);
        return await this.handleVoidResponse(response);
    }

    // Impex file

    public static async fetchImpexFile(accountId: string, impexId: string, withCredentials?: Token): Promise<Blob> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/impexs/${impexId}/file`, {}, true, withCredentials);
        return await this.handleBlobResponse(response);
    }

    public static async fetchImpexTemplateFile(accountId: string, impexId: string, withCredentials?: Token): Promise<Blob> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/impexs/${impexId}/impex-import-template`, {}, true, withCredentials);
        return await this.handleBlobResponse(response);
    }

    public static async generateImpexSasUrl(accountId: string, impexId: string): Promise<string> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/impexs/${impexId}/generate-file-sas-url`);
        return await this.handleResponse<string>(response);
    }

    public static async postImpexFile(accountId: string, impexId: string, body: CreateImpexFile): Promise<Impex> {
        const formData = new FormData();
        formData.append('file', body.file!);
        const response = await this.post(`${endpoint}/accounts/${accountId}/impexs/${impexId}/impex-import`, formData);
        return await this.handleResponse<Impex>(response);
    }

    public static async fetchImpexSasUrl(accountId: string, impexId: string): Promise<string> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/impexs/${impexId}/file-sas`);
        return await this.handleResponse<string>(response);
    }
}
