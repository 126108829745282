import { SelectInput } from '@frontend/basic-forms';
import { FormatTypeName } from '@frontend/impex/types';
import React from 'react';

import useFormatSelect from './format-select.controller';

const ID = 'format-select';

export interface FormatSelectProps {
    label?: string;
    className?: string;
    required?: boolean;
    submitted: boolean;
    onChange: (newValue: FormatTypeName) => void;
    value?: string | null;
}

export const FormatTypeSelect = (props: FormatSelectProps) => {
    const viewProps = useFormatSelect(props);

    return (
        <SelectInput
            id={ID}
            className={props.className}
            label={props.label || 'Format'}
            submitted={props.submitted}
            required={props.required}
            options={viewProps.options}
            value={viewProps.value}
            onChange={(newValue) => props.onChange((newValue as { value: FormatTypeName; label: React.ReactNode })?.value)}
        />
    );
};
