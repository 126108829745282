import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';
import { FaAnchor } from 'react-icons/fa';
import { Row } from 'react-table';

interface Props {
    className?: string;
    expandEnabled?: boolean;
    selectEnabled?: boolean;
    row: Row<any>;
    onClick?: (obj: any) => void;
    RowElement: (props: RowProps) => JSX.Element;
    /**
     * @deprecated
     */
    dispatch?: ThunkDispatch<any, any, Action>;
}

export function TRow(props: Props) {
    return (
        <props.RowElement
            className={props.className}
            row={props.row}
            selectEnabled={props.selectEnabled}
            expandEnable={props.expandEnabled}
            onClick={props.onClick}
        />
    );
}

export interface RowProps {
    className?: string;
    expandEnable?: boolean;
    selectEnabled?: boolean;
    row: Row<any>;
    onClick?: (element: any) => void;
    /**
     * @deprecated
     */
    dispatch?: ThunkDispatch<any, any, Action>;
}

export const RowAnchor = (props: RowProps) => {
    const onClick = () => {
        const page = window.open('', '_blank');
        const pre = page?.document.createElement('pre');
        if (!pre) return;
        pre.textContent = JSON.stringify(props.row.original, null, 2);
        page?.document.body.appendChild(pre);
        page?.document.close();
    };

    return (
        <button
            className={props.className || 'btn btn-outline-secondary px-1 pb-1 pt-0 my-0 mx-1'}
            onClick={onClick}>
            <FaAnchor />
        </button>
    );
};
