import { ClassType } from '@frontend/common';
import { HorizontalButtonGroup } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { ObjectDetailMapper } from '@frontend/rendering/details';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ObjectUpdateProps<T> {
    object: T | null;
    updateURL: string;
    title: React.ReactNode;
    missingObjectMessage?: React.ReactNode;
}

export const ObjectUpdate = <T extends object>(props: ObjectUpdateProps<T>) => {
    const navigate = useNavigate();
    return (
        <div className='card mt-3'>
            <div className='card-header d-flex'>
                <div className='d-flex justify-content-start col-4'>
                    <HorizontalButtonGroup
                        direction='left'
                        buttons={[
                            {
                                text: CommonMessage.BUTTONS.UPDATE,
                                icon: ICONS.BUTTON.UPDATE,
                                type: ClassType.PRIMARY,
                                onClick: () => navigate(props.updateURL)
                            }
                        ]}
                    />
                </div>
                <div className='d-flex justify-content-center col-4'>
                    <h4>{props.title}</h4>
                </div>
            </div>
            <div className='card-body'>
                {props.object ? (
                    <ObjectDetailMapper<T>
                        object={props.object}
                        order={Object.keys(props.object) as (keyof T)[]}
                    />
                ) : (
                    <div className='d-flex justify-content-center'>
                        <p>{props.missingObjectMessage || 'No data available'}</p>
                    </div>
                )}
            </div>
        </div>
    );
};
