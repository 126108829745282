import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { EntityType } from '@frontend/common';
import { JobClient } from '@frontend/import/api';
import { useResolver } from '@frontend/repository';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useJob:request': CustomEvent<string>;
    }
}

const REQUEST_JOB_EVENT = 'useJob:request';
const STORAGE_KEY = 'JOB_REQ';

export function jobRequest(jobId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_JOB_EVENT, { detail: jobId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function JobResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_JOB_EVENT, EntityType.JOB, (ids) => JobClient.resolveJobs(ids), props.dispatch);
    return <></>;
}