import React from 'react';
import { HeaderGroup } from 'react-table';

import { THeaderItem } from './THeaderItem';

interface Props {
    headerGroup: HeaderGroup<any>;
    sortingColumns?: string[];
    className?: string;
}

export function THeader(props: Props) {
    return (
        <tr>
            {props.headerGroup.headers.map((column) => (
                <THeaderItem
                    className={props.className}
                    key={column.id}
                    column={column}
                    sort={props.sortingColumns ? props.sortingColumns.includes(column.id) : false}
                />
            ))}
        </tr>
    );
}
