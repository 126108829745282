import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { EventEmitter, PubSubConnection } from './pub-sub-connection';

/**
 * @deprecated
 */
export abstract class EventListener<T> extends PubSubConnection {
    protected dispatch: ThunkDispatch<any, any, Action>;

    #emitter = new EventEmitter<string>();
    constructor(service: string, dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>) {
        super(service, variables);
        this.dispatch = dispatch;
    }
    protected override onMessageEvent(event: MessageEvent<string>): void {
        const payload = JSON.parse(event.data) as { message: string; data: T; changes?: any };
        this.#emitter.emit(payload.message, payload.data);
        if (payload.message.includes('updated')) {
            this.onUpdate(payload);
        } else if (payload.message.includes('deleted') && !payload.message.includes('multi')) {
            this.onDelete(payload);
        } else if (payload.message.includes('created') && !payload.message.includes('multi')) {
            this.onCreate(payload);
        }
    }

    abstract getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): EventListener<T>;
    protected abstract onUpdate(payload: { message: string; data: T; changes?: any }): void;
    protected abstract onDelete(payload: { message: string; data: T }): void;
    protected abstract onCreate(payload: { message: string; data: T; changes?: any }): void;

    subscribe(callback: (value: string, data?: T) => void) {
        this.#emitter.subscribe(callback);
    }

    unsubscribe(callback: (value: string) => void) {
        this.#emitter.unsubscribe(callback);
    }
}
