import { Product } from '@frontend/product/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class ProductEventListener extends EventListener<Product> {
    private static instance: ProductEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>) {
        super('product', dispatch, variables);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): ProductEventListener {
        if (this.instance == null) {
            this.instance = new ProductEventListener(dispatch, variables);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>, variables?: Array<{ [key: string]: any }>): EventListener<Product> {
        return ProductEventListener.getInstance(dispatch, variables);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
