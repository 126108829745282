import { DateTimeCellProps } from './date-time-cell.component';

interface ViewProps {
    date?: string;
    time?: string;
}

const useDateTimeCell = (props: DateTimeCellProps): ViewProps => {
    const date = props.date ? new Date(props.date).toLocaleDateString() : 'Unknown';
    const time = props.displayTime && props.date ? new Date(props.date).toLocaleTimeString() : 'Unknown';
    return {
        date,
        time
    };
};

export default useDateTimeCell;
