import { AsyncComponent } from '@frontend/elements';
import { IdentificationType } from '@frontend/identification/types';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const IdentificationCreate = lazy(() => import('../identification/identification-create/identification-create.component'));
const TagOverview = lazy(() => import('../tag/tag-overview'));
const TagDetail = lazy(() => import('../tag/tag-detail/tag-detail.component'));

export function TagRoutes() {
    return (
        <Route path='tags'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <TagOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <IdentificationCreate type={IdentificationType.TAG} />
                    </AsyncComponent>
                }
            />
            <Route path=':tagId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <TagDetail />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}

export default TagRoutes;
