import { APIClient, ApiQueryParams, buildApiEndpoint } from '@frontend/api-utils';
import { FillRateByProductData, FillRateBySpotData, StockChartQueryParams, TotalFillRateData } from '@frontend/stock/types';

const endpoint = '/stock-api/v1';

export class StockChartClient extends APIClient {
    public static async fetchTotalFillRate(accountId: string, queryParams?: ApiQueryParams<StockChartQueryParams>): Promise<TotalFillRateData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/fill-rate-total`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<TotalFillRateData>(response);
    }

    public static async fetchFillRateByProduct(accountId: string, queryParams?: ApiQueryParams<StockChartQueryParams>): Promise<FillRateByProductData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/fill-rate-by-product`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<FillRateByProductData>(response);
    }

    public static async fetchFillRateBySpot(accountId: string, queryParams?: ApiQueryParams<StockChartQueryParams>): Promise<FillRateBySpotData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/fill-rate-by-spot`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<FillRateBySpotData>(response);
    }
}
