import React from 'react';
import { IconType } from 'react-icons';

export interface PaginationNavigationButtonProps {
    id?: string;
    disabled?: boolean;
    onClick: () => void;
    icon: IconType;
    styles?: {
        default?: {
            li?: string;
            button?: string;
        };
        disabled?: {
            li?: string;
            button?: string;
        };
    };
}

export const PaginationNavigationButtonLiClass = 'page-item';
export const PaginationNavigationButtonButtonClass = 'page-link';
export const PaginationNavigationButtonLiClassDisabled = 'page-item disabled';
export const PaginationNavigationButtonButtonClassDisabled = 'page-link disabled';

export const PaginationNavigationButton = (props: PaginationNavigationButtonProps) => {
    const liClass = props.disabled
        ? (props?.styles?.disabled?.li ?? PaginationNavigationButtonLiClassDisabled)
        : (props?.styles?.default?.li ?? PaginationNavigationButtonLiClass);
    const buttonClass = props.disabled
        ? (props?.styles?.disabled?.button ?? PaginationNavigationButtonButtonClassDisabled)
        : (props?.styles?.default?.button ?? PaginationNavigationButtonButtonClass);
    return (
        <li
            id={props.id + '-pagination-nav-button'}
            className={liClass}
            onClick={props.disabled ? undefined : props.onClick}>
            <button className={buttonClass}>
                <span aria-hidden='true'>
                    <props.icon />
                </span>
            </button>
        </li>
    );
};

export default PaginationNavigationButton;
