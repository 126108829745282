import { ClassType, EntityType } from '@frontend/common';
import { ConfirmationModal, HorizontalButtonGroup, HorizontalButtonGroupButton, Spinner } from '@frontend/elements';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { EventListener } from '@frontend/pub-sub';
import { ObjectDetailMapper } from '@frontend/rendering/details';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

import useObjectDetail from './object-detail.controller';

export interface ObjectDetailProps<T> {
    fetch: (...args: any) => Promise<T>;
    onDelete?: (...args: any) => Promise<void>;
    onDeleteSuccess?: () => void;
    onUpdate?: () => void;
    /**
     * @description keys should be provided in the correct order to use the fetch and delete function
     */
    urlParamKeys: string[];
    /**
     * @description is used to route back in case of any failure or on delete
     */
    baseRoute: string;
    /**
     * @description is used to map navigation keys to their replacement values for URL construction
     */
    navigationKeyReplacements?: { [key: string]: string };
    updateKeys?: string[];
    eventListener?: EventListener<T> | EntityType;
    dispatch?: ThunkDispatch<any, any, Action>;
    id: string;
    loadingTitle?: React.ReactNode;
    objectHeader?: keyof T;
    /**
     * @description is used to map object keys to display
     */
    order: (keyof T)[];
    children?: React.ReactNode;
    deleteKey?: keyof T;
    deleteMessage?: React.ReactNode;
    object?: (entity: T | null) => void;
    headerClassName?: string;
    customHeaderButtons?: HorizontalButtonGroupButton[];
    keyOverwrite?: Map<keyof T, React.ReactNode>;
    valueOverwrite?: Map<keyof T, React.ReactNode>;
    hideUpdateButton?: boolean;
    customHeaderComponent?: React.ReactNode;
}
export const ObjectDetail = <T extends object>(props: ObjectDetailProps<T>) => {
    const viewProps = useObjectDetail<T>(props);

    if (!viewProps.object) {
        return (
            <div id={props.id}>
                <div className='card mt-3'>
                    <div className='card-header'>{props.loadingTitle}</div>
                    <div className='card-body'>
                        <Spinner />
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div
            id={props.id}
            className='d-flex flex-row flex-wrap justify-content-between'>
            <div className={props.headerClassName ? props.headerClassName : 'card mt-3 w-100'}>
                <div className='card-header d-flex justify-content-between'>
                    <h4>{props.objectHeader && (viewProps.object[props.objectHeader] as string)}</h4>
                    <div className='d-flex justify-content-end'>
                        <HorizontalButtonGroup
                            direction='left'
                            buttons={[
                                {
                                    onClick: () => viewProps.changeShowDeleteModal(true),
                                    icon: ICONS.BUTTON.DELETE,
                                    type: ClassType.DANGER,
                                    id: 'ObjectDetail.DeleteButton',
                                    text: CommonMessage.BUTTONS.DELETE,
                                    hide: props.deleteKey === undefined || props.onDelete === undefined
                                },
                                {
                                    hide: props.hideUpdateButton,
                                    onClick: viewProps.onUpdate,
                                    icon: ICONS.BUTTON.UPDATE,
                                    type: ClassType.INFO,
                                    id: 'ObjectDetail.UpdateButton',
                                    text: CommonMessage.BUTTONS.UPDATE
                                },
                                ...(props.customHeaderButtons || [])
                            ]}
                        />
                        {props.customHeaderComponent}
                    </div>
                </div>
                <div className='card-body d-flex flex-wrap justify-content-between'>
                    <ObjectDetailMapper<T>
                        object={viewProps.object}
                        order={props.order}
                        keyOverwrite={props.keyOverwrite}
                        valueOverwrite={props.valueOverwrite}
                    />
                </div>
            </div>
            {props.children}
            {props.deleteKey && viewProps.showDeleteModal && (
                <ConfirmationModal
                    handleClose={() => viewProps.changeShowDeleteModal(false)}
                    message={CommonMessage.FORMS.DELETE_OBJECT(props.deleteMessage, viewProps.object[props.deleteKey] as string)}
                    onConfirm={viewProps.onDeleteConfirmed}
                    severity={ClassType.DANGER}
                    show={viewProps.showDeleteModal}
                />
            )}
        </div>
    );
};
