import { Authentication } from '@frontend/authentication-v2';
import { AuthorizationProvider } from '@frontend/authorization';
import { ClassType, useLocalStorageState, useScripts } from '@frontend/common';
import { AsyncComponent, Profile } from '@frontend/elements';
import { EventType } from '@frontend/events/types';
import { Header } from '@frontend/header';
import { CommonMessage, TranslationProvider } from '@frontend/lang';
import { AccountStoreProvider, DocumentStoreProvider } from '@frontend/repository';
import { lazy } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { VERSION } from '../version';
import { BudgetRoutes } from './budget/budget-routes';
import { ConstraintRoutes } from './constraint/constraint-routes';
import { DeviceRoutes } from './device/device-routes';
import { EquipmentRoutes } from './equipment/equipment-routes';
import Footer from './footer/footer';
import IOTRoutes from './iot/iot-routes';
import Navbar from './navigation/navbar/navbar.component';
import NotificationTemplateRoutes from './notification-template/notification-template-routes';
import PermissionsList from './permissions/permissions-list.component';
import ProductCategoryRoutes from './product-category/product-category-routes';
import { useAppDispatch } from './redux/store';
import RequirementOverview from './requirement/requirement-overview';
import { StatisticRoutes } from './statistics/statistic-routes';
import TagRoutes from './tag/tag-routes';
import { UgentDemoRoutes } from './ugent-demo/ugent-demo-routes';
import Unauthorized from './unauthorized/unauthorized';
import UserImport from './user-import/user-import.component';
import { UserRoutes } from './user/user-routes';
import Resolver from './utils/resolver';
import { VariantRoutes } from './variant/variant-routes';

const Configuration = lazy(() => import('./configuration/configuration.component'));

// #region PuDo
const PuDoTransactionOverview = lazy(() => import('./pudo/pudo-transaction-overview/pudo-transaction-overview.component'));
const PudoTransactionCreate = lazy(() => import('./pudo/pudo-transaction-create/pudo-transaction-create.component'));
const PudoTransactionDetail = lazy(() => import('./pudo/pudo-transaction-detail/pudo-transaction-detail.component'));
// #endregion
// #region AddressBook
const AddressBookContactOverview = lazy(() => import('./address-book/contact-overview/contact-overview.component'));
const AddressBookContactCreate = lazy(() => import('./address-book/contact-create/contact-create.component'));
const AddressBookContactDetail = lazy(() => import('./address-book/contact-detail/contact-detail.component'));
const AddressBookContactUpdate = lazy(() => import('./address-book/contact-update/contact-update.component'));
// #endregion
// #region Accounts
const AccountOverview = lazy(() => import('./account/account-overview'));
const AccountCreate = lazy(() => import('./account/account-create/account-create.component'));
const AccountDetail = lazy(() => import('./account/account-detail/account-detail.component'));
const AccountUpdate = lazy(() => import('./account/account-update/account-update.component'));
const AccountBrandingUpdate = lazy(() => import('./account/branding-update/branding-update.component'));
// #endregion
// #region Address
const AddressOverview = lazy(() => import('./address/address-overview'));
const AddressCreate = lazy(() => import('./address/address-create/address-create.component'));
const AddressDetail = lazy(() => import('./address/address-detail/address-detail.component'));
const AddressUpdate = lazy(() => import('./address/address-update/address-update.component'));
const AddressEntityLink = lazy(() => import('./address/address-entity-link/address-entity-link.component'));
const EntityToAddressLink = lazy(() => import('./address/entity-to-address-link/entity-to-address-link.component'));
// #endregion
// #region User Groups
const UserGroupCreate = lazy(() => import('./user-group/user-group-create/user-group-create.component'));
const UserGroupOverview = lazy(() => import('./user-group/user-group-overview'));
const UserGroupDetail = lazy(() => import('./user-group/user-group-detail/user-group-detail.component'));
const UserGroupUpdate = lazy(() => import('./user-group/user-group-update/user-group-update.component'));
const UserGroupMemberAdd = lazy(() => import('./user-group/user-group-member-add/user-group-member-add.component'));
// #endregion
// #region Product Groups
const ProductGroupCreate = lazy(() => import('./product-group/product-group-create/product-group-create.component'));
const ProductGroupOverview = lazy(() => import('./product-group/product-group-overview'));
const ProductGroupDetail = lazy(() => import('./product-group/product-group-detail/product-group-detail.component'));
const ProductGroupUpdate = lazy(() => import('./product-group/product-group-update/product-group-update.component'));
const ProductGroupMemberAdd = lazy(() => import('./product-group/product-group-member-add/product-group-member-add.component'));
// #endregion
// #region Equipment Groups
const EquipmentGroupCreate = lazy(() => import('./equipment-group/equipment-group-create/equipment-group-create.component'));
const EquipmentGroupDetail = lazy(() => import('./equipment-group/equipment-group-detail/equipment-group-detail.component'));
const EquipmentGroupOverview = lazy(() => import('./equipment-group/equipment-group-overview'));
const EquipmentGroupUpdate = lazy(() => import('./equipment-group/equipment-group-update/equipment-group-update.component'));
const EquipmentGroupMemberAdd = lazy(() => import('./equipment-group/equipment-group-member-add/equipment-group-member-add.component'));
// #endregion
// #region Contact Groups
const ContactGroupCreate = lazy(() => import('./contact-group/contact-group-create/contact-group-create.component'));
const ContactGroupOverview = lazy(() => import('./contact-group/contact-group-overview/contact-group-overview'));
const ContactGroupDetail = lazy(() => import('./contact-group/contact-group-detail/contact-group-detail.component'));
const ContactGroupMemberAdd = lazy(() => import('./contact-group/contact-group-member-add/contact-group-member-add.component'));
const ContactGroupUpdate = lazy(() => import('./contact-group/contact-group-update/contact-group-update.component'));
// #endregion
// #region Cost center
const CostCenterDetail = lazy(() => import('./cost-center/cost-center-detail/cost-center-detail.component'));
const CostCenterOverview = lazy(() => import('./cost-center/cost-center-overview'));
const CostCenterCreate = lazy(() => import('./cost-center/cost-center-create/cost-center-create.component'));
const CostCenterUpdate = lazy(() => import('./cost-center/cost-center-update/cost-center-update.component'));
// #endregion
// #region Document
const DocumentOverview = lazy(() => import('./document/document-overview'));
const DocumentDetail = lazy(() => import('./document/document-detail/document-detail.component'));
const DocumentFileUpload = lazy(() => import('./document/file-upload/file-upload.component'));
// #endregion
// #region Roles
const RoleOverview = lazy(() => import('./role/role-overview'));
const RoleCreate = lazy(() => import('./role/role-create/role-create.component'));
const RoleDetail = lazy(() => import('./role/role-detail/role-detail.component'));
const RoleUpdate = lazy(() => import('./role/role-update/role-update.component'));
const RoleCreate2 = lazy(() => import('./role/role-create-2/role-create-2.component'));
const RoleCreatePredefined = lazy(() => import('./role/role-create-predefined/role-create-predefined.component'));
// #endregion
// #region Question Groups
const QuestionGroupCreate = lazy(() => import('./question-group/question-group-create/question-group-create.component'));
const QuestionGroupDetail = lazy(() => import('./question-group/question-group-detail/question-group-detail.component'));
const QuestionGroupOverview = lazy(() => import('./question-group/question-group-overview'));
const QuestionGroupUpdate = lazy(() => import('./question-group/question-group-update/question-group-update.component'));
const QuestionGroupMemberAdd = lazy(() => import('./question-group/question-group-member-add/question-group-member-add.component'));
const QuestionGroupMemberUpdate = lazy(
    () => import('./question-group/question-group-detail/question-group-member-update/question-group-member-update.component')
);
// #endregion
// #region Question
const QuestionOverview = lazy(() => import('./question/question-overview'));
const QuestionCreate = lazy(() => import('./question/question-create/question-create.component'));
const QuestionDetail = lazy(() => import('./question/question-detail/question-detail.component'));
const QuestionUpdate = lazy(() => import('./question/question-update/question-update.component'));
// #endregion
// #region Answer
const AnswerOverview = lazy(() => import('./question/answer/answer-overview'));
const AnswerCreate = lazy(() => import('./question/answer/answer-create/answer-create.component'));
const AnswerDetail = lazy(() => import('./question/answer/answer-detail/answer-detail.component'));
const AnswerUpdate = lazy(() => import('./question/answer/answer-update/answer-update.component'));
// #endregion
// #region Playlist
const PlaylistOverview = lazy(() => import('./playlist/playlist-overview'));
const PlaylistCreate = lazy(() => import('./playlist/playlist-create/playlist-create.component'));
const PlaylistDetail = lazy(() => import('./playlist/playlist-detail/playlist-detail.component'));
const PlaylistUpdate = lazy(() => import('./playlist/playlist-update/playlist-update.component'));
const PlaylistItemCreate = lazy(() => import('./playlist/playlist-item-create/playlist-item-create.component'));
const PlaylistItemDetail = lazy(() => import('./playlist/playlist-item-detail/playlist-item-detail.component'));
const PlaylistItemUpdate = lazy(() => import('./playlist/playlist-item-update/playlist-item-update.component'));
// #endregion
// #region Impex
const ImpexOverview = lazy(() => import('./impex/impex-overview'));
const ImpexCreate = lazy(() => import('./impex/impex-create/impex-create.component'));
const ImpexDetail = lazy(() => import('./impex/impex-detail/impex-detail.component'));
// #endregion
// #region Spots
const SpotOverview = lazy(() => import('./spot/spot-overview'));
const SpotCreate = lazy(() => import('./spot/spot-create/spot-create.component'));
const SpotDetail = lazy(() => import('./spot/spot-detail/spot-detail.component'));
const SpotUpdate = lazy(() => import('./spot/spot-update/spot-update.component'));
const SpotSettingsUpdate = lazy(() => import('./spot/spot-settings-update/spot-settings-update.component'));
// #endregion
// #region Modules
const ModuleOverview = lazy(() => import('./module/module-overview'));
const ModuleCreate = lazy(() => import('./module/module-create/module-create.component'));
const ModuleDetail = lazy(() => import('./module/module-detail/module-detail.component'));
const ModuleUpdate = lazy(() => import('./module/module-update/module-update.component'));
// #endregion
// #region Module templates
const ModuleTemplateOverview = lazy(() => import('./module-template/module-template-overview'));
const ModuleTemplateCreate = lazy(() => import('./module-template/module-template-create/module-template-create.component'));
const ModuleTemplateDetail = lazy(() => import('./module-template/module-template-detail/module-template-detail.component'));
const ModuleTemplateUpdate = lazy(() => import('./module-template/module-template-update/module-template-update.component'));
const ModuleTemplateCopy = lazy(() => import('./module-template/module-template-copy/module-template-copy.component'));
// #endregion
// #region Transaction
const TransactionOverview = lazy(() => import('./transaction/transaction-overview'));
const TransactionCreate = lazy(() => import('./transaction/pudo-transaction-create/pudo-transaction-create.component'));
const TransactionDetail = lazy(() => import('./transaction/transaction-detail/transaction-detail.component'));
// #region Slots
const SlotCreate = lazy(() => import('./slot/slot-create/slot-create.component'));
const SlotDetail = lazy(() => import('./slot/slot-detail/slot-detail.component'));
const SlotUpdate = lazy(() => import('./slot/slot-update/slot-update.component'));
// #endregion
// #region Slot templates
const SlotTemplateOverview = lazy(() => import('./slot-template/slot-template-overview'));
const SlotTemplateCreate = lazy(() => import('./slot-template/slot-template-create/slot-template-create.component'));
const SlotTemplateDetail = lazy(() => import('./slot-template/slot-template-detail/slot-template-detail.component'));
const SlotTemplateUpdate = lazy(() => import('./slot-template/slot-template-update/slot-template-update.component'));
const SlotTemplateCopy = lazy(() => import('./slot-template/slot-template-copy/slot-template-copy.component'));
// #endregion
// #region Products
const ProductOverview = lazy(() => import('./product/product-overview'));
const ProductCreate = lazy(() => import('./product/product-create/product-create.component'));
const ProductDetail = lazy(() => import('./product/product-detail/product-detail.component'));
const ProductUpdate = lazy(() => import('./product/product-update/product-update.component'));
const AddCertificate = lazy(() => import('./product/product-detail/add-certificate/add-certificate.component'));
// #endregion
// #region Requirements
const RequirementCreate = lazy(() => import('./requirement/requirement-create/requirement-create.component'));
const RequirementDetail = lazy(() => import('./requirement/requirement-detail/requirement-detail.component'));
const RequirementUpdate = lazy(() => import('./requirement/requirement-update/requirement-update.component'));
// #endregion
// #region Variants
const AttributeOverview = lazy(() => import('./attribute/attribute-overview'));
const AttributeCreate = lazy(() => import('./attribute/attribute-create/attribute-create.component'));
const AttributeDetail = lazy(() => import('./attribute/attribute-detail/attribute-detail.component'));
const AttributeUpdate = lazy(() => import('./attribute/attribute-update/attribute-update.component'));
const AttributeLinkingDetail = lazy(() => import('./attribute/attribute-linking-detail/attribute-linking-detail.component'));
const AttributeLinkingCreate = lazy(() => import('./attribute/attribute-linking-create/attribute-linking-create.component'));
const AttributeLinkingUpdate = lazy(() => import('./attribute/attribute-linking-update/attribute-linking-update.component'));
// #endregion
// #region Equipment Problems
const ProblemCreate = lazy(() => import('./problem/problem-create/problem-create.component'));
const ProblemDetail = lazy(() => import('./problem/problem-detail/problem-detail.component'));
const ProblemUpdate = lazy(() => import('./problem/problem-update/problem-update.component'));
const ProblemOverview = lazy(() => import('./problem/problem-overview/problem-overview'));
const ProblemEntityLink = lazy(() => import('./problem/problem-entity-link/problem-entity-link/problem-entity-link.component'));
// #endregion
// #region License
const LicenseCreate = lazy(() => import('./license/license-create/license-create.component'));
// #endregion
// #region Certificates
const CertificateOverview = lazy(() => import('./certificate/certificate-overview/certificate-overview'));
const CertificateCreate = lazy(() => import('./certificate/certificate-create/certificate-create.component'));
const CertificateUpdate = lazy(() => import('./certificate/certificate-update/certificate-update.component'));
const CertificateDetail = lazy(() => import('./certificate/certificate-detail/certificate-detail.component'));
// #endregion
// #region Identifications
const IdentificationDetail = lazy(() => import('./identification/identification-detail/identification-detail.component'));
// #endregion
// #region Workflows
const WorkflowCreate = lazy(() => import('./workflow/workflow-create/workflow-create.component'));
const WorkflowUpdate = lazy(() => import('./workflow/workflow-update/workflow-update.component'));
const WorkflowDetail = lazy(() => import('./workflow/workflow-detail/workflow-detail.component'));
const WorkflowOverview = lazy(() => import('./workflow/workflow-overview'));
const WorkflowCopy = lazy(() => import('./workflow/workflow-copy/workflow-copy.component'));
const StepCreate = lazy(() => import('./workflow/step/step-create/step-create.component'));
const FieldsUpdate = lazy(() => import('./workflow/fields/fields-update/fields-update.component'));
// #endregion
// #region User interfaces
const UserInterfaceCopy = lazy(() => import('./user-interface/user-interface-copy/user-interface-copy.component'));
const UserInterfaceCreate = lazy(() => import('./user-interface/user-interface-create/user-interface-create.component'));
const UserInterfaceUpdate = lazy(() => import('./user-interface/user-interface-update/user-interface-update.component'));
const UserInterfaceDetail = lazy(() => import('./user-interface/user-interface-detail/user-interface-detail.component'));
const UserInterfaceButtonCreate = lazy(() => import('./user-interface/user-interface-button-create/user-interface-button-create.component'));
const UserInterfaceOverview = lazy(() => import('./user-interface/user-interface-overview'));
const UserInterfaceButtonUpdate = lazy(() => import('./user-interface/user-interface-button-update/user-interface-button-update.component'));
// #endregion
// #region Unknowns
const UnknownOverview = lazy(() => import('./unknown/unknown-overview/unknown-overview'));
const UnknownDetail = lazy(() => import('./unknown/unknown-detail/unknown-detail.component'));
const UnknownCreate = lazy(() => import('./unknown/unknown-create/unknown-create.component'));
// #endregion
// #region Stocks
const StockOverview = lazy(() => import('./stock/stock-overview/stock-overview'));
const StockCreate = lazy(() => import('./stock/stock-create/stock-create.component'));
const StockDetail = lazy(() => import('./stock/stock-detail/stock-detail.component'));
const StockUpdate = lazy(() => import('./stock/stock-update/stock-update.component'));
// #endregion
// #region Movements
const MovementOverview = lazy(() => import('./movement/movement-overview/movement-overview'));
const MovementDetail = lazy(() => import('./movement/movement-detail/movement-detail.component'));
// #endregion

// #region Warehouse
const StockOverviewReport = lazy(() => import('./stock/stock-overview-report/stock-overview-report'));
const MovementOverviewReport = lazy(() => import('./movement/movement-overview-report/movement-overview-report'));
// #endregion
// #region Packages
const PackageOverview = lazy(() => import('./package/package-overview/package-overview'));
const PackageDetail = lazy(() => import('./package/package-detail/package-detail.component'));
// #endregion
// #region Change Password
const ChangePassword = lazy(() => import('./authentication/change-password/change-password.component'));
// #endregion
// #region Contacts
const ContactOverview = lazy(() => import('./contact/contact-overview/contact-overview'));
const ContactDetail = lazy(() => import('./contact/contact-detail/contact-detail.component'));
const ContactCreate = lazy(() => import('./contact/contact-create/contact-create.component'));
const ContactUpdate = lazy(() => import('./contact/contact-update/contact-update.component'));
// #endregion
//#region Seeds
const SeedOverview = lazy(() => import('./sync/seed-overview/seed-overview'));
// #endregion
// #region Events
const AllEventsOverview = lazy(() => import('./event/all-events-overview/all-events-overview'));
// #endregion
// #region User Certificates
const UserCertificateCreate = lazy(() => import('./user-certificate/user-certificate-create/user-certificate-create.component'));
// #endregion
// #region Equipment Certificates
const EquipmentCertificateCreate = lazy(() => import('./equipment-certificate/equipment-certificate-create/equipment-certificate-create.component'));
// #endregion
const Troubleshoot = lazy(() => import('./troubleshoot/troubleshoot.component'));

const UserBadgeOverview = lazy(() => import('./user-badge/user-badge-overview'));

const envColor =
    process.env['NX_PUBLIC_BUILD_ENV'] === 'development'
        ? ClassType.INFO
        : process.env['NX_PUBLIC_BUILD_ENV'] === 'test'
          ? ClassType.SECONDARY
          : ClassType.PRIMARY;

export function App() {
    useScripts('https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js');
    useScripts('https://kit.fontawesome.com/42d5adcbca.js');
    const dispatch = useAppDispatch();
    const [navbarPinned] = useLocalStorageState('navbar-pinned', false);
    const [hovered] = useLocalStorageState('navbar-hovered', false);

    const IoTRoutes = IOTRoutes();
    const UserRoute = UserRoutes();
    const BudgetRoute = BudgetRoutes();
    const EquipmentRoute = EquipmentRoutes();
    const StatisticRoute = StatisticRoutes();
    const TagRoute = TagRoutes();
    const DeviceRoute = DeviceRoutes();
    const NotificationTemplateRoute = NotificationTemplateRoutes();
    const ProductCategoryRoute = ProductCategoryRoutes();
    const VariantRoute = VariantRoutes();
    const ConstraintRoute = ConstraintRoutes();

    //UGENT DEMO
    const UgentDemoRoute = UgentDemoRoutes();
    return (
        <TranslationProvider>
            <DocumentStoreProvider>
                <div className={`g-sidenav-show bg-gray-100 g-sidenav-${navbarPinned || hovered ? 'pinned' : 'hidden'}`}>
                    <div className={`min-height-300 bg-${envColor} position-absolute w-100 sticky-top top-bar`} />
                    <Router>
                        <Authentication>
                            <AccountStoreProvider>
                                <AuthorizationProvider dispatch={dispatch}>
                                    <Resolver>
                                        <Navbar />
                                        <main className='main-content position-relative border-radius-lg'>
                                            <Header
                                                dispatch={dispatch}
                                                color={envColor}
                                                version={VERSION}
                                            />
                                            <div className='container-fluid ps-4'>
                                                <AsyncComponent>
                                                    <Routes>
                                                        {UgentDemoRoute}
                                                        <Route path='pudo'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <PuDoTransactionOverview />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <PudoTransactionCreate />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route path=':transactionId'>
                                                                <Route
                                                                    index
                                                                    path='detail'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PudoTransactionDetail />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                        <Route path='address-book'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <AddressBookContactOverview />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='create'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <AddressBookContactCreate />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route path=':contactId'>
                                                                <Route
                                                                    index
                                                                    path='detail'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressBookContactDetail />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    index
                                                                    path='update'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressBookContactUpdate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                        </Route>
                                                        <Route path='admin'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <Configuration />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route path='accounts'>
                                                                {/**
                                                        // #region Accounts
                                                        * */}
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AccountOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AccountCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route path=':accountId'>
                                                                    <Route
                                                                        index
                                                                        path='detail'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <AccountDetail />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path='update'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <AccountUpdate />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path='branding/update'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <AccountBrandingUpdate />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                    <Route path=':entityType'>
                                                                        <Route path=':entityId'>
                                                                            <Route
                                                                                path='permissions'
                                                                                element={<PermissionsList />}
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account user groups
                                                            * */}
                                                                    <Route path='user-groups'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='members/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserGroupMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/user-groups' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account equipment groups
                                                            * */}
                                                                    <Route path='equipment-groups'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='members/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <EquipmentGroupMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/equipment-groups' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account product groups
                                                            * */}
                                                                    <Route path='product-groups'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='members/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductGroupMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/product-groups' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account contact groups
                                                            * */}
                                                                    <Route path='contact-groups'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='members/add'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactGroupMemberAdd />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/product-groups' />}
                                                                        />
                                                                    </Route>

                                                                    {/**
                                                                // #region Account budgets
                                                            * */}
                                                                    {BudgetRoute}
                                                                    {/**
                                                                // #region Account documents
                                                            * */}
                                                                    <Route path='documents'>
                                                                        <Route path=':documentId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <DocumentDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='file'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <DocumentFileUpload />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/documents' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account cost centers
                                                            * */}
                                                                    <Route path='cost-centers'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/cost-centers' />}
                                                                        />
                                                                        <Route path=':costCenterId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CostCenterDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CostCenterUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                                     // #region Account  tags
                                                                    * */}
                                                                    {TagRoute}
                                                                    {/**
                                                             // #region Account roles
                                                            * */}
                                                                    <Route path='roles'>
                                                                        <Route path=':roleId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <RoleDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <RoleUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/roles' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account playlists
                                                            * */}
                                                                    <Route path='playlists'>
                                                                        <Route path=':playlistId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <PlaylistDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <PlaylistUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='playlist-items'>
                                                                                <Route path=':playlistItemId'>
                                                                                    <Route
                                                                                        index
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <PlaylistItemDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <PlaylistItemUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <PlaylistItemCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/playlists' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account impexs
                                                            * */}
                                                                    <Route path='impexs'>
                                                                        <Route path=':impexId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ImpexDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/impexs' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account addresses
                                                            * */}
                                                                    <Route path='addresses'>
                                                                        <Route path=':addressId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AddressDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AddressUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path=':entityType'>
                                                                                <Route
                                                                                    path='link'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <AddressEntityLink />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/addresses' />}
                                                                        />
                                                                        <Route
                                                                            path='link/:entityId/:entityType/add'
                                                                            element={
                                                                                <AsyncComponent>
                                                                                    <EntityToAddressLink />
                                                                                </AsyncComponent>
                                                                            }
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account question groups
                                                            * */}
                                                                    <Route path='questionnaires'>
                                                                        <Route path=':groupId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <QuestionGroupDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <QuestionGroupUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='members'>
                                                                                <Route
                                                                                    path='add'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <QuestionGroupMemberAdd />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':memberId'>
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={<QuestionGroupMemberUpdate />}
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/questionnaires' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account questions
                                                            * */}
                                                                    <Route path='questions'>
                                                                        <Route
                                                                            path='create'
                                                                            element={
                                                                                <AsyncComponent>
                                                                                    <QuestionCreate />
                                                                                </AsyncComponent>
                                                                            }
                                                                        />
                                                                        <Route path=':questionId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <QuestionDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <QuestionUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='answers'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <AnswerCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':answerId'>
                                                                                    <Route
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <AnswerDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <AnswerUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/questions' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                            // #region Account Module templates
                                                            * */}
                                                                    <Route path='module-templates'>
                                                                        <Route path=':moduleTemplateId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ModuleTemplateDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ModuleTemplateUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='copy'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ModuleTemplateCopy />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/module-templates' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                            // #region Account Slot templates
                                                            * */}
                                                                    <Route path='slot-templates'>
                                                                        <Route path=':slotTemplateId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SlotTemplateDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SlotTemplateUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='copy'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SlotTemplateCopy />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/slot-templates' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                            // #region Account spots
                                                            * */}
                                                                    <Route path='spots'>
                                                                        <Route path=':spotId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SpotDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SpotUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='settings/update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <SpotSettingsUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            {/** 
                                                                          // #region Account spot constraints
                                                                         */}
                                                                            {ConstraintRoute}
                                                                            {/**
                                                                    // #region Account spot requirements
                                                                     * */}
                                                                            <Route path='requirements'>
                                                                                <Route
                                                                                    index
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <RequirementOverview />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <RequirementCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':requirementId'>
                                                                                    <Route
                                                                                        index
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <RequirementDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <RequirementUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                            {/**
                                                                     // #region Account spot modules
                                                                    * */}
                                                                            <Route path='modules'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <ModuleCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':moduleId'>
                                                                                    <Route
                                                                                        index
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <ModuleDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <ModuleUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                    {ConstraintRoute}
                                                                                    {/**
                                                                             // #region Account spot module slots
                                                                            * */}
                                                                                    <Route path='slots'>
                                                                                        <Route
                                                                                            path='create'
                                                                                            element={
                                                                                                <AsyncComponent>
                                                                                                    <SlotCreate />
                                                                                                </AsyncComponent>
                                                                                            }
                                                                                        />
                                                                                        <Route path=':slotId'>
                                                                                            <Route
                                                                                                index
                                                                                                path='detail'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <SlotDetail />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                            <Route
                                                                                                path='update'
                                                                                                element={
                                                                                                    <AsyncComponent>
                                                                                                        <SlotUpdate />
                                                                                                    </AsyncComponent>
                                                                                                }
                                                                                            />
                                                                                            {ConstraintRoute}
                                                                                            <Route path='stocks'>
                                                                                                <Route path=':stockId'>
                                                                                                    <Route
                                                                                                        index
                                                                                                        path='detail'
                                                                                                        element={
                                                                                                            <AsyncComponent>
                                                                                                                <StockDetail />
                                                                                                            </AsyncComponent>
                                                                                                        }
                                                                                                    />
                                                                                                    <Route
                                                                                                        path='update'
                                                                                                        element={
                                                                                                            <AsyncComponent>
                                                                                                                <StockUpdate />
                                                                                                            </AsyncComponent>
                                                                                                        }
                                                                                                    />
                                                                                                </Route>
                                                                                            </Route>
                                                                                            <Route path='stock-updates'>
                                                                                                <Route path=':movementId'>
                                                                                                    <Route
                                                                                                        path='detail'
                                                                                                        element={
                                                                                                            <AsyncComponent>
                                                                                                                <MovementDetail />
                                                                                                            </AsyncComponent>
                                                                                                        }
                                                                                                    />
                                                                                                </Route>
                                                                                            </Route>
                                                                                        </Route>
                                                                                        <Route
                                                                                            index
                                                                                            element={<Navigate to='../detail' />}
                                                                                        />
                                                                                    </Route>
                                                                                </Route>
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/spots' />}
                                                                        />
                                                                    </Route>
                                                                    {/**
                                                             // #region Account equipment
                                                            * */}
                                                                    {EquipmentRoute}
                                                                    {/**
                                                             // #region Account users
                                                            * */}
                                                                    {UserRoute}
                                                                    {/**
                                                             // #region Account products
                                                            * */}
                                                                    <Route path='products'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/products' />}
                                                                        />
                                                                        <Route path=':productId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProductUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-stock'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <StockCreate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='add-certificate'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AddCertificate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            {VariantRoute}
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account attributes
                                                            * */}
                                                                    <Route path='attributes'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/attributes' />}
                                                                        />
                                                                        <Route path=':attributeId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AttributeDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <AttributeUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Entities
                                                            * */}
                                                                    <Route path=':entityType/:entityId'>
                                                                        <Route
                                                                            path='debug'
                                                                            element={<Troubleshoot />}
                                                                        />
                                                                        {/**
                                                                 // #region Entity roles
                                                                * */}
                                                                        <Route path='roles'>
                                                                            <Route
                                                                                path='create'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <RoleCreate2 />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='predefined'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <RoleCreatePredefined />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>

                                                                        <Route path=':identificationType'>
                                                                            <Route
                                                                                index
                                                                                element={<Navigate to='../detail' />}
                                                                            />
                                                                            <Route path=':identificationId'>
                                                                                <Route
                                                                                    path='detail'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <IdentificationDetail />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Licenses
                                                            * */}
                                                                    <Route path='license'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='../detail' />}
                                                                        />
                                                                        <Route
                                                                            path='create'
                                                                            element={
                                                                                <AsyncComponent>
                                                                                    <LicenseCreate />
                                                                                </AsyncComponent>
                                                                            }
                                                                        />
                                                                    </Route>

                                                                    {/**
                                                             // #region Account Certificates
                                                            * */}
                                                                    <Route path='certificates'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/certificates' />}
                                                                        />
                                                                        <Route path=':certificateId'>
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CertificateUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <CertificateDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='equipment-certificates'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <EquipmentCertificateCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                            <Route path='user-certificates'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <UserCertificateCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Workflows
                                                            * */}
                                                                    <Route path='workflows'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/workflows' />}
                                                                        />
                                                                        <Route path=':workflowId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <WorkflowDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='copy'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <WorkflowCopy />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <WorkflowUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='steps'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <StepCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route path=':stepId'>
                                                                                    <Route path='fields'>
                                                                                        <Route
                                                                                            path='update'
                                                                                            element={<FieldsUpdate />}
                                                                                        />
                                                                                    </Route>
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account user interfaces
                                                            * */}
                                                                    <Route path='user-interfaces'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/user-interfaces' />}
                                                                        />
                                                                        <Route path=':userInterfaceId'>
                                                                            <Route
                                                                                index
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserInterfaceDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserInterfaceUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='copy'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <UserInterfaceCopy />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='button'>
                                                                                <Route
                                                                                    path='create'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <UserInterfaceButtonCreate />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                            </Route>
                                                                            <Route path='buttons'>
                                                                                <Route path=':buttonId'>
                                                                                    <Route
                                                                                        path='update'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <UserInterfaceButtonUpdate />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account IoTs
                                                            * */}
                                                                    {IoTRoutes}
                                                                    {/**
                                                                     * // #region Account Problems
                                                                     */}
                                                                    <Route path='problems'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/problems' />}
                                                                        />
                                                                        <Route path=':problemId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProblemDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ProblemUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path=':entityType'>
                                                                                <Route
                                                                                    path='link'
                                                                                    element={
                                                                                        <AsyncComponent>
                                                                                            <ProblemEntityLink />
                                                                                        </AsyncComponent>
                                                                                    }
                                                                                />
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='../detail' />}
                                                                                />
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Contacts
                                                            * */}
                                                                    <Route path='contacts'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/contacts' />}
                                                                        />
                                                                        <Route path=':contactId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path='update'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <ContactUpdate />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                             // #region Account Transactions
                                                            * */}
                                                                    <Route path='transactions'>
                                                                        <Route
                                                                            index
                                                                            element={<Navigate to='/admin/transactions' />}
                                                                        />
                                                                        <Route path=':transactionId'>
                                                                            <Route
                                                                                path='detail'
                                                                                element={
                                                                                    <AsyncComponent>
                                                                                        <TransactionDetail />
                                                                                    </AsyncComponent>
                                                                                }
                                                                            />
                                                                            <Route path='packages'>
                                                                                <Route
                                                                                    index
                                                                                    element={<Navigate to='/admin/packages' />}
                                                                                />
                                                                                <Route path=':packageId'>
                                                                                    <Route
                                                                                        path='detail'
                                                                                        element={
                                                                                            <AsyncComponent>
                                                                                                <PackageDetail />
                                                                                            </AsyncComponent>
                                                                                        }
                                                                                    />
                                                                                </Route>
                                                                            </Route>
                                                                        </Route>
                                                                    </Route>
                                                                    {/**
                                                                  // #region Account Notification Templates
                                                                 * */}
                                                                    {NotificationTemplateRoute}
                                                                    {/**
                                                             // #region Account product categories
                                                            * */}
                                                                    {ProductCategoryRoute}
                                                                </Route>
                                                            </Route>
                                                            {/**
                                                     // #region User groups
                                                    * */}
                                                            <Route path='user-groups'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                     // #region Equipment groups
                                                    * */}
                                                            <Route path='equipment-groups'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <EquipmentGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <EquipmentGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                     // #region Product groups
                                                    * */}
                                                            <Route path='product-groups'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            <Route path='contact-groups'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ContactGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ContactGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                     // #region Cost centers
                                                    * */}
                                                            <Route path='cost-centers'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CostCenterOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CostCenterCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                        // #region Budgets
                                                    * */}
                                                            {BudgetRoute}
                                                            {/**
                                                        // #region Document
                                                    * */}
                                                            <Route path='documents'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <DocumentOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                            // #region  Tags
                                                            * */}
                                                            {TagRoute}
                                                            {/*
                                                        // #region Roles
                                                    * */}
                                                            <Route path='roles'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <RoleOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <RoleCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                     // #region Question groups
                                                    * */}
                                                            <Route path='questionnaires'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <QuestionGroupOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <QuestionGroupCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                        // #region Questions
                                                    * */}
                                                            <Route path='questions'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <QuestionOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <QuestionCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/**
                                                        // #region Answers
                                                    * */}
                                                            <Route path='answers'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AnswerOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AnswerCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Addresses
                                                    * */}
                                                            <Route path='addresses'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AddressCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {VariantRoute}
                                                            {/*
                                                        // #region Playlists
                                                    * */}
                                                            <Route path='playlists'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PlaylistOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PlaylistCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Playlists
                                                    * */}
                                                            <Route path='impexs'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ImpexOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ImpexCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Spots
                                                    * */}
                                                            <Route path='spots'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SpotOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SpotCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Modules
                                                    * */}
                                                            <Route path='modules'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ModuleOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ModuleCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Module templates
                                                    * */}
                                                            <Route path='module-templates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ModuleTemplateOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ModuleTemplateCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                            {/*
                                                        // #region Slot templates
                                                    * */}
                                                            <Route path='slot-templates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SlotTemplateOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SlotTemplateCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Equipment
                                                    * */}
                                                            {EquipmentRoute}
                                                            {/*
                                                        // #region Transactions
                                                    * */}
                                                            <Route path='transactions'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <TransactionOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <TransactionCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Slots
                                                    * */}
                                                            {/* <Route path='slots'>
                                                        <Route
                                                            index
                                                            element={<AsyncComponent><SlotOverview /></AsyncComponent>}
                                                        />
                                                        <Route
                                                            path='create'
                                                            element={<AsyncComponent><SlotCreate /></AsyncComponent>}
                                                        />
                                                    </Route> */}
                                                            {/*
                                                        // #region Users
                                                    * */}
                                                            {UserRoute}
                                                            {/*
                                                        // #region Products
                                                    * */}
                                                            <Route path='products'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProductCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Attributes
                                                    * */}
                                                            <Route path='attributes'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AttributeOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <AttributeCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Certificates
                                                    * */}
                                                            <Route path='certificates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CertificateOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <CertificateCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Workflows
                                                    * */}
                                                            <Route path='workflows'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <WorkflowOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <WorkflowCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region User interfaces
                                                    * */}
                                                            <Route path='user-interfaces'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserInterfaceOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UserInterfaceCreate dispatch={dispatch} />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region IoT
                                                    * */}
                                                            {IoTRoutes}

                                                            {/*
                                                        // #region Problems
                                                    * */}
                                                            <Route path='problems'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProblemOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ProblemCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/** *
                                                 // #region Unknowns
                                                 * */}
                                                            <Route path='unknowns'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <UnknownOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route path=':unknownId'>
                                                                    <Route
                                                                        index
                                                                        path='detail'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <UnknownDetail />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                    <Route
                                                                        path='create-iot'
                                                                        element={
                                                                            <AsyncComponent>
                                                                                <UnknownCreate />
                                                                            </AsyncComponent>
                                                                        }
                                                                    />
                                                                </Route>
                                                            </Route>
                                                            {/*
                                                        // #region Devices
                                                    * */}
                                                            {DeviceRoute}
                                                            {/*
                                                        // #region Stocks
                                                    * */}
                                                            <Route path='stocks'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <StockOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <StockCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Movements
                                                    * */}
                                                            <Route path='stock-updates'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <MovementOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Packages
                                                    * */}
                                                            <Route path='packages'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <PackageOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Contacts
                                                    * */}
                                                            <Route path='contacts'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ContactOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                                <Route
                                                                    path='create'
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <ContactCreate />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Seeds
                                                    * */}
                                                            <Route path='seeds'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AsyncComponent>
                                                                            <SeedOverview />
                                                                        </AsyncComponent>
                                                                    }
                                                                />
                                                            </Route>
                                                            {/*
                                                        // #region Events
                                                    * */}
                                                            <Route path='sync-events'>
                                                                <Route
                                                                    index
                                                                    element={
                                                                        <AllEventsOverview
                                                                            type={[
                                                                                EventType.ENTITY_SYNC_DELETED,
                                                                                EventType.ENTITY_SYNC_UPDATED,
                                                                                EventType.ENTITY_SYNC_CREATED
                                                                            ]}
                                                                            title={CommonMessage.OBJECTS.EVENT.SYNC_EVENTS}
                                                                        />
                                                                    }
                                                                />
                                                            </Route>
                                                            <Route
                                                                path='import-users'
                                                                element={<UserImport />}
                                                            />
                                                            {/** 
                                                         // #region Notification Templates
                                                         */}
                                                            {NotificationTemplateRoute}
                                                            {/** 
                                                         // #region Product Categories
                                                         */}
                                                            {ProductCategoryRoute}
                                                            <Route path=':entityType'>
                                                                <Route path=':entityId'>
                                                                    <Route
                                                                        path='permissions'
                                                                        element={<PermissionsList />}
                                                                    />
                                                                    <Route
                                                                        path='debug'
                                                                        element={<Troubleshoot />}
                                                                    />
                                                                </Route>
                                                            </Route>

                                                            <Route path='user-badges'>
                                                                <Route
                                                                    index
                                                                    element={<UserBadgeOverview />}
                                                                />
                                                            </Route>
                                                        </Route>
                                                        {/**
                                                         * // #region Statistics
                                                         */}
                                                        {StatisticRoute}

                                                        {/** *
                                                      // #region Warehouse
                                                     * */}
                                                        <Route path='warehouse'>
                                                            <Route
                                                                path='inventory'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <StockOverviewReport />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                            <Route
                                                                path='history'
                                                                element={
                                                                    <AsyncComponent>
                                                                        <MovementOverviewReport />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        {/** *
                                                         * // #region Change Password
                                                         * */}
                                                        <Route path='change-password'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <ChangePassword />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        {/** *
                                                 // #region Profile
                                                 * */}
                                                        <Route path='profile'>
                                                            <Route
                                                                index
                                                                element={
                                                                    <AsyncComponent>
                                                                        <Profile dispatch={dispatch} />
                                                                    </AsyncComponent>
                                                                }
                                                            />
                                                        </Route>
                                                        <Route
                                                            path='unauthorized'
                                                            element={<Unauthorized />}
                                                        />
                                                        <Route
                                                            path='/'
                                                            element={<Navigate to='/pudo' />}
                                                        />
                                                        <Route
                                                            path='/*'
                                                            element={<Navigate to='/pudo' />}
                                                        />
                                                    </Routes>
                                                </AsyncComponent>
                                            </div>
                                            <Footer />
                                        </main>
                                    </Resolver>
                                </AuthorizationProvider>
                            </AccountStoreProvider>
                        </Authentication>
                    </Router>
                    <ToastContainer />
                </div>
            </DocumentStoreProvider>
        </TranslationProvider>
    );
}

export default App;
