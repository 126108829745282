import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const VariantOverview = lazy(() => import('./variant-overview'));
const VariantWizard = lazy(() => import('./variant-wizard/variant-wizard.component'));
const VariantDetail = lazy(() => import('./variant-detail/variant-detail.component'));
const VariantUpdate = lazy(() => import('./variant-update/variant-update.component'));

const AttributeLinkingDetail = lazy(() => import('../attribute/attribute-linking-detail/attribute-linking-detail.component'));
const AttributeLinkingCreate = lazy(() => import('../attribute/attribute-linking-create/attribute-linking-create.component'));
const AttributeLinkingUpdate = lazy(() => import('../attribute/attribute-linking-update/attribute-linking-update.component'));

export function VariantRoutes() {
    return (
        <Route path='variants'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <VariantOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <VariantWizard />
                    </AsyncComponent>
                }
            />
            <Route path=':variantId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <VariantDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <VariantUpdate />
                        </AsyncComponent>
                    }
                />
                <Route path='attribute-linkings'>
                    <Route path=':attributeLinkingId'>
                        <Route
                            index
                            path='detail'
                            element={
                                <AsyncComponent>
                                    <AttributeLinkingDetail />
                                </AsyncComponent>
                            }
                        />
                        <Route
                            path='update'
                            element={
                                <AsyncComponent>
                                    <AttributeLinkingUpdate />
                                </AsyncComponent>
                            }
                        />
                    </Route>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <AttributeLinkingCreate />
                            </AsyncComponent>
                        }
                    />
                    <Route
                        index
                        element={<Navigate to='../detail' />}
                    />
                </Route>
            </Route>
        </Route>
    );
}
