import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const UgentTransactionOverview = lazy(() => import('./ugent-transaction-overview'));
const UgentTransactionDetail = lazy(() => import('./ugent-transaction-detail/ugent-transaction-detail.component'));
export function UgentDemoRoutes() {
    return (
        <Route path='ugent'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <UgentTransactionOverview />
                    </AsyncComponent>
                }
            />
            <Route path=':transactionId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <UgentTransactionDetail />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
