import { EventListener } from '@frontend/pub-sub';
import { Spot } from '@frontend/spot/types';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class SpotEventListener extends EventListener<Spot> {
    private static instance: SpotEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('spot', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): SpotEventListener {
        if (this.instance == null) {
            this.instance = new SpotEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Spot> {
        return SpotEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
