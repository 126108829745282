import { Logger } from '@frontend/Logger';

import { AuthenticationManager } from './authentication-manager';
import { AuthenticationError, Token } from './models';

export class BasicAuthenticationClient {
    static async login(email: string, password: string): Promise<Token> {
        Logger.debug('Sending login request.');

        let signInUri = 'https://identification-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + '/v1/basic-auth-sign-in';
        if (process.env['NX_PUBLIC_BUILD_ENV'] === 'edge') {
            signInUri = 'http://localhost:8018/v1/basic-auth-sign-in';
        }

        const response = await fetch(signInUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: `${email}`,
                password: `${password}`
            })
        });
        Logger.debug('Login response code: ' + response.status);
        if (response.status !== 200) {
            throw new AuthenticationError('Login failed.', await response.json());
        }
        return response.json();
    }

    static async refresh(refreshToken: string): Promise<Token> {
        Logger.debug('Sending refresh token request.');
        let refreshUri = 'https://authorization-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + '/v1/refresh';
        if (process.env['NX_PUBLIC_BUILD_ENV'] === 'edge') {
            refreshUri = 'http://localhost:8002/v1/refresh';
        }

        const response = await fetch(refreshUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                refresh_token: `${refreshToken}`
            })
        });
        Logger.debug('Token refresh response code: ' + response.status);
        return response.json();
    }

    static async logout(token: string): Promise<Response> {
        Logger.debug('Sending logout request.');
        let logoutUri = 'https://authorization-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + '/v1/de-authorize';
        if (process.env['NX_PUBLIC_BUILD_ENV'] === 'edge') {
            logoutUri = 'http://localhost:8002/v1/de-authorize';
        }

        const response = await fetch(logoutUri, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            },
            body: token
        });
        Logger.debug('Logout response code: ' + response.status);
        return response;
    }

    static async signUp(email: string, password: string, password_confirmation: string, url: string): Promise<{ message: string }> {
        Logger.debug('Sending sign up request.');
        const signUpUri = 'https://authentication-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + '/v1/basic-auth-sign-up';

        const response = await fetch(signUpUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: `${email}`,
                password: `${password}`,
                password_confirmation: `${password_confirmation}`,
                url: `${url}`
            })
        });
        Logger.debug('Sign up response code: ' + response.status);
        if (response.status !== 200) {
            throw new AuthenticationError('Login failed.', await response.json());
        }
        return response.json();
    }

    static async confirmSignUp(token: string): Promise<Token> {
        Logger.debug('Sending sign up confirmation request.');
        const signUpConfirmationUri = 'https://authentication-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + '/v1/basic-auth-verification/' + token;

        const response = await fetch(signUpConfirmationUri, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        Logger.debug('Sign up confirmation response code: ' + response.status);
        if (response.status !== 200) {
            throw new AuthenticationError('Sign up confirmation failed.', await response.json());
        }
        return response.json();
    }

    static async forgotPassword(email: string, url: string): Promise<{ message: string }> {
        Logger.debug('Sending forgot password request.');
        const forgotPasswordUri = 'https://identification-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + '/v1/basic-auth-forgot-password';

        const response = await fetch(forgotPasswordUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: `${email}`,
                url: `${url}`
            })
        });
        Logger.debug('Forgot password response code: ' + response.status);
        if (response.status != 200) {
            throw new AuthenticationError('Forgot password failed.', await response.json());
        }
        return response.json();
    }

    static async changeForgottenPassword(token: string, newPassword: string): Promise<Token> {
        Logger.debug('Sending change forgotten password request.');
        const changeForgottenPasswordUri =
            'https://identification-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + '/v1/basic-auth-change-forgotten-password/' + token;

        const response = await fetch(changeForgottenPasswordUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                new_password: `${newPassword}`
            })
        });
        Logger.debug('Change forgotten password response code: ' + response.status);
        if (response.status != 200) {
            throw new AuthenticationError('Change forgotten password failed.', await response.json());
        }
        return response.json();
    }

    static async changePassword(accountId: string, userId: string, body: { old_password?: string; new_password?: string; email?: string }): Promise<Token> {
        Logger.debug('Sending change password request.');
        const changePasswordUri =
            'https://identification-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + `/v1/accounts/${accountId}/users/${userId}/change-password`;
        const token = await AuthenticationManager.getInstance().waitForToken();
        const response = await fetch(changePasswordUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + token.jwt_token
            },
            body: JSON.stringify({
                old_password: `${body.old_password}`,
                new_password: `${body.new_password}`,
                email: `${body.email}`
            })
        });
        Logger.debug('Change password response code: ' + response.status);
        if (response.status != 200) {
            throw new AuthenticationError('Change password failed.', await response.json());
        }
        return response.json();
    }
}
