import { APIClient, ApiQueryParams } from "@frontend/api-utils";
import { CopySlotTemplate, CreateSlotTemplate, SlotTemplate, SlotTemplateListResponse, SlotTemplateQueryParams, UpdateSlotTemplate } from "@frontend/slot/types";

const endpoint = '/slot-api/v1';

export class SlotTemplateClient extends APIClient {
    public static async fetchSlotTemplates(queryParams?: ApiQueryParams<SlotTemplateQueryParams>): Promise<SlotTemplateListResponse> {
        return await this.apiPaginated<SlotTemplateListResponse, SlotTemplateQueryParams>(`${endpoint}/templates`, queryParams);
    }

    public static async resolveSlotTemplates(ids: string[], field: keyof SlotTemplate = 'id'): Promise<SlotTemplateListResponse> {
        const response = await this.post(`${endpoint}/templates-resolve`, { field, ids });
        return await this.handleResponse<SlotTemplateListResponse>(response);
    }

    public static async deleteSlotTemplates(ids: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/templates-delete`, { ids });
        return await this.handleVoidResponse(response);
    }

    public static async postSlotTemplate(accountId: string, template: CreateSlotTemplate): Promise<SlotTemplate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/templates`, template);
        return await this.handleResponse<SlotTemplate>(response);
    }

    public static async fetchSlotTemplate(accountId: string, templateId: string): Promise<SlotTemplate> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/templates/${templateId}`);
        return await this.handleResponse<SlotTemplate>(response);
    }

    public static async patchSlotTemplate(accountId: string, templateId: string, body: UpdateSlotTemplate): Promise<SlotTemplate> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/templates/${templateId}`, body);
        return await this.handleResponse<SlotTemplate>(response);
    }

    public static async deleteSlotTemplate(accountId: string, templateId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/templates/${templateId}`);
        return await this.handleVoidResponse(response);
    }
            
    public static async copyAccountSlotTemplate(accountId: string, slotTemplateId: string, body: CopySlotTemplate): Promise<SlotTemplate> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/templates/${slotTemplateId}/copy`, body);
        return await this.handleResponse<SlotTemplate>(response);
    }

}