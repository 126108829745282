import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { ReportStockListResponse, ReportStocksProductQuantityListResponse, StockQueryParams } from '@frontend/stock/types';

const endpoint = '/stock-api/v1';

export class StockReportClient extends APIClient {
    public static async fetchStocksReport(queryParams?: ApiQueryParams<DefaultQueryParams | StockQueryParams>): Promise<ReportStockListResponse> {
        return await this.apiPaginated(`${endpoint}/stocks-report`, queryParams);
    }

    public static async fetchReportStocksProductQuantity(queryParams?: ApiQueryParams<DefaultQueryParams | StockQueryParams>): Promise<ReportStocksProductQuantityListResponse> {
        return await this.apiPaginated(`${endpoint}/report-stocks-product-quantity`, queryParams);
    }
}