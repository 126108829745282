import { ClassType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export enum LogMessageTypeName {
    DEBUG = 'debug',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    ALERT = 'alert'
}

export class LogMessageType {
    static readonly DEBUG = new LogMessageType(
        LogMessageTypeName.DEBUG,
        ClassType.PRIMARY,
        (
            <FormattedMessage
                id='LogMessageType.DEBUG'
                description='The displayed value for the log message type DEBUG'
                defaultMessage='Debug'
            />
        )
    );
    static readonly INFO = new LogMessageType(
        LogMessageTypeName.INFO,
        ClassType.INFO,
        (
            <FormattedMessage
                id='LogMessageType.INFO'
                description='The displayed value for the log message type INFO'
                defaultMessage='Info'
            />
        )
    );
    static readonly WARNING = new LogMessageType(
        LogMessageTypeName.WARNING,
        ClassType.WARNING,
        (
            <FormattedMessage
                id='LogMessageType.WARNING'
                description='The displayed value for the log message type WARNING'
                defaultMessage='Warning'
            />
        )
    );
    static readonly ERROR = new LogMessageType(
        LogMessageTypeName.ERROR,
        ClassType.DANGER,
        (
            <FormattedMessage
                id='LogMessageType.ERROR'
                description='The displayed value for the log message type ERROR'
                defaultMessage='Error'
            />
        )
    );
    static readonly ALERT = new LogMessageType(
        LogMessageTypeName.ALERT,
        ClassType.WARNING,
        (
            <FormattedMessage
                id='LogMessageType.ALERT'
                description='The displayed value for the log message type ALERT'
                defaultMessage='Alert'
            />
        )
    );

    static readonly ALL = [LogMessageType.DEBUG, LogMessageType.INFO, LogMessageType.WARNING, LogMessageType.ERROR, LogMessageType.ALERT];

    private constructor(
        public readonly name: LogMessageTypeName,
        public readonly classType: ClassType,
        public readonly displayedValue: React.ReactNode
    ) {}

    static getByName(name: LogMessageTypeName): LogMessageType | undefined {
        return this.ALL.find((s) => s.name === name);
    }
    static getByString(value: string): LogMessageType | undefined {
        return this.ALL.find((s) => s.name.toString() === value);
    }
}
