import { DateStep } from '@frontend/common';
import React from 'react';

import { DefaultSelectProps } from '../basic-forms';
import { SelectInput } from '../select-input/select-input.component';

const ID = 'period-type-select';
export const PeriodSelect = (props: DefaultSelectProps) => {
    return (
        <SelectInput
            {...props}
            id={props.id || ID}
            label='Period'
            submitted={props.submitted}
            options={Object.entries(DateStep).map(([key, value]) => ({ value: value, label: key }))}
            value={props.value}
            onChange={(v) => props.onChange && (v ? props.onChange((v as { value: DateStep; label: string }).value) : props.onChange(null))}
            isClearable={props.isClearable}
        />
    );
};
