import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import { userRequest } from '@frontend/user/events';
import { User } from '@frontend/user/types';
import React from 'react';

export const UserBadge = (props: ObjectBadgeProps<User>) => {
    return (
        <ObjectBadge<User>
            id={props.id}
            type={EntityType.USER}
            mapDisplayName={(user: User) => user.first_name + ' ' + user.last_name}
            resolve={userRequest}
            navigateString={(object: User) => `/accounts/${object.account_id}/users/${object.id}`}
            {...props}
        />
    );
};
