/* eslint-disable react/prop-types */
import { ClassType, EntityType } from '@frontend/common';
import { ConfirmationModal, Dropdown, DropdownItem, HorizontalButtonGroupButton } from '@frontend/elements';
import { ErrorHandler } from '@frontend/error-handler';
import { ICONS } from '@frontend/icons';
import { CommonMessage } from '@frontend/lang';
import { Checkbox } from '@frontend/table';
import React from 'react';
import { CellProps, Column, HeaderProps, Row } from 'react-table';

import { ObjectCellMapper } from './cells/object-cell-mapper';

export interface ObjectColumnsProps<T extends { id: string } & object> {
    columns: (keyof T)[] | string[];
    showIdAsEntity?: EntityType;
    headerOverwrite?: Map<keyof T | string, React.ReactNode>;
    valueOverwrite?: Map<keyof T, keyof T>;
    /**
     * @deprecated
     * should use the document repository
     */
    blobColumnOverwrite?: Map<keyof T, (object: T) => Promise<Blob>>;
    documentColumnOverwrite?: {
        key: keyof T;
        entityType: EntityType;
    };
    /**
     * Should be used if no generic cell is available for the required functionality
     */
    customCells?: Map<string, (props: CellProps<T, any>) => React.ReactNode>;
    /**
     * the first provided key should contain the key holding the entity id
     * the second should hold the entity type
     */
    entityColumnOverwrite?: Map<keyof T, keyof T>;
    hidden?: (keyof T | string)[];
    selectEnabled?: boolean;
    managedSelect?: string;
    /**
     * @description keys should be provided in the correct order to use the delete function
     */
    deleteKeys?: (keyof T)[];
    /**
     * @description key of the object that will be displayed in the confirmation modal
     */
    displayName?: (value: T) => React.ReactNode;
    deleteAction?: (...args: any) => Promise<void>;
    updateAction?: (object: T) => void;
    customActionButtons?: HorizontalButtonGroupButton[];
    cellClassName?: string | ((row: Row<T>) => string);
}

/**
 * @param props
 * @returns
 */
export const ObjectColumns = <T extends { id: string } & object>(props: ObjectColumnsProps<T>): Column<T>[] => {
    return convert<T>(props);
};

function convert<T extends { id: string } & object>(props: ObjectColumnsProps<T>): Column<T>[] {
    const result: Column<T>[] = [];
    if (props.selectEnabled) {
        result.push({
            Header: (headerProps: HeaderProps<T>) => {
                return <Checkbox {...headerProps.getToggleAllPageRowsSelectedProps()} />;
            },
            id: 'selection',
            Cell: (cellProps: CellProps<T, any>) => {
                return (
                    <td
                        style={{ width: '30px' }}
                        onClick={(e) => e.stopPropagation()}>
                        <Checkbox original={cellProps.row.original} listId={props.managedSelect} {...cellProps.row.getToggleRowSelectedProps()} />
                    </td>
                );
            }
        });
    }

    props.columns.forEach((col) => {
        if (props.hidden?.includes(col)) return;
        result.push({
            Header: (headerProps: HeaderProps<T>) => {
                if (typeof col != 'string') return;
                if (props.headerOverwrite && props.headerOverwrite.has(col)) {
                    return props.headerOverwrite.get(col);
                } else {
                    return (col[0].toUpperCase() + col.slice(1)).replace(/_|timestamp/g, ' ');
                }
            },
            id: col as string,
            Cell: (cellProps: CellProps<T, any>) => (
                <ObjectCellMapper
                    className={props.cellClassName}
                    {...cellProps}
                    parent={props}
                    cellKey={col}
                />
            ),
            accessor: col as keyof T
        });
    });

    if (props.deleteAction || props.updateAction || props.customActionButtons) {
        result.push({
            Header: '',
            id: 'actions',
            Cell: (cellProps: CellProps<T, any>) => {
                const [showDeleteModal, changeShowDeleteModal] = React.useState(false);
                return (
                    <td
                        className='align-content-center'
                        onClick={(e) => e.stopPropagation()}>
                        <Dropdown
                            label='...'
                            buttonStyle='font-weight-bolder text-lg'
                            hideChevron>
                            <DropdownItem
                                label={CommonMessage.BUTTONS.UPDATE}
                                icon={ICONS.BUTTON.UPDATE}
                                onClick={() => props.updateAction && props.updateAction(cellProps.row.original)}
                                classType={ClassType.INFO}
                                hide={!props.updateAction}
                            />
                            <DropdownItem
                                label={CommonMessage.BUTTONS.DELETE}
                                icon={ICONS.BUTTON.DELETE}
                                onClick={() => changeShowDeleteModal(true)}
                                classType={ClassType.DANGER}
                                hide={!props.deleteAction}
                            />
                            {props.customActionButtons &&
                                props.customActionButtons.map((button) => (
                                    <DropdownItem
                                        key={button.id}
                                        label={button.text}
                                        icon={button.icon}
                                        onClick={() => button.onClick && button.onClick(cellProps.row.original)}
                                        classType={button.type}
                                    />
                                ))}
                        </Dropdown>
                        {showDeleteModal && (
                            <ConfirmationModal
                                show={showDeleteModal}
                                handleClose={() => changeShowDeleteModal(false)}
                                onConfirm={() =>
                                    props.deleteAction &&
                                    props
                                        .deleteAction(...(props.deleteKeys ? props.deleteKeys.map((key) => cellProps.row.original[key])! : []))
                                        .then(() => changeShowDeleteModal(false))
                                        .catch(ErrorHandler.handleError)
                                }
                                message={CommonMessage.FORMS.DELETE_OBJECT(props.displayName ? (props.displayName(cellProps.row.original) as string) : '')}
                                severity={ClassType.DANGER}
                            />
                        )}
                    </td>
                );
            }
        });
    }

    return result;
}
