import { ApiError } from '@frontend/api-utils';
import { WorkflowEnumClient, WorkflowTemplateClient } from '@frontend/workflow/api';
import { WorkflowField } from '@frontend/workflow/types';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';



interface WorkflowSharedState {
    generalWorkflowTriggers: string[] | null;
    generalWorkflowActions: string[] | null;

    actionFieldTemplates: { [key: string]: WorkflowField[] } | null;
    triggerFieldTemplates: { [key: string]: WorkflowField[] } | null;
}

const initialState: WorkflowSharedState = {
    generalWorkflowTriggers: null,
    generalWorkflowActions: null,
    actionFieldTemplates: null,
    triggerFieldTemplates: null
};

const workflowSharedSlice = createSlice({
    name: 'workflowShared',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeneralWorkflowTriggers.fulfilled, (state, action) => {
                state.generalWorkflowTriggers = action.payload;
            })
            .addCase(fetchWorkflowActionFieldTemplates.fulfilled, (state, action) => {
                state.actionFieldTemplates = action.payload;
            })
            .addCase(fetchWorkflowTriggerFieldTemplates.fulfilled, (state, action) => {
                state.triggerFieldTemplates = action.payload;
            });
    }
});

export const fetchWorkflowActionFieldTemplates = createAsyncThunk<{ [key: string]: WorkflowField[] }>(
    'fetchWorkflowActionFieldTemplates',
    async (_, { rejectWithValue }) => {
        try {
            return await WorkflowTemplateClient.fetchWorkflowActionFieldTemplates();
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchWorkflowTriggerFieldTemplates = createAsyncThunk<{ [key: string]: WorkflowField[] }>(
    'fetchWorkflowTriggerFieldTemplates',
    async (_, { rejectWithValue }) => {
        try {
            return await WorkflowTemplateClient.fetchWorkflowTriggerFieldTemplates();
        } catch (e) {
            if ((e as ApiError).json) return rejectWithValue(e);
            throw e;
        }
    }
);

export const fetchGeneralWorkflowTriggers = createAsyncThunk<string[]>('fetchGeneralWorkflowTriggers', async (_, { rejectWithValue }) => {
    try {
        return await WorkflowEnumClient.fetchGeneralWorkflowTriggers();
    } catch (e) {
        if ((e as ApiError).json) return rejectWithValue(e);
        throw e;
    }
});


export const workflowSharedStore = { workflowShared: workflowSharedSlice.reducer };
