import { EntityType } from '@frontend/common';
import { useResolver } from '@frontend/repository';
import { WorkflowClient } from '@frontend/workflow/api';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useWorkflow:request': CustomEvent<string>;
    }
}

const REQUEST_WORKFLOW_EVENT = 'useWorkflow:request';
const STORAGE_KEY = 'WFS_REQ';

export function workflowRequest(workflowId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_WORKFLOW_EVENT, { detail: workflowId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function WorkflowResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_WORKFLOW_EVENT, EntityType.WORKFLOW, (ids) => WorkflowClient.resolveWorkflows(ids), props.dispatch);
    return <></>;
}