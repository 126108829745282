import { ErrorHandler } from '@frontend/error-handler';
import { FormatTypeName } from '@frontend/impex/types';
import { ToastUtil } from '@frontend/toast-utils';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ExportProps } from './export.component';

interface ViewProps {
    format: FormatTypeName;
    changeFormat: Dispatch<SetStateAction<FormatTypeName>>;
    onSubmit: (e: any) => void;
    loading: boolean;
}

const useExport = (props: ExportProps): ViewProps => {
    const [format, changeFormat] = useState<FormatTypeName>(FormatTypeName.XLSX);
    const [loading, changeLoading] = useState<boolean>(false);

    const onSubmit = (e: any) => {
        e.preventDefault();
        changeLoading(true);
        props
            .export(format)
            .then(() => {
                changeLoading(false);
                ToastUtil.success('Success', exportMessage());
                props.onClose();
            })
            .catch(ErrorHandler.handleError);
    };

    return {
        changeFormat,
        format,
        onSubmit,
        loading,
    };
};

export default useExport;

const exportMessage = () => {
    return (
        <FormattedMessage
            id='Export.Success.Message'
            description='Message for when a file has been exported'
            defaultMessage='Export successfull'
        />
    );
};
