import { AccountSelect } from '@frontend/account/select';
import { ClassType, EntityType } from '@frontend/common';
import { AsyncComponent, DefaultModal, DropZone, ModalFooter, Spinner } from '@frontend/elements';
import { FormatTypeName, ImportResponse } from '@frontend/impex/types';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormattedMessage } from 'react-intl';

import useImport from './import.controller';

export interface ImportProps {
    fetchTemplate: () => Promise<void>;
    import: (args: { accountId: string; file: File; dry_run: boolean }) => Promise<ImportResponse>;
    show: boolean;
    onClose: () => void;
    defaultValue?: FormatTypeName;
    accountId?: string | null;
    type: EntityType;
}

export const Import = (props: ImportProps) => {
    const viewProps = useImport(props);

    return (
        <DefaultModal
            handleClose={props.onClose}
            show={props.show}>
            <div>
                <form onSubmit={viewProps.onSubmit}>
                    <div className='modal-header'>
                        <h4>Import</h4>
                    </div>
                    <div className='modal-body'>
                        {viewProps.dryRunPassed ? (
                            <div className='alert alert-success text-white'>
                                <FormattedMessage
                                    id='Import.DryRunPassed'
                                    description='Message for when a dry run has passed'
                                    defaultMessage='Dry run passed. Click submit to import the file.'
                                />
                            </div>
                        ) : viewProps.dryRunPassed && viewProps.loading ? (
                            <Spinner />
                        ) : viewProps.errors.length > 0 ? (
                            <div
                                className='alert alert-danger d-flex max-height-500 text-white'
                                style={{ overflow: 'auto' }}>
                                <ul>
                                    {viewProps.errors.map((error, index) => (
                                        <>
                                            <li key={index}>{error}</li>
                                        </>
                                    ))}
                                </ul>
                            </div>
                        ) : (
                            <>
                                <div className='d-flex flex-row align-items-center'>
                                    <span className='text-sm text-primary font-weight-bolder me-2'>
                                        <FormattedMessage
                                            id='Import.DownloadTemplate'
                                            description='Message for downloading a template file'
                                            defaultMessage='Click <a>here</a> to download a template file.'
                                            values={{
                                                a: (chunks) => (
                                                    <a
                                                        className='text-underline font-weight-bolder text-info cursor-pointer'
                                                        onClick={() => props.fetchTemplate()}>
                                                        <u>{chunks}</u>
                                                    </a>
                                                )
                                            }}
                                        />
                                    </span>
                                </div>

                                {!props.accountId && (
                                    <AsyncComponent>
                                        <AccountSelect
                                            submitted={false}
                                            value={viewProps.accountId}
                                            onChange={viewProps.changeAccountId}
                                            onChangeObject={viewProps.changeAccount}
                                            required
                                            allowURLChange={false}
                                        />
                                    </AsyncComponent>
                                )}

                                <DropZone
                                    file={viewProps.importFile}
                                    onChange={viewProps.changeImportFile}
                                    dropZoneMessage={
                                        <FormattedMessage
                                            id='Import.DropZoneMessage'
                                            description='Dropzone message'
                                            defaultMessage='Drop a file here'
                                        />
                                    }
                                    enableImportButton
                                    importButtonLabel={CommonMessage.BUTTONS.IMPORT}
                                />
                            </>
                        )}
                    </div>
                    <ModalFooter
                        justifyContent='between'
                        buttons={[
                            {
                                hide: false,
                                text: CommonMessage.BUTTONS.CANCEL,
                                type: ClassType.SECONDARY,
                                onClick: (e) => {
                                    e?.preventDefault();
                                    props.onClose();
                                }
                            },
                            {
                                hide: viewProps.dryRunPassed || viewProps.errors.length === 0,
                                text: 'Re-upload',
                                type: ClassType.WARNING,
                                onClick: (e) => {
                                    viewProps.resolveErrors(e);
                                }
                            },
                            {
                                hide: false,
                                text: CommonMessage.BUTTONS.SUBMIT,
                                type: ClassType.SUCCESS,
                                icon: FaPlus,
                                buttonType: 'submit',
                                disabled: !viewProps.importFile || !viewProps.accountId || viewProps.errors.length > 0
                            }
                        ]}
                    />
                </form>
            </div>
        </DefaultModal>
    );
};
