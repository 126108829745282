import { Spinner } from '@frontend/elements';
import { EntityBadge } from '@frontend/pill';
import { FaCheck, FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FaXmark } from 'react-icons/fa6';

import usePermissionsList from './permissions-list.controller';

const PermissionsList = () => {
    const viewProps = usePermissionsList();
    if (!viewProps.mappedPermissions) return <Spinner />;
    const resultArray = Array.from(viewProps.mappedPermissions!.result.entries());
    const uidArray = Array.from(viewProps.mappedPermissions!.mappedUids.entries());
    const rights = ['create', 'read', 'update', 'delete'];
    return (
        <>
            <div className='card w-auto'>
                <div className='card-body '>
                    <h5>{viewProps.label} Permissions</h5>
                </div>
            </div>
            {resultArray
                .filter(([key]) => !!viewProps.entityTypePermissions[key])
                .map(([key, value]) => {
                    if (Object.keys(value).length === 0) return null;
                    const extraRights = viewProps.entityTypePermissions[key]?.filter((right) => !rights.includes(right) && right !== '*');
                    return (
                        <div
                            className='card mt-3 w-100 overflow-x-auto'
                            key={key}>
                            <div className='card-body'>
                                <table className='table table-responsive align-items-center mb-0'>
                                    <thead className='sticky-top bg-light bg-gradient shadow-sm'>
                                        <th className='text-uppercase text-primary text-sm font-weight-bolder opacity-7 ps-2 w-20'>
                                            {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
                                        </th>
                                        {viewProps.showExtraRights?.[key]
                                            ? extraRights?.map((right) => (
                                                  <th
                                                      key={right}
                                                      className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2'>
                                                      {right}
                                                  </th>
                                              ))
                                            : rights.map((right) => (
                                                  <th
                                                      key={right}
                                                      className='text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2'>
                                                      {right}
                                                  </th>
                                              ))}
                                        <th
                                            className={`text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 justify-content-end w-10 ${
                                                extraRights && extraRights.length > 0 ? 'cursor-pointer' : ''
                                            }`}>
                                            {extraRights && extraRights.length > 0 && (
                                                <span
                                                    className='text-primary'
                                                    onClick={() =>
                                                        viewProps.changeShowExtraRights({
                                                            ...viewProps.showExtraRights,
                                                            [key]: !viewProps.showExtraRights?.[key]
                                                        })
                                                    }>
                                                    {viewProps.showExtraRights?.[key] ? <FaRegEyeSlash size={16} /> : <FaRegEye size={16} />}
                                                </span>
                                            )}
                                        </th>
                                    </thead>
                                    <tbody>
                                        {uidArray.map(([uidKey, uidValue]) => {
                                            const rightsMap = viewProps.showExtraRights?.[key] ? extraRights : rights;
                                            return uidValue.map((id) => {
                                                if (!(Object.values(value) as string[][]).some((v) => v.includes(id))) return null;
                                                return (
                                                    <tr key={id}>
                                                        <td key={id}>
                                                            {id === '*' ? (
                                                                '*'
                                                            ) : (
                                                                <EntityBadge
                                                                    entityId={id}
                                                                    entityType={uidKey}
                                                                />
                                                            )}
                                                        </td>
                                                        {rightsMap?.map((right) => {
                                                            if (value[right]?.includes(id) || (Object.keys(value).includes('*') && id === '*')) {
                                                                return (
                                                                    <td
                                                                        key={right}
                                                                        className='text-xs text-primary align-content-center'>
                                                                        <FaCheck className='text-success' />
                                                                    </td>
                                                                );
                                                            } else {
                                                                return (
                                                                    <td
                                                                        key={right}
                                                                        className='text-xs text-primary align-content-center'>
                                                                        <FaXmark className='text-danger' />
                                                                    </td>
                                                                );
                                                            }
                                                        })}
                                                        <td className='text-xs text-primary align-content-center'></td>
                                                    </tr>
                                                );
                                            });
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                })}
        </>
    );
};

export default PermissionsList;
