import React from 'react';

import useDateTimeCell from './date-time-cell.controller';

const ID = 'date-time-cell';
export interface DateTimeCellProps {
    className?: string;
    date?: Date | string | null;
    displayTime?: boolean;
    inline?: boolean;
}

export const DateTimeCell = (props: DateTimeCellProps) => {
    const viewProps = useDateTimeCell(props);

    return (
        <td
            id={ID}
            className={props.className || 'text-primary text-xs align-content-center'}>
            {props.date == null ? (
                '-'
            ) : (
                <>
                    {viewProps.date}
                    {props.displayTime && (
                        <>
                            {props.inline ? ' ' : <br />}
                            {viewProps.time}
                        </>
                    )}
                </>
            )}
        </td>
    );
};

export default DateTimeCell;
