import { APIClient } from '@frontend/api-utils';
import { fileDownload } from '@frontend/common';
import { ImportLog } from '@frontend/import-logs/types';

const endpoint = '/import-api/v1';
export class ProductImportClient extends APIClient {
    //Import

    public static async fetchProductTemplateFile(accountId: string): Promise<void> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/products-template-xlsx-file`);
        const file = await this.handleBlobResponse(response);
        const fileName = response.headers.get('Content-Disposition')?.split('filename=')[1];
        fileDownload(file, fileName);
    }

    public static async postDryProductImportFile(accountId: string, catalogId: string, spot_id: string, file: FormData): Promise<ImportLog> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/catalogs/${catalogId}/spots/${spot_id}/run-dry-import-products`, file);
        return await this.handleResponse<ImportLog>(response);
    }

    public static async importProducts(accountId: string, catalogId: string, spotId: string, importLogId: string): Promise<void> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/catalogs/${catalogId}/spots/${spotId}/import-logs/${importLogId}/import-products`);
        return await this.handleVoidResponse(response);
    }

    // Export

    public static async exportAccountProducts(accountId: string): Promise<void> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/export-products`);
        const file = await this.handleBlobResponse(response);
        const fileName = response.headers.get('Content-Disposition')?.split('filename=')[1];
        fileDownload(file, fileName)
    }
}
