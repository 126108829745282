import { APIClient } from '@frontend/api-utils';
import { PostSensor } from '@frontend/device/types';

const endpoint = '/device-api/v1';
export class DeviceSensorClient extends APIClient {
    public static async postDeviceSensor(accountId: string, iotId: string, deviceId: string, body: PostSensor): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/devices/${deviceId}/sensors`, body);
        return await this.handleVoidResponse(response);
    }
}
