import { APIClient } from '@frontend/api-utils';

const endpoint = '/device-api/v1';

export class DeviceEnumClient extends APIClient {
    public static async fetchDevicesTypes(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/device-types`);
        return this.handleResponse<string[]>(response);
    }

    public static async fetchDevicesBrands(): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/device-brands`);
        return this.handleResponse<string[]>(response);
    }
}
