import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const BudgetDetail = lazy(() => import('./budget-detail/budget-detail.component'));
const BudgetEntityLink = lazy(() => import('./budget-entity-link/budget-entity-link.component'));
const BudgetRuleCreate = lazy(() => import('./budget-rule-create/budget-rule-create.component'));
const BudgetRuleUpdate = lazy(() => import('./budget-rule-update/budget-rule-update.component'));
const BudgetUpdate = lazy(() => import('./budget-update/budget-update.component'));
const EntityToBudgetLink = lazy(() => import('./entity-to-budget-link/entity-to-budget-link.component'));
const BudgetCreate = lazy(() => import('./budget-create/budget-create.component'));
const BudgetOverview = lazy(() => import('./budget-overview'));

export function BudgetRoutes() {
    return (
        <Route path='budgets'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <BudgetOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <BudgetCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':budgetId'>
                <Route
                    index
                    path='detail'
                    element={
                        <AsyncComponent>
                            <BudgetDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <BudgetUpdate />
                        </AsyncComponent>
                    }
                />
                <Route path=':entityType'>
                    <Route
                        path='link'
                        element={
                            <AsyncComponent>
                                <BudgetEntityLink />
                            </AsyncComponent>
                        }
                    />
                    <Route
                        index
                        element={<Navigate to='../detail' />}
                    />
                </Route>

                <Route path='rules'>
                    <Route path=':ruleId'>
                        <Route
                            path='update'
                            element={
                                <AsyncComponent>
                                    <BudgetRuleUpdate />
                                </AsyncComponent>
                            }
                        />
                    </Route>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <BudgetRuleCreate />
                            </AsyncComponent>
                        }
                    />
                    <Route
                        index
                        element={<Navigate to='../detail' />}
                    />
                </Route>
            </Route>
            <Route
                index
                element={<Navigate to='/admin/budgets' />}
            />
            <Route
                path='link/:entityId/:entityType/add'
                element={
                    <AsyncComponent>
                        <EntityToBudgetLink />
                    </AsyncComponent>
                }
            />
        </Route>
    );
}
