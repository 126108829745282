import React from 'react';

interface PaginationNumberProps {
    id?: string;
    index: number;
    disabled?: boolean | ((index: number) => boolean);
    highlighted: boolean | ((index: number) => boolean);
    onClick: (index: number) => void;
    styles?: {
        highlighted?: {
            li?: string;
            button?: string;
        };
        default?: {
            li?: string;
            button?: string;
        };
        disabled?: {
            li?: string;
            button?: string;
        };
    };
}

export const PaginationNumberLiClass = 'page-item';
export const PaginationNumberButtonClass = 'page-link';
export const PaginationNumberLiClassHighlighted = 'page-item active';
export const PaginationNumberButtonClassHighlighted = 'page-link text-light';
export const PaginationNumberLiClassDisabled = 'page-item disabled';
export const PaginationNumberButtonClassDisabled = 'page-link disabled';

export const PaginationNumber = (props: PaginationNumberProps) => {
    const isDisabled = props.disabled === undefined ? false : typeof props.disabled === 'boolean' ? props.disabled : props.disabled(props.index);
    const isHighlighted = typeof props.highlighted === 'boolean' ? props.highlighted : props.highlighted(props.index);

    const liClass = isDisabled
        ? (props?.styles?.disabled?.li ?? PaginationNumberLiClassDisabled)
        : isHighlighted
          ? (props?.styles?.highlighted?.li ?? PaginationNumberLiClassHighlighted)
          : (props?.styles?.default?.li ?? PaginationNumberLiClass);
    const buttonClass = isDisabled
        ? (props?.styles?.disabled?.button ?? PaginationNumberButtonClassDisabled)
        : isHighlighted
          ? (props?.styles?.highlighted?.button ?? PaginationNumberButtonClassHighlighted)
          : (props?.styles?.default?.button ?? PaginationNumberButtonClass);
    return (
        <li
            id={props.id + '-pagination-number-' + props.index}
            className={liClass}
            onClick={isDisabled ? undefined : () => props.onClick(props.index)}>
            <button className={buttonClass}>{props.index}</button>
        </li>
    );
};
