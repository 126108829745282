import { EntityType } from '@frontend/common';
import { equipmentRequest } from '@frontend/equipment/events';
import { Equipment } from '@frontend/equipment/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const EquipmentBadge = (props: ObjectBadgeProps<Equipment>) => {
    return (
        <ObjectBadge<Equipment>
            id={props.id}
            type={EntityType.EQUIPMENT}
            mapDisplayName={(eq: Equipment) => eq.serial_number}
            resolve={equipmentRequest}
            navigateString={(object: Equipment) => `/accounts/${object.account_id}/equipments/${object.id}`}
            key={props.id}
            {...props}
        />
    );
};
