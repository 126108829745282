import React from 'react';

import useTextAreaInput from './text-area-input.controller';

const ID = 'text-area-input';

export interface TextAreaProps {
    id?: string;
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string | null;
    errorMessage?: React.ReactNode;
    label: React.ReactNode;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    submitted: boolean;
    useConditionedStyling?: boolean;
    className?: string;
}

export function TextArea(props: TextAreaProps) {
    const viewProps = useTextAreaInput(props);
    return (
        <div
            id={ID}
            className={'form-group ' + props.className}>
            <label className='text-primary'>
                {props.required && <span className='text-danger me-1'>&#9679;</span>}
                {props.label}
            </label>
            <textarea
                className={viewProps.inputClass}
                ref={viewProps.ref}
                style={{ height: viewProps.ref.current?.scrollHeight ? viewProps.ref.current?.scrollHeight + 2 + 'px' : undefined }} // Not sure if this is correct, but this sets the intial height of the textarea to the content inside
                autoFocus={props.autoFocus}
                id={props.id ? props.id : props.label?.toString()}
                value={props.value ?? undefined}
                onFocus={() => {
                    viewProps.changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                required={props.required}
                onChange={(event) => {
                    if (props.onChange) props.onChange(event.target.value);
                    viewProps.isValid();
                }}
            />
            {viewProps.valid === false && viewProps.touched === true && (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage}</small>
                </span>
            )}
            {props.helpText && <span className='text-secondary text-xs'>{props.helpText}</span>}
        </div>
    );
}
