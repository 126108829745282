import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Navigate, Route } from 'react-router-dom';

const AttachModuleToIot = lazy(() => import('../iot/attach-module/attach-module.component'));
const LockerCreate = lazy(() => import('../locker/locker-create/locker-create.component'));
const LockerDetail = lazy(() => import('../locker/locker-detail/locker-detail.component'));
const LockerUpdate = lazy(() => import('../locker/locker-update/locker-update.component'));
const DeviceCreate = lazy(() => import('./device-create/device-create.component'));
const DeviceDetail = lazy(() => import('./device-detail/device-detail.component'));
const DeviceUpdate = lazy(() => import('./device-update/device-update.component'));

export function DeviceRoutes() {
    return (
        <Route path='devices'>
            <Route
                index
                element={<Navigate to='../detail' />}
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <DeviceCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':deviceId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <DeviceDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='attach-module'
                    element={
                        <AsyncComponent>
                            <AttachModuleToIot />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <DeviceUpdate />
                        </AsyncComponent>
                    }
                />
                <Route path='lockers'>
                    <Route
                        path='create'
                        element={
                            <AsyncComponent>
                                <LockerCreate />
                            </AsyncComponent>
                        }
                    />
                    <Route path=':lockerId'>
                        <Route
                            path='detail'
                            element={
                                <AsyncComponent>
                                    <LockerDetail />
                                </AsyncComponent>
                            }
                        />
                        <Route
                            path='update'
                            element={
                                <AsyncComponent>
                                    <LockerUpdate />
                                </AsyncComponent>
                            }
                        />
                    </Route>
                </Route>
            </Route>
        </Route>
    );
}
