import React from 'react';
import { HeaderGroup } from 'react-table';

interface Props {
    column: HeaderGroup<any>;
    sort: boolean;
    className?: string;
}

export function THeaderItem(props: Props) {
    if (props.column.id === 'selection' || props.column.id === 'expand') {
        return (
            <th
                className={props.className || 'ps-2'}
                {...props.column.getHeaderProps(props.column.getSortByToggleProps())}>
                {props.column.render('Header')}
            </th>
        );
    }
    return (
        <th
            {...props.column.getHeaderProps(props.column.getSortByToggleProps())}
            className={
                ((!props.sort && props.className) || 'text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2 ') +
                (props.column.isSorted ? (props.column.isSortedDesc ? 'desc' : 'asc') : '')
            }>
            {props.sort ? <a className={'dataTable-sorter ' + props.className}>{props.column.render('Header')}</a> : props.column.render('Header')}
        </th>
    );
}
