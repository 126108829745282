import { BsTranslate } from 'react-icons/bs';
import { CgDebug } from 'react-icons/cg';
import { FaChartLine, FaHome } from 'react-icons/fa';
import { LuLink } from 'react-icons/lu';
import { TbReportAnalytics } from 'react-icons/tb';

export const DETAIL_ICONS = {
    MAIN: FaHome,
    LINKED: LuLink,
    REPORTING: TbReportAnalytics,
    STATISTICS: FaChartLine,
    DEBUG: CgDebug,
    TRANSLATIONS: BsTranslate
};
