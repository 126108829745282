import { useLocalStorageState } from '@frontend/common';
import React, { forwardRef, useEffect, useState } from 'react';

export const Checkbox = forwardRef<HTMLInputElement, { original?: { id: string } & object; listId?: string; indeterminate?: boolean | undefined }>(
    ({ original, listId, indeterminate, ...rest }, ref) => {
        const resolvedRef = ref;
        const [checked, changeChecked] = useLocalStorageState<({ id: string } & object)[]>(listId || 'unknown_list', []);
        const [isChecked, changeIsChecked] = useState<boolean>(!!original && !!checked.find((o) => o.id === original.id));

        useEffect(() => {
            if (resolvedRef && resolvedRef != null && typeof resolvedRef !== 'function' && resolvedRef.current && indeterminate != undefined)
                resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        useEffect(() => {
            changeIsChecked(!!original && !!checked.find((o) => o.id === original.id));
        }, [checked]);

        return (
            <div className='form-check text-lg ms-2 my-0 py-0'>
                <input
                    className='form-check-input my-0 py-0'
                    type='checkbox'
                    ref={resolvedRef}
                    {...rest}
                    {...(original &&
                        listId && {
                            checked: isChecked,
                            onClick: (e) => {
                                if (isChecked) {
                                    changeChecked(checked.filter((o) => o.id !== original.id));
                                } else {
                                    changeChecked([...checked, original]);
                                }
                            }
                        })}
                />
            </div>
        );
    }
);

Checkbox.displayName = 'Search';
