import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Details = {
    MAIN: (
        <FormattedMessage
            id='details.main'
            defaultMessage='Main'
        />
    ),
    LINKED: (
        <FormattedMessage
            id='details.linked'
            defaultMessage='Linked'
        />
    ),
    REPORTING: (
        <FormattedMessage
            id='details.reporting'
            defaultMessage='Reporting'
        />
    ),
    STATISTICS: (
        <FormattedMessage
            id='details.statistics'
            defaultMessage='Statistics'
        />
    ),
    DEBUG: (
        <FormattedMessage
            id='details.debug'
            defaultMessage='Debug'
        />
    ),
    TRANSLATIONS: (
        <FormattedMessage
            id='details.translations'
            defaultMessage='Translations'
        />
    )
};
