import { ClassType, EntityType } from '@frontend/common';
import { DefaultModal, ModalFooter, Spinner } from '@frontend/elements';
import { FormatTypeName } from '@frontend/impex/types';
import { CommonMessage } from '@frontend/lang';
import React from 'react';
import { FaPlus } from 'react-icons/fa';

import { FormatTypeSelect } from '../format-select/format-select.component';
import useExport from './export.controller';

export interface ExportProps {
    show: boolean;
    onClose: () => void;
    export: (format: FormatTypeName) => Promise<void>;
    type: EntityType;
}

export const Export = (props: ExportProps) => {
    const viewProps = useExport(props);

    return (
        <DefaultModal
            handleClose={props.onClose}
            show={props.show}>
            <div>
                <form onSubmit={viewProps.onSubmit}>
                    <div className='modal-header'>
                        <h4>Export</h4>
                    </div>
                    <div className='modal-body'>
                        {viewProps.loading ? (
                            <Spinner />
                        ) : (
                            <>
                                <FormatTypeSelect
                                    required
                                    onChange={viewProps.changeFormat}
                                    submitted={false}
                                    value={viewProps.format}
                                />
                            </>
                        )}
                    </div>
                    <ModalFooter
                        justifyContent='between'
                        buttons={[
                            {
                                hide: false,
                                text: CommonMessage.BUTTONS.CANCEL,
                                type: ClassType.SECONDARY,
                                onClick: (e) => {
                                    e?.preventDefault();
                                    props.onClose();
                                }
                            },
                            {
                                hide: false,
                                text: CommonMessage.BUTTONS.SUBMIT,
                                type: ClassType.SUCCESS,
                                icon: FaPlus,
                                buttonType: 'submit',
                                disabled: !viewProps.format
                            }
                        ]}
                    />
                </form>
            </div>
        </DefaultModal>
    );
};
