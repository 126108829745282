import { APIClient, ApiQueryParams, DefaultQueryParams } from '@frontend/api-utils';
import { MovementReportListResponse } from '@frontend/stock/types';

const endpoint = '/stock-api/v1';

export class MovementReportClient extends APIClient {
    static async fetchMovementReport(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<MovementReportListResponse> {
        return this.apiPaginated<MovementReportListResponse, DefaultQueryParams>(`${endpoint}/movements-report`, queryParams);
    }
}
