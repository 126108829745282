import { EntityType } from '@frontend/common';
import { iotRequest } from '@frontend/iot/events';
import { IoT } from '@frontend/iot/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const IoTBadge = (props: ObjectBadgeProps<IoT>) => {
    return (
        <ObjectBadge<IoT>
            id={props.id}
            type={EntityType.IOT}
            mapDisplayName={(ac: IoT) => ac.name}
            resolve={iotRequest}
            key={props.id}
            navigateString={(object: IoT) => `/accounts/${object.account_id}/iots/${object.id}`}
            {...props}
        />
    );
};
