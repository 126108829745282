import { Logger } from "@frontend/Logger";
import { DockerImageClient } from "@frontend/version/api";
import { useEffect, useState } from "react";

interface Props {
    currentVersion: string;
    repository: string;
}
export const useCacheBusting = (props: Props) => {
    const [remoteVersion, changeRemoteVersion] = useState<string | null>(null);
    const [udpateAvailable, changeUpdateAvailable] = useState<boolean>(false);

    useEffect(() => {
        DockerImageClient.fetchDockerImages({ repository: props.repository }).then((images) => {
            if(images.count === 0) return;
            const image = images.results[0];
            if (image) changeRemoteVersion(image.tag);
        })
    }, [props.currentVersion]);

    useEffect(() => {
        if (remoteVersion && remoteVersion !== props.currentVersion) changeUpdateAvailable(true);
        else changeUpdateAvailable(false);
    }, [remoteVersion]);

    const update = () => {
        Logger.debug("Clearing cache and reloading");
        if (caches) {
            caches.keys().then(function (names) {
                for (const name of names) caches.delete(name);
            });
        }
        window.location.reload();
    };

    return { udpateAvailable, update };
}