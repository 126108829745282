import { addressRequest } from '@frontend/address/events';
import { Address } from '@frontend/address/types';
import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';


export const AddressBadge = (props: ObjectBadgeProps<Address>) => {
    const composeAddress = (address: Address) => {
        if (address.name) return address.name;
        const addressParts = [`${address.street} ${address.number}`, `${address.apartment}`, `${address.city} ${address.zip_code}`, `${address.country}`];
        return addressParts
            .map((part) => part.trim())
            .filter(Boolean)
            .join(', ');
    };

    return (
        <ObjectBadge<Address>
            id={props.id}
            type={EntityType.ADDRESS}
            mapDisplayName={(address: Address) => composeAddress(address)}
            resolve={addressRequest}
            key={props.id}
            {...props}
        />
    );
};
