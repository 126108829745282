import { EntityType } from '@frontend/common';
import { DropZone } from '@frontend/elements';
import { JobClient, JobErrorClient } from '@frontend/import/api';
import { Job, JobError } from '@frontend/import/types';
import { ObjectList } from '@frontend/rendering/list';
import { TEXT_MIME_TYPES } from '@frontend/repository';

import { useAppDispatch } from '../redux/store';
import useUserImport from './user-import.controller';

const UserImport = () => {
    const viewProps = useUserImport();
    const dispatch = useAppDispatch();
    return (
        <>
            <div className='card mt-3'>
                <div className='card-body'>
                    <button
                        className='btn btn-primary'
                        disabled={!viewProps.file}
                        onClick={viewProps.onSubmit}>
                        Upload
                    </button>
                    <DropZone
                        onChange={viewProps.changeFile}
                        file={viewProps.file}
                        dropZoneMessage='Drop your file here'
                        enableImportButton
                        importButtonLabel='Import'
                        type={TEXT_MIME_TYPES}
                    />
                </div>
            </div>
            <ObjectList<Job>
                fetch={(arg) => JobClient.fetchJobs(arg)}
                dispatch={dispatch}
                eventListener={EntityType.IMPORT}
                id='job-list'
                type={EntityType.IMPORT}
                label='Jobs'
                columns={{
                    columns: ['id', 'type', 'status', 'creation_timestamp'],
                    showIdAsEntity: EntityType.JOB
                }}
            />
            <ObjectList<JobError>
                fetch={(arg) => JobErrorClient.fetchJobErrors(arg)}
                label='Job Errors'
                dispatch={dispatch}
                eventListener={EntityType.IMPORT}
                id='job-list'
                type={EntityType.IMPORT}
                columns={{
                    columns: ['job_id', 'field', 'record', 'message']
                }}
            />
        </>
    );
};

export default UserImport;
