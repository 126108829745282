import useEntityBadge from './entity-badge.controller';

export interface EntityBadgeProps {
    entityId: string;
    entityType: string;
}

export const EntityBadge = (props: EntityBadgeProps) => {
    const viewProps = useEntityBadge(props);

    return viewProps;
};
