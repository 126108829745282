import { EntityType } from '@frontend/common';
import { deviceRequest } from '@frontend/device/events';
import { Device } from '@frontend/device/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const DeviceBadge = (props: ObjectBadgeProps<Device>) => {
    return (
        <ObjectBadge<Device>
            id={props.id}
            type={EntityType.DEVICE}
            mapDisplayName={(dev: Device) => dev.name}
            resolve={deviceRequest}
            key={props.id}
            navigateString={(object: Device) => `/accounts/${object.account_id}/iots/${object.iot_id}/devices/${object.id}`}
            {...props}
        />
    );
};
