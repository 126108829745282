import { APIClient } from "@frontend/api-utils";
import { EntityType } from "@frontend/common";
import { ImpexAction } from "@frontend/impex/types";

const endpoint = '/impex-api/v1'

export class ImpexEntityTypeFieldEnumClient extends APIClient {
    public static async fetchEntityTypeFields(entityType: EntityType, action: ImpexAction): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/entity-types/${entityType}/fields?action=${action}`);
        return this.handleResponse<string[]>(response);
    }

    public static async fetchEntityTypeMandatoryFields(entityType: EntityType, action: ImpexAction): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/entity-types/${entityType}/mandatory-fields?action=${action}`);
        return this.handleResponse<string[]>(response);
    }
}
