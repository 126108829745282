import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

import { DeviceRoutes } from '../device/device-routes';

const IoTOverview = lazy(() => import('./iot-overview/iot-overview'));
const IoTCreate = lazy(() => import('./iot-create/iot-create.component'));
const IoTDetail = lazy(() => import('./iot-detail/iot-detail.component'));
const IoTUpdate = lazy(() => import('./iot-update/iot-update.component'));
const IoTSeed = lazy(() => import('./iot-seed/iot-seed.component'));
const IoTSettingsUpdate = lazy(() => import('./iot-settings-update/iot-settings-update.component'));

const DeviceRoute = DeviceRoutes();

export function IOTRoutes() {
    return (
        <Route path='iots'>
            <Route
                index
                element={
                    <AsyncComponent>
                        <IoTOverview />
                    </AsyncComponent>
                }
            />
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <IoTCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':iotId'>
                <Route
                    path='detail'
                    element={
                        <AsyncComponent>
                            <IoTDetail />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <IoTUpdate />
                        </AsyncComponent>
                    }
                />

                <Route
                    path='seed'
                    element={
                        <AsyncComponent>
                            <IoTSeed />
                        </AsyncComponent>
                    }
                />
                <Route
                    path='settings/update'
                    element={
                        <AsyncComponent>
                            <IoTSettingsUpdate />
                        </AsyncComponent>
                    }
                />
                {DeviceRoute}
            </Route>
        </Route>
    );
}

export default IOTRoutes;
