import { DefaultQueryParams } from '@frontend/api-utils';
import { DateInput } from '@frontend/basic-forms';
import { Filter } from '@frontend/elements';
import { EnumSelectList, ObjectSelectMapper } from '@frontend/rendering/select';
import { useListRepository } from '@frontend/repository';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FaFilter } from 'react-icons/fa';

import FilterModal from './filter-modal/filter-modal.component';
import useHeaderFilter from './header-filter.controller';

export interface Props<T extends object & { id: string }> extends ReturnType<typeof useListRepository<T>> {
    filter?: FilterOptions<T>;
}

// eslint disabled because T is not used but probably will be in the future
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
export interface FilterOptions<T extends object & { id: string }> {
    keys?: (DefaultQueryParams | string | number)[];
    default?: boolean;
    keyProps?: Map<string, any>;
    queryParamKeyOverwrite?: Map<string, string>;
    position?: FilterPosition;
    hideKeys?: (string | number)[];
    custom?: ((props: Props<T>) => React.ReactNode)[];
    type?: 'platform' | 'terminal';
}

export enum FilterPosition {
    TOP = 'top',
    RIGHT = 'right'
}

export const HeaderFilter = <T extends object & { id: string }>(props: Props<T>) => {
    const viewProps = useHeaderFilter(props);
    const className = useMemo(() => {
        switch (props.filter?.position) {
            case FilterPosition.RIGHT:
                return 'd-flex flex-column w-100 my-1 mx-2 p-1 border-left justify-content-end';
            case FilterPosition.TOP:
            default:
                return 'd-flex flex-column flex-md-row flex-lg-row w-100 w-lg-50 my-1 mx-4 justify-content-end align-content-center align-items-center';
        }
    }, [props.filter?.position]);
    return (
        <div className={className}>
            {(!props.filter?.type || props.filter.type === 'terminal') && (
                <>
                    {props.filter?.custom?.map((c) => c(props))}
                    {props.filter?.keys?.map((key) => {
                        if (typeof key === 'string') {
                            const extraProps = props.filter?.keyProps?.get(key) ?? {};
                            if ((key as string).includes('_id')) {
                                return (
                                    <ObjectSelectMapper<T>
                                        key={key}
                                        id={key}
                                        objectKey={key as keyof T}
                                        submitted={false}
                                        useConditionedStyling={false}
                                        onChange={(newValue) => {
                                            props.changeParams({
                                                ...props.params,
                                                [props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]: newValue
                                            });
                                        }}
                                        className='w-100 me-1'
                                        {...extraProps}
                                        {...props}
                                    />
                                );
                            }
                            if (['from', 'to', 'timestamp'].includes(key)) {
                                let keyValue = key;
                                if (key.includes('from')) keyValue = 'after';
                                if (key.includes('to')) keyValue = 'before';
                                return (
                                    <DateInput
                                        key={key}
                                        id={key}
                                        label={key}
                                        useConditionedStyling={false}
                                        onChange={(newValue) => {
                                            props.changeParams({
                                                ...props.params,
                                                [props.filter?.queryParamKeyOverwrite?.get(keyValue.toString()) ?? keyValue]: newValue
                                                    ? moment(newValue).format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ')
                                                    : undefined
                                            });
                                        }}
                                        value={props.params?.[props.filter?.queryParamKeyOverwrite?.get(keyValue.toString()) ?? keyValue]}
                                        submitted={false}
                                        className='w-100 me-1'
                                        {...extraProps}
                                        {...props}
                                    />
                                );
                            }
                        }
                        if (key in EnumSelectList) {
                            const EnumInput = EnumSelectList[key as keyof typeof EnumSelectList];
                            return (
                                <EnumInput
                                    key={key}
                                    id={key.toString()}
                                    submitted={false}
                                    isClearable
                                    className='me-1'
                                    useConditionedStyling={false}
                                    required={false}
                                    onChange={(newValue: any) => {
                                        props.changeParams({
                                            ...props.params,
                                            [props.filter?.queryParamKeyOverwrite?.get(key.toString()) ?? key]: newValue?.value
                                        });
                                    }}
                                    {...props}
                                />
                            );
                        }
                        return <></>;
                    })}
                </>
            )}

            {props.filter?.default && (
                <Filter
                    filterValue={(value) => {
                        props.changeParams((prev) => ({ ...prev, search: value }));
                    }}
                />
            )}
            {props.filter?.type === 'platform' && props.filter?.keys && props.filter.keys.length > 0 && (
                <button
                    className='btn btn-primary ms-2 mb-0 mt-2 h-50'
                    style={{ position: 'relative' }}
                    onClick={(e) => {
                        e.preventDefault();
                        viewProps.changeShowModal(true);
                    }}>
                    <FaFilter />
                    {Object.keys(props.params).filter((p) => !p.includes('search')).length > 0 && (
                        <span
                            className='badge badge-sm badge-circle badge-info border-white'
                            style={{ position: 'absolute', top: 0, right: 0, transform: 'translateX(50%) translateY(-50%)' }}>
                            {Object.keys(props.params).filter((p) => !p.includes('search')).length}
                        </span>
                    )}
                </button>
            )}
            {viewProps.showModal && (
                <FilterModal
                    handleClose={() => viewProps.changeShowModal(false)}
                    show={viewProps.showModal}
                    changeURLParams={(v) => {
                        if (Object.keys(v).length === 0) {
                            props.changeParams({ search: props.params['search'] });
                        } else {
                            const obj = { ...props.params, ...v };
                            const cleanObj = Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== undefined && v !== null));
                            props.changeParams(cleanObj);
                        }
                    }}
                    {...props}
                    params={props.params}
                />
            )}
        </div>
    );
};
