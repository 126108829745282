import { accountRequest } from '@frontend/account/events';
import { Account } from '@frontend/account/types';
import { EntityType } from '@frontend/common';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const AccountBadge = (props: ObjectBadgeProps<Account>) => {
    return (
        <ObjectBadge<Account>
            id={props.id}
            type={EntityType.ACCOUNT}
            mapDisplayName={(ac: Account) => ac.name}
            resolve={accountRequest}
            navigateString={(object: Account) => `/accounts/${object.id}`}
            {...props}
        />
    );
};
