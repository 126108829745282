import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { DropZoneProps } from './drop-zone.component';

interface ViewProps {
    dropZoneRef: React.RefObject<HTMLDivElement>;
    isDropping: boolean;
    accept?: string;
    error?: JSX.Element | null;
}

const useDropZone = (props: DropZoneProps): ViewProps => {
    const dropZoneRef = useRef<HTMLDivElement>(null);
    const [isDropping, changeIsDropping] = useState<boolean>(false);
    const [error, changeError] = useState<JSX.Element | null>(null);
    const accept = useMemo<string | undefined>(() => {
        if (props.type == undefined) return undefined;
        if (Array.isArray(props.type)) {
            if (props.type.length === 0) return '';
            return props.type.join(', ');
        }
        if (typeof props.type === 'string') return props.type;
        return props.type.toString();
    }, [props.type]);

    useEffect(() => {
        if (!dropZoneRef) return;
        ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, preventDefaults, false);
        });
        ['dragenter', 'dragover'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, () => changeIsDropping(true), false);
        });
        ['dragleave', 'drop'].forEach((eventName) => {
            dropZoneRef.current?.addEventListener(eventName, () => changeIsDropping(false), false);
        });
        dropZoneRef.current?.addEventListener('drop', handleDrop, false);
    }, [dropZoneRef, props.file]);

    function preventDefaults(e: any) {
        e.preventDefault();
        e.stopPropagation();
    }

    function handleDrop(e: DragEvent) {
        const droppedFile = e.dataTransfer;
        if (!droppedFile) return;
        if (droppedFile.files.length > 1) {
            changeError(
                <FormattedMessage
                    id='DropZone.ErrorMessages.OnlyOneFile'
                    defaultMessage='Only 1 file can be uploaded at a time.'
                />
            );
            return;
        }
        const file = droppedFile.files.item(0);
        if (!file) return;
        if (Array.isArray(props.type)) {
            if (!(props.type as string[]).includes(file.type)) {
                changeError(
                    <FormattedMessage
                        id='DropZone.ErrorMessages.InvalidFileType'
                        defaultMessage='Invalid file type. Please upload a file of type {type}.'
                        values={{ type: props.type.join(', ') }}
                    />
                );
                return;
            }
        } else if (typeof props.type === 'string') {
            if (!file.type.includes(props.type)) {
                changeError(
                    <FormattedMessage
                        id='DropZone.ErrorMessages.InvalidFileType'
                        defaultMessage='Invalid file type. Please upload a file of type {type}.'
                        values={{ type: props.type }}
                    />
                );
                return;
            }
        }
        changeError(null);
        props.onUpload && props.onUpload(file);
        props.onChange(file);
    }

    return {
        dropZoneRef,
        isDropping,
        accept,
        error
    };
};

export default useDropZone;
