import { AttributeLinkingWithName } from '@frontend/attribute/types';
import { EntityType, useAppSelector } from '@frontend/common';
import { ObjectBadgeProps } from '@frontend/rendering/badge';
import { resolveStore } from '@frontend/repository';
import { variantLinkingRequest } from '@frontend/variant/events';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface ViewProps {
    attributes: AttributeLinkingWithName[];
    isLoading: boolean;
}

const useVariantAttributesBadge = (props: ObjectBadgeProps<AttributeLinkingWithName>): ViewProps => {
    const [attributes, changeAttributes] = useState<AttributeLinkingWithName[]>([]);
    const resolveState = useAppSelector(useSelector, resolveStore);
    const [isLoading, changeIsLoading] = useState<boolean>(false);
    useEffect(() => {
        if (!props.id) return;
        changeIsLoading(true);
        variantLinkingRequest(props.id);
    }, [props.id]);

    useEffect(() => {
        if (resolveState.resolved && resolveState.resolved[EntityType.VARIANT_LINKING]) {
            Object.keys(resolveState.resolved[EntityType.VARIANT_LINKING]).map((key) => {
                changeIsLoading(false);
                const found = (resolveState.resolved![EntityType.VARIANT_LINKING][key] as any as { variant_id: string }).variant_id == props.id;
                if (!found) return;
                changeAttributes((prev) => {
                    if (prev.find((attr) => attr.id === key)) return prev;
                    return [...prev, { ...(resolveState.resolved![EntityType.VARIANT_LINKING][key] as AttributeLinkingWithName) }];
                });
            });
        }
    }, [resolveState, props.id]);

    return {
        attributes,
        isLoading
    };
};

export default useVariantAttributesBadge;
