import { AccountBadge } from '@frontend/account/badge';
import { EntityType } from '@frontend/common';
import { ContactBadge } from '@frontend/contact/badge';
import { DeviceBadge } from '@frontend/device/badge';
import { EquipmentBadge } from '@frontend/equipment/badge';
import { GroupBadge } from '@frontend/group/badge';
import { IoTBadge } from '@frontend/iot/badge';
import { ProductBadge } from '@frontend/product/badge';
import { CustomRoleBadge } from '@frontend/role/badge';
import { SlotBadge } from '@frontend/slot/badge';
import { SpotBadge } from '@frontend/spot/badge';
import { TransactionBadge } from '@frontend/transaction/badge';
import { UserBadge } from '@frontend/user/badge';
import { WorkflowBadge } from '@frontend/workflow/badge';
import React from 'react';

import { EntityBadgeProps } from './entity-badge.component';

const useEntityBadge = (props: EntityBadgeProps): React.JSX.Element => {
    switch (props.entityType) {
        case 'account':
            return <AccountBadge id={props.entityId} />;
        case 'slot':
            return <SlotBadge id={props.entityId} />;
        case 'contact':
            return <ContactBadge id={props.entityId} />;
        case 'user':
            return <UserBadge id={props.entityId} />;
        case 'spot':
            return <SpotBadge id={props.entityId} />;
        case 'workflow':
            return <WorkflowBadge id={props.entityId} />;
        case 'transaction':
            return <TransactionBadge id={props.entityId} />;
        case 'iot':
            return <IoTBadge id={props.entityId} />;
        case 'device':
            return <DeviceBadge id={props.entityId} />;
        case 'product':
            return <ProductBadge id={props.entityId} />;
        case 'equipment':
            return <EquipmentBadge id={props.entityId} />;
        case 'custom_role':
            return <CustomRoleBadge id={props.entityId} />;
        case 'user_group_id':
            return (
                <GroupBadge
                    id={props.entityId}
                    type={EntityType.USER_GROUP}
                />
            );
        case 'product_group_id':
        case 'product_group':
            return (
                <GroupBadge
                    id={props.entityId}
                    type={EntityType.PRODUCT_GROUP}
                />
            );
        case 'product_category_id':
        case 'product_category':
            return (
                <GroupBadge
                    id={props.entityId}
                    type={EntityType.PRODUCT_CATEGORY}
                />
            );
        case 'contact_group_id':
        case 'contact_group':
            return (
                <GroupBadge
                    id={props.entityId}
                    type={EntityType.CONTACT_GROUP}
                />
            );
        case 'questionnaire_id':
        case 'consume_questionnaire_id':
        case 'return_questionnaire_id':
        case 'questionnaire':
        case 'consume_questionnaire':
        case 'return_questionnaire':
            return (
                <GroupBadge
                    id={props.entityId}
                    type={EntityType.QUESTIONNAIRE}
                />
            );
        case 'equipment_group_id':
        case 'equipment_group':
            return (
                <GroupBadge
                    id={props.entityId}
                    type={EntityType.EQUIPMENT_GROUP}
                />
            );
        default:
            return <></>;
    }
};

export default useEntityBadge;
