import { APIClient, ApiQueryParams, DefaultQueryParams } from "@frontend/api-utils";
import { PaginatedJobResponse } from "@frontend/import/types";

const endpoint = '/import-api/v1'

export class JobClient extends APIClient {
    public static async fetchJobs(queryParams?: ApiQueryParams<DefaultQueryParams>): Promise<PaginatedJobResponse> {
        return await this.apiPaginated<PaginatedJobResponse, DefaultQueryParams>(`${endpoint}/jobs`, queryParams);
    }

    public static async resolveJobs(jobIds: string[]): Promise<PaginatedJobResponse> {
        const response = await this.post(`${endpoint}/jobs-resolve`, { ids: jobIds });
        return await this.handleResponse<PaginatedJobResponse>(response);
    }
}