import { useEffect, useMemo, useState } from 'react';

import { getClassName, getInputClassMultiSelect } from '../input-class';
import { AsyncSelectInputProps } from './async-select-input.component';

interface ViewProps {
    value: any;
    valid: boolean;
    touched: boolean;
    changeValue: React.Dispatch<any>;
    isValid: () => boolean;
    changeTouched: React.Dispatch<React.SetStateAction<boolean>>;
    className: string;
    findValue: () => any;
}

const useAsyncSelectInput = (props: AsyncSelectInputProps): ViewProps => {
    const [touched, changeTouched] = useState<boolean>(false);
    const [valid, changeValid] = useState<boolean>(props.valid !== undefined ? props.valid : true);
    const [value, changeValue] = useState<any | null | undefined>(null);
    const className = useMemo(() => {
        return props.useConditionedStyling === false
            ? 'form-control'
            : props.isMulti
              ? getInputClassMultiSelect(touched, valid, props.value)
              : getClassName(touched, valid, props.value);
    }, [props.useConditionedStyling, props.isMulti]);

    const isValid = (): boolean => {
        let check = true;
        if ((props.value === undefined || props.value === null) && props.required === true) check = false;
        if (props.isMulti) {
            if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
        } else {
            if (props.value && (props.value.value || props.value.value === undefined || props.value.value.length === 0)) {
                if (
                    check === true &&
                    props.required === true &&
                    (props.value.value === undefined || props.value.value === null || props.value.value.length === 0)
                )
                    check = false;
            } else {
                if (check === true && props.required === true && (props.value === undefined || props.value === null || props.value.length === 0)) check = false;
            }
        }
        if (props.valid !== undefined && check === true) check = props.valid;
        changeValid(check);
        return check;
    };

    useEffect(() => {
        if (props.isValidCallback) {
            props.isValidCallback(isValid());
        } else isValid();
    }, [props.value, props.valid]);

    useEffect(() => {
        if (props.submitted) {
            !touched && changeTouched(true);
        }
    }, [props.submitted]);

    const multiValues = props.isMulti === true && props.value !== undefined ? props.options?.filter((op: any) => props.value.includes(op.value)) : [];

    const findValue = () => {
        if (props.isMulti) {
            return multiValues;
        }
        if (props.value !== undefined && props.value !== null) {
            if (props.options) {
                return props.options.find((op) => op && op.value === (props.value.value || props.value));
            } else if (props.defaultOptions && Array.isArray(props.defaultOptions)) {
                return props.defaultOptions.find((s) => s && s.value == props.value);
            } else return value;
        } else {
            return null;
        }
    };
    return {
        valid,
        value,
        touched,
        className,
        findValue,
        changeValue,
        isValid,
        changeTouched
    };
};

export default useAsyncSelectInput;
