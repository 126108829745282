import { EntityType } from '@frontend/common';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const Objects = {
    DEFAULT: {
        CREATION_TIMESTAMP: (
            <FormattedMessage
                id='ContactList.Table.Title.CreationTimestamp'
                description='Table title for creation date'
                defaultMessage='Created date'
            />
        ),
        UPDATE_TIMESTAMP: (
            <FormattedMessage
                id='Objects.DEFAULT.UPDATE_TIMESTAMP'
                description='Table title for update date'
                defaultMessage='Updated date'
            />
        ),
        VALID_TIMESTAMP: (
            <FormattedMessage
                id='Objects.DEFAULT.VALID_TIMESTAMP'
                description='Table title for valid date'
                defaultMessage='Valid date'
            />
        )
    },
    NOTIFICATION_CONTENT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.NOTIFICATION_CONTENT.DEFAULT'
                description='Label for notification content'
                defaultMessage='Notification content'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.NOTIFICATION_CONTENT.PLURAL'
                description='Plural name for notification contents'
                defaultMessage='Notification contents'
            />
        )
    },
    NOTIFICATION_TEMPLATE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.NOTIFICATION_TEMPLATE.DEFAULT'
                description='Label for notification template'
                defaultMessage='Notification template'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.NOTIFICATION_TEMPLATE.PLURAL'
                description='Plural name for notification templates'
                defaultMessage='Notification templates'
            />
        )
    },
    VARIABLE: {
        NAME: typedVariable
    },
    EVENT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.EVENT.DEFAULT'
                description='Label for event'
                defaultMessage='Event'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.EVENT.PLURAL'
                description='Plural name for events'
                defaultMessage='Events'
            />
        ),
        SYNC_EVENTS: (
            <FormattedMessage
                id='Objects.EVENT.SYNC_EVENTS'
                description='Label for sync events'
                defaultMessage='Sync Events'
            />
        )
    },
    SYNC: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.SYNC.DEFAULT'
                description='Label for sync'
                defaultMessage='Sync'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.SYNC.PLURAL'
                description='Plural name for syncs'
                defaultMessage='Syncs'
            />
        )
    },
    CHANGE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.CHANGE.DEFAULT'
                description='Label for change'
                defaultMessage='Change'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.CHANGE.PLURAL'
                description='Plural name for changes'
                defaultMessage='Changes'
            />
        )
    },
    SEED: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.SEED.DEFAULT'
                description='Label for seed'
                defaultMessage='Seed'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.SEED.PLURAL'
                description='Plural name for seeds'
                defaultMessage='Seeds'
            />
        )
    },
    RULE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.RULE.DEFAULT'
                description='Label for rule'
                defaultMessage='Rule'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.RULE.PLURAL'
                description='Plural name for rules'
                defaultMessage='Rules'
            />
        )
    },
    PIN_CODE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PIN_CODE.DEFAULT'
                description='Label for pin code'
                defaultMessage='Pin code'
            />
        ),
        CODE: (
            <FormattedMessage
                id='Objects.PIN_CODE.CODE'
                description='Label for code'
                defaultMessage='Code'
            />
        ),
        CHALLENGE: (
            <FormattedMessage
                id='Objects.PIN_CODE.CHALLENGE'
                description='Label for pin code challenge'
                defaultMessage='Challenge'
            />
        )
    },
    CONTACT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.CONTACT.DEFAULT'
                description='Label for contact'
                defaultMessage='Contact'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.CONTACT.PLURAL'
                description='Plural name for contacts'
                defaultMessage='Contacts'
            />
        )
    },
    CONTACT_GROUP: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.CONTACT_GROUP.DEFAULT'
                description='Label for contact group'
                defaultMessage='Contact group'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.CONTACT_GROUP.PLURAL'
                description='Plural name for contact groups'
                defaultMessage='Contact groups'
            />
        )
    },
    IMPEX: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.IMPEX.DEFAULT'
                description='Label for import/export'
                defaultMessage='Import/Export'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.IMPEX.PLURAL'
                description='Plural name for imports/exports'
                defaultMessage='Imports/Exports'
            />
        )
    },
    STOCK: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.STOCK.DEFAULT'
                description='Label for stock'
                defaultMessage='Stock'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.STOCK.PLURAL'
                description='Plural name for stocks'
                defaultMessage='Stocks'
            />
        ),
        UPDATE: {
            DEFAULT: (
                <FormattedMessage
                    id='Objects.STOCK.UPDATE.DEFAULT'
                    description='Label for stock update'
                    defaultMessage='Stock update'
                />
            ),
            PLURAL: (
                <FormattedMessage
                    id='Objects.STOCK.UPDATE.PLURAL'
                    description='Plural name for stock updates'
                    defaultMessage='Stock updates'
                />
            )
        }
    },
    UNKNOWN: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.UNKNOWN.DEFAULT'
                description='Label for unknown'
                defaultMessage='Unknown'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.UNKNOWN.PLURAL'
                description='Plural name for unknowns'
                defaultMessage='Unknowns'
            />
        )
    },
    PROBLEM: {
        REPORT_PROBLEM: (
            <FormattedMessage
                id='Objects.PROBLEM.REPORT_PROBLEM'
                description='Label for report problem'
                defaultMessage='Report problem'
            />
        ),
        DEFAULT: (
            <FormattedMessage
                id='Objects.PROBLEM.DEFAULT'
                description='Label for problem'
                defaultMessage='Problem'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.PROBLEM.PLURAL'
                description='Plural name for problems'
                defaultMessage='Problems'
            />
        )
    },
    DEVICE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.DEVICE.DEFAULT'
                description='Label for device'
                defaultMessage='Device'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.DEVICE.PLURAL'
                description='Plural name for devices'
                defaultMessage='Devices'
            />
        ),
        QR: (
            <FormattedMessage
                id='Objects.DEVICE.QR'
                description='Label for QR device'
                defaultMessage='QR'
            />
        ),
        NFC: (
            <FormattedMessage
                id='Objects.DEVICE.NFC'
                description='Label for NFC device'
                defaultMessage='NFC'
            />
        )
    },
    DRIVER: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.DRIVER.DEFAULT'
                description='Label for driver'
                defaultMessage='Driver'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.DRIVER.PLURAL'
                description='Plural name for drivers'
                defaultMessage='Drivers'
            />
        )
    },
    VARIANT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.VARIANT.DEFAULT'
                description='Label for variant'
                defaultMessage='Variant'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.VARIANT.PLURAL'
                description='Plural name for variants'
                defaultMessage='Variants'
            />
        )
    },
    LOCKER: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.DEVICE.LOCKER.DEFAULT'
                description='Label for locker'
                defaultMessage='Locker'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.DEVICE.LOCKER.PLURAL'
                description='Plural name for lockers'
                defaultMessage='Lockers'
            />
        )
    },
    REQUIREMENT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.REQUIREMENT.DEFAULT'
                description='Label for requirement'
                defaultMessage='Requirement'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.REQUIREMENT.PLURAL'
                description='Plural name for requirements'
                defaultMessage='Requirements'
            />
        )
    },
    ENTITY_TYPE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ENTITY_TYPE.DEFAULT'
                description='Label for entity type'
                defaultMessage='Entity type'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.ENTITY_TYPE.PLURAL'
                description='Plural name for entity types'
                defaultMessage='Entity types'
            />
        )
    },
    IOT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.IOT.DEFAULT'
                description='Label for IoT'
                defaultMessage='IoT'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.IOT.PLURAL'
                description='Plural name for IoTs'
                defaultMessage='IoTs'
            />
        ),
        VENDING_MACHINE: (
            <FormattedMessage
                id='Objects.IOT.VENDING_MACHINE'
                description='Label for vending machine'
                defaultMessage='Vending machine'
            />
        ),
        TWIN_IOT: (
            <FormattedMessage
                id='Objects.IOT.TWIN_IOT'
                description='Label for twin IoT'
                defaultMessage='Twin IoT'
            />
        )
    },
    SETTING: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.SETTING.DEFAULT'
                description='Label for setting'
                defaultMessage='Setting'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.SETTING.PLURAL'
                description='Plural name for settings'
                defaultMessage='Settings'
            />
        )
    },
    EQUIPMENT_PROBLEM: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.EQUIPMENT_PROBLEM.DEFAULT'
                description='Label for equipment problem'
                defaultMessage='Equipment problem'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.EQUIPMENT_PROBLEM.PLURAL'
                description='Plural name for equipment problems'
                defaultMessage='Equipment problems'
            />
        ),
        PROBLEM: (
            <FormattedMessage
                id='Objects.EQUIPMENT_PROBLEM.PROBLEM'
                description='Label for problem'
                defaultMessage='Problem'
            />
        )
    },
    IDENTIFICATION: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.IDENTIFICATION.DEFAULT'
                description='Label for identification'
                defaultMessage='Identification'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.IDENTIFICATION.PLURAL'
                description='Plural name for identifications'
                defaultMessage='Identifications'
            />
        ),
        SECRET: (
            <FormattedMessage
                id='Objects.IDENTIFICATION.SECRET'
                description='Label for secret'
                defaultMessage='Secret'
            />
        ),
        INDEFINITELY: (
            <FormattedMessage
                id='Objects.IDENTIFICATION.INDEFINITELY'
                description='Label for indefinitely'
                defaultMessage='Indefinitely'
            />
        ),
        PENDING_LINK_MESSAGE: PendingLinkMessage,
        SUCCESS_LINK_MESSAGE: SuccessLinkMessage,
        ERROR_LINK_MESSAGE: ErrorLinkMessage,
        PENDING_UNLINK_MESSAGE: PendingUnlinkMessage,
        SUCCESS_UNLINK_MESSAGE: SuccessUnlinkMessage,
        ERROR_UNLINK_MESSAGE: ErrorUnlinkMessage,
        BASIC_AUTH: (
            <FormattedMessage
                id='Objects.IDENTIFICATION.BASIC_AUTH'
                description='Label for basic auth'
                defaultMessage='Basic auth'
            />
        ),
        PASSWORD: (
            <FormattedMessage
                id='Objects.IDENTIFICATION.PASSWORD'
                description='Label for password'
                defaultMessage='Password'
            />
        ),
        BADGE: (
            <FormattedMessage
                id='Objects.IDENTIFICATION.BADGE'
                description='Label for badge'
                defaultMessage='Badge'
            />
        )
    },
    MEDIA_FILE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.MEDIA_FILE.DEFAULT'
                description='Label for media file'
                defaultMessage='Media file'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.MEDIA_FILE.PLURAL'
                description='Plural name for media files'
                defaultMessage='Media files'
            />
        ),
        FILE: (
            <FormattedMessage
                id='Objects.MEDIA_FILE.FILE'
                description='Label for file'
                defaultMessage='File'
            />
        ),
        TYPE: (
            <FormattedMessage
                id='Objects.MEDIA_FILE.TYPE'
                description='Label for type'
                defaultMessage='File type'
            />
        ),
        SIZE: (
            <FormattedMessage
                id='Objects.MEDIA_FILE.SIZE'
                description='Label for file size'
                defaultMessage='File size'
            />
        )
    },
    QUESTION_GROUP: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.QUESTION_GROUP.DEFAULT'
                description='Label for question group'
                defaultMessage='Question group'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.QUESTION_GROUP.PLURAL'
                description='Plural name for question group'
                defaultMessage='Questionnaires'
            />
        )
    },
    QUESTION: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.QUESTION.DEFAULT'
                description='Label for question'
                defaultMessage='Question'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.QUESTION.PLURAL'
                description='Plural name for questions'
                defaultMessage='Questions'
            />
        ),
        IMAGE_OR_VIDEO: (
            <FormattedMessage
                id='Objects.QUESTION.IMAGE_OR_VIDEO'
                description='Label for image or video'
                defaultMessage='Image/Video'
            />
        ),
        DURATION: (
            <FormattedMessage
                id='Objects.QUESTION.DURATION'
                description='Label for duration'
                defaultMessage='Duration'
            />
        )
    },
    ANSWER: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ANSWER.DEFAULT'
                description='Label for answer'
                defaultMessage='Answer'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.ANSWER.PLURAL'
                description='Plural name for answers'
                defaultMessage='Answers'
            />
        ),
        ILLUSTRATION: (
            <FormattedMessage
                id='Objects.ANSWER.ILLUSTRATION'
                description='Label for illustration'
                defaultMessage='Illustration'
            />
        ),
        SORTING: (
            <FormattedMessage
                id='Objects.ANSWER.SORTING'
                description='Label for sorting'
                defaultMessage='Sorting'
            />
        ),
        RESULT: (
            <FormattedMessage
                id='Objects.ANSWER.RESULT'
                description='Label for result'
                defaultMessage='Result'
            />
        ),
        VIDEO: (
            <FormattedMessage
                id='Objects.ANSWER.VIDEO'
                description='Label for video'
                defaultMessage='Video'
            />
        ),
        IMAGE_OR_VIDEO: (
            <FormattedMessage
                id='Objects.ANSWER.IMAGE_OR_VIDEO'
                description='Label for image or video'
                defaultMessage='Image/Video'
            />
        )
    },
    TAG: {
        TAG: (
            <FormattedMessage
                id='Objects.TAG.TAG'
                description='Label for tag'
                defaultMessage='Tag'
            />
        ),
        DEFAULT: (
            <FormattedMessage
                id='Objects.TAG.DEFAULT'
                description='Label for Tag'
                defaultMessage='Tag'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.TAG.PLURAL'
                description='Plural name for Tags'
                defaultMessage='Tags'
            />
        )
    },
    ROLE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ROLE.DEFAULT'
                description='Label for role'
                defaultMessage='Role'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.ROLE.PLURAL'
                description='Plural name for roles'
                defaultMessage='Roles'
            />
        )
    },
    CUSTOM_ROLE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.CUSTOM_ROLE.DEFAULT'
                description='Label for custom role'
                defaultMessage='Custom role'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.CUSTOM_ROLE.PLURAL'
                description='Plural name for custom roles'
                defaultMessage='Custom roles'
            />
        )
    },
    PREDEFINED_ROLE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PREDEFINED_ROLE.DEFAULT'
                description='Label for predefined role'
                defaultMessage='Predefined role'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.PREDEFINED_ROLE.PLURAL'
                description='Plural name for predefined roles'
                defaultMessage='Predefined roles'
            />
        )
    },
    EQUIPMENT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.EQUIPMENT.DEFAULT'
                description='Label for equipment'
                defaultMessage='Equipment'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.EQUIPMENT.PLURAL'
                description='Plural name for equipment'
                defaultMessage='Equipments'
            />
        ),
        SERIAL_NUMBER: (
            <FormattedMessage
                id='Objects.EQUIPMENT.SERIAL_NUMBER'
                description='Label for serial number'
                defaultMessage='Serial number'
            />
        ),
        LINKED_EQUIPMENT: (
            <FormattedMessage
                id='Objects.EQUIPMENT.LINKED_EQUIPMENT'
                description='Label for linked equipment'
                defaultMessage='Linked equipment'
            />
        )
    },
    EQUIPMENT_GROUP: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.EQUIPMENT_GROUP.DEFAULT'
                description='Label for equipment group'
                defaultMessage='Equipment group'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.EQUIPMENT_GROUP.PLURAL'
                description='Plural name for equipment group'
                defaultMessage='Equipment groups'
            />
        )
    },
    ROLE_PERMISSION: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ROLE_PERMISSION.DEFAULT'
                description='Label for role permission'
                defaultMessage='Role permission'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.ROLE_PERMISSION.PLURAL'
                description='Plural name for role permissions'
                defaultMessage='Role permissions'
            />
        ),
        RIGHT: (
            <FormattedMessage
                id='Objects.ROLE_PERMISSION.RIGHT'
                description='Label for right'
                defaultMessage='Right'
            />
        )
    },
    PERMISSION: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PERMISSION.DEFAULT'
                description='Label for permission'
                defaultMessage='Permission'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.PERMISSION.PLURAL'
                description='Plural name for permissions'
                defaultMessage='Permissions'
            />
        )
    },
    PLAYLIST_ITEM: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PLAYLIST_ITEM.DEFAULT'
                description='Label for playlist item'
                defaultMessage='Playlist item'
            />
        ),
        SORT_ORDER: (
            <FormattedMessage
                id='Objects.PLAYLIST_ITEM.SORT_ORDER'
                description='Order play list items are sorted'
                defaultMessage='Sort order'
            />
        ),
        DURATION: (
            <FormattedMessage
                id='Objects.PLAYLIST_ITEM.DURATION'
                description='Duration of an image or a video appearance'
                defaultMessage='Duration'
            />
        ),
        VISIBLE: (
            <FormattedMessage
                id='Objects.PLAYLIST_ITEM.VISIBLE'
                description='Label for visibility'
                defaultMessage='Visibility'
            />
        )
    },
    PLAYLIST: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PLAYLIST.DEFAULT'
                description='Label for playlist'
                defaultMessage='Playlist'
            />
        )
    },
    COURIER: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.COURIER.DEFAULT'
                description='Label for courier'
                defaultMessage='Courier'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.COURIER.PLURAL'
                description='Label for couriers'
                defaultMessage='Couriers'
            />
        )
    },
    DOCUMENT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.DOCUMENT.DEFAULT'
                description='Label for document'
                defaultMessage='Document'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.DOCUMENT.PLURAL'
                description='Plural name for documents'
                defaultMessage='Documents'
            />
        )
    },
    BUDGET: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.BUDGET.DEFAULT'
                description='Label for budget'
                defaultMessage='Budget'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.BUDGET.PLURAL'
                description='Plural name for budgets'
                defaultMessage='Budgets'
            />
        ),
        FROM_TIMESTAMP: (
            <FormattedMessage
                id='Objects.BUDGET.FROM_TIMESTAMP'
                description='Label for from timestamp'
                defaultMessage='From'
            />
        ),
        TO_TIMESTAMP: (
            <FormattedMessage
                id='Objects.BUDGET.TO_TIMESTAMP'
                description='Label for to timestamp'
                defaultMessage='To'
            />
        )
    },
    BUDGET_RULE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.BUDGET_RULE.DEFAULT'
                description='Label for budget rule'
                defaultMessage='Budget rule'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.BUDGET_RULE.PLURAL'
                description='Plural name for budget rules'
                defaultMessage='Budget rules'
            />
        )
    },
    BUDGET_USE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.BUDGET_USE.DEFAULT'
                description='Label for budget use'
                defaultMessage='Budget use'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.BUDGET_USE.PLURAL'
                description='Plural name for budget uses'
                defaultMessage='Budget uses'
            />
        )
    },
    GROUP: {
        PLURAL: (
            <FormattedMessage
                id='Objects.GROUP.PLURAL'
                description='Plural name for groups'
                defaultMessage='Groups'
            />
        ),
        DEFAULT: (
            <FormattedMessage
                id='Objects.GROUP.DEFAULT'
                description='Label for group'
                defaultMessage='Group'
            />
        )
    },
    GROUP_MEMBER: {
        PLURAL: (
            <FormattedMessage
                id='Objects.GROUP_MEMBER.PLURAL'
                description='Plural name for group members'
                defaultMessage='Group members'
            />
        ),
        ADD: groupMemberAdd
    },
    RAKINDA: {
        MAC_ADDRESS: (
            <FormattedMessage
                id='Objects.RAKINDA.MAC_ADDRESS'
                description='Label for mac address'
                defaultMessage='Mac address'
            />
        ),
        STATUS: (
            <FormattedMessage
                id='Objects.RAKINDA.STATUS'
                description='Label for status'
                defaultMessage='Status'
            />
        ),
        HEARTBEAT: (
            <FormattedMessage
                id='Objects.RAKINDA.HEARTBEAT'
                description='Label for heartbeat'
                defaultMessage='Heartbeat'
            />
        ),
        MASTER_PIN_CODE: (
            <FormattedMessage
                id='Forms.MASTER_PIN_CODE'
                description='Label for master pin code'
                defaultMessage='Master pin code'
            />
        )
    },
    SLOT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.SLOT.DEFAULT'
                description='Label for slot'
                defaultMessage='SLOT'
            />
        ),
        SLOTS: (
            <FormattedMessage
                id='Objects.SLOT.SLOTS'
                description='Message for SLOTs'
                defaultMessage='SLOTs'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.SLOT.PLURAL'
                description='Plural name for slots'
                defaultMessage='SLOTs'
            />
        ),
        TEMPLATE: (
            <FormattedMessage
                id='Objects.SLOT.TEMPLATE'
                description='Label for slot template'
                defaultMessage='SLOT template'
            />
        ),
        TEMPLATE_PLURAL: (
            <FormattedMessage
                id='Objects.SLOT.TEMPLATE_PLURAL'
                description='Plural name for slot templates'
                defaultMessage='SLOT templates'
            />
        ),
        NOTE: (
            <FormattedMessage
                id='Objects.SLOT.NOTE'
                description='Label for note'
                defaultMessage='Note'
            />
        ),
        DISPLAY: (
            <FormattedMessage
                id='Objects.SLOT.DISPLAY'
                description='Label for display'
                defaultMessage='Display'
            />
        ),
        DROP_STATION_SLOT: (
            <FormattedMessage
                id='Objects.SLOT.DROP_STATION_SLOT'
                description='Label for drop station slot'
                defaultMessage='Drop station slot'
            />
        ),
        ROW: (
            <FormattedMessage
                id='Objects.SLOT.ROW'
                description='Label for row'
                defaultMessage='Row'
            />
        ),
        COLUMN: (
            <FormattedMessage
                id='Objects.SLOT.COLUMN'
                description='Label for column'
                defaultMessage='Column'
            />
        ),
        STATUS: {
            DEFAULT: (
                <FormattedMessage
                    id='Objects.SLOT.STATUS'
                    description='Label for status'
                    defaultMessage='Status'
                />
            ),
            BROKEN: (
                <FormattedMessage
                    id='Objects.SLOT.STATUS.BROKEN'
                    description='Label for broken status'
                    defaultMessage='Broken'
                />
            ),
            AVAILABLE: (
                <FormattedMessage
                    id='Objects.SLOT.STATUS.AVAILABLE'
                    description='Label for available status'
                    defaultMessage='Available'
                />
            )
        },
        PENDING_OPENING_MESSAGE: PendingOpeningMessage,
        SUCCESS_OPENING_MESSAGE: SuccessOpeningMessage,
        ERROR_OPENING_MESSAGE: ErrorOpeningMessage
    },
    DIMENSION: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.DIMENSION.DEFAULT'
                description='Label for dimension'
                defaultMessage='Dimension'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.DIMENSION.PLURAL'
                description='Plural name for dimensions'
                defaultMessage='Dimensions'
            />
        ),
        DEPTH: (
            <FormattedMessage
                id='Objects.DIMENSION.DEPTH'
                description='Label for depth'
                defaultMessage='Depth'
            />
        ),
        WIDTH: (
            <FormattedMessage
                id='Objects.DIMENSION.WIDTH'
                description='Label for width'
                defaultMessage='Width'
            />
        ),
        HEIGHT: (
            <FormattedMessage
                id='Objects.DIMENSION.HEIGHT'
                description='Label for height'
                defaultMessage='Height'
            />
        )
    },
    BADGE: {
        NUMBER: (
            <FormattedMessage
                id='Objects.BADGE.NUMBER'
                description='Label for number'
                defaultMessage='Number'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.BADGE.PLURAL'
                description='Plural name for badges'
                defaultMessage='Badges'
            />
        ),
        DEFAULT: (
            <FormattedMessage
                id='Objects.BADGE.DEFAULT'
                description='Label for badge'
                defaultMessage='Badge'
            />
        )
    },
    CATEGORY: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.CATEGORY.DEFAULT'
                description='Label for category'
                defaultMessage='Category'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.CATEGORY.PLURAL'
                description='Plural name for categories'
                defaultMessage='Categories'
            />
        ),
        PARENT_CATEGORY: (
            <FormattedMessage
                id='Objects.CATEGORY.PARENT_CATEGORY'
                description='Label for parent category'
                defaultMessage='Parent category'
            />
        )
    },
    ATTRIBUTE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ATTRIBUTE.DEFAULT'
                description='Label for attribute'
                defaultMessage='Attribute'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.ATTRIBUTE.PLURAL'
                description='Plural name for attributes'
                defaultMessage='Attributes'
            />
        ),
        CHOICES: (
            <FormattedMessage
                id='Objects.ATTRIBUTE.CHOICES'
                description='Plural name for attribute choices'
                defaultMessage='Choices'
            />
        )
    },
    ATTRIBUTE_LINKING: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ATTRIBUTE_LINKING.DEFAULT'
                description='Label for attribute linking'
                defaultMessage='Attribute link'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.ATTRIBUTE_LINKING.PLURAL'
                description='Plural name for attribute linkings'
                defaultMessage='Attribute linkings'
            />
        ),
        CHOICE: (
            <FormattedMessage
                id='Objects.ATTRIBUTE_LINKING.CHOICE'
                description='Plural name for attribute linking choice'
                defaultMessage='Choice'
            />
        )
    },
    PRODUCT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PRODUCTS.DEFAULT'
                description='Label for products'
                defaultMessage='Product'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.PRODUCTS.PLURAL'
                description='Plural name for products'
                defaultMessage='Products'
            />
        ),
        EAN: (
            <FormattedMessage
                id='Objects.PRODUCTS.EAN'
                description='Label for EAN'
                defaultMessage='EAN'
            />
        ),
        SKU: (
            <FormattedMessage
                id='Objects.PRODUCTS.SKU'
                description='Label for SKU'
                defaultMessage='SKU'
            />
        ),
        SUPPLIER: (
            <FormattedMessage
                id='Objects.PRODUCTS.SUPPLIER'
                description='Label for supplier'
                defaultMessage='Supplier'
            />
        ),
        OWNER: (
            <FormattedMessage
                id='Objects.PRODUCTS.OWNER'
                description='Label for owner'
                defaultMessage='Owner'
            />
        )
    },
    PRODUCT_GROUP: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PRODUCT_GROUP.DEFAULT'
                description='Label for product group'
                defaultMessage='Product group'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.PRODUCT_GROUP.PLURAL'
                description='Plural name for product groups'
                defaultMessage='Product groups'
            />
        )
    },
    USER_GROUP: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.USER_GROUP.DEFAULT'
                description='Label for user group'
                defaultMessage='User group'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.USER_GROUP.PLURAL'
                description='Plural name for user groups'
                defaultMessage='User groups'
            />
        )
    },
    DEVICE_GROUP: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.DEVICE_GROUP.DEFAULT'
                description='Label for device group'
                defaultMessage='Device group'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.DEVICE_GROUP.PLURAL'
                description='Plural name for device groups'
                defaultMessage='Device groups'
            />
        )
    },
    USER_INTERFACE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.USER_INTERFACE.DEFAULT'
                description='Label for user interface'
                defaultMessage='User interface'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.USER_INTERFACE.PLURAL'
                description='Plural name for user interfaces'
                defaultMessage='User interfaces'
            />
        )
    },
    USER_INTERFACE_BUTTON: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.USER_INTERFACE_BUTTON.DEFAULT'
                description='Label for user interface button'
                defaultMessage='User interface button'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.USER_INTERFACE_BUTTON.PLURAL'
                description='Plural name for user interface buttons'
                defaultMessage='User interface buttons'
            />
        )
    },
    WORKFLOW: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.WORKFLOW.DEFAULT'
                description='Label for workflow'
                defaultMessage='Workflow'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.WORKFLOW.PLURAL'
                description='Plural name for workflows'
                defaultMessage='Workflows'
            />
        ),
        STATE: (
            <FormattedMessage
                id='Objects.WORKFLOW.STATE'
                description='Label for change state'
                defaultMessage='Change state'
            />
        )
    },
    WORKFLOW_STEP: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.WORKFLOW_STEP.DEFAULT'
                description='Label for workflow step'
                defaultMessage='Workflow step'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.WORKFLOW_STEP.PLURAL'
                description='Plural name for workflow steps'
                defaultMessage='Workflow steps'
            />
        )
    },
    ACCOUNT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ACCOUNT.DEFAULT'
                description='Label for account'
                defaultMessage='Account'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.ACCOUNT.PLURAL'
                description='Plural name for accounts'
                defaultMessage='Accounts'
            />
        ),
        BRANDING: (
            <FormattedMessage
                id='Objects.ACCOUNT.BRANDING'
                description='Label for branding'
                defaultMessage='Branding'
            />
        )
    },
    COMMON_FIELDS: {
        NAME: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.NAME'
                description='Label for name'
                defaultMessage='Name'
            />
        ),
        DESCRIPTION: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.DESCRIPTION'
                description='Label for description'
                defaultMessage='Description'
            />
        ),
        TYPE: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.TYPE'
                description='Label for type'
                defaultMessage='Type'
            />
        ),
        STATUS: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.STATUS'
                description='Label for status'
                defaultMessage='Status'
            />
        ),
        KEY: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.KEY'
                description='Label for key'
                defaultMessage='Key'
            />
        ),
        INDEX: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.INDEX'
                description='Label for index'
                defaultMessage='Index'
            />
        ),
        BRAND: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.BRAND'
                description='Label for brand'
                defaultMessage='Brand'
            />
        ),
        PROTOCOL: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.PROTOCOL'
                description='Label for protocol'
                defaultMessage='Protocol'
            />
        ),
        STATE: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.STATE'
                description='Label for state'
                defaultMessage='State'
            />
        ),
        ITEM: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.ITEM'
                description='Label for item'
                defaultMessage='Item'
            />
        ),
        TARGET: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.TARGET'
                description='Label for target'
                defaultMessage='Target'
            />
        ),
        TARGET_TYPE: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.TARGET_TYPE'
                description='Label for target type'
                defaultMessage='Target type'
            />
        ),
        IDENTIFIER: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.IDENTIFIER'
                description='Label for identifier'
                defaultMessage='Identifier'
            />
        ),
        ORDER: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.ORDER'
                description='Label for order'
                defaultMessage='Order'
            />
        ),
        QUANTITY: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.QUANTITY'
                description='Label for quantity'
                defaultMessage='Quantity'
            />
        ),
        RIGHT: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.RIGHT'
                description='Label for right'
                defaultMessage='Right'
            />
        ),
        SETTINGS: (
            <FormattedMessage
                id='Objects.COMMON_FIELDS.SETTINGS'
                description='Label for settings'
                defaultMessage='Settings'
            />
        )
    },
    MOVEMENT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.MOVEMENT.DEFAULT'
                description='Label for movement'
                defaultMessage='Movement'
            />
        ),
        ORIGINAL_QUANTITY: (
            <FormattedMessage
                id='Objects.MOVEMENT.ORIGINAL_QUANTITY'
                description='Label for original quantity'
                defaultMessage='Original quantity'
            />
        ),
        NEW_QUANTITY: (
            <FormattedMessage
                id='Objects.MOVEMENT.NEW_QUANTITY'
                description='Label for new quantity'
                defaultMessage='New quantity'
            />
        ),
        DIFFERENCE: (
            <FormattedMessage
                id='Objects.MOVEMENT.DIFFERENCE'
                description='Label for difference'
                defaultMessage='Difference'
            />
        )
    },
    USER: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.USER.DEFAULT'
                description='Label for user'
                defaultMessage='User'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.USER.PLURAL'
                description='Plural name for users'
                defaultMessage='Users'
            />
        ),
        EMAIL: (
            <FormattedMessage
                id='Objects.USER.EMAIL'
                description='Label for email'
                defaultMessage='Email'
            />
        )
    },
    SPOT: {
        DISTRI_SPOT: (
            <FormattedMessage
                id='Objects.SPOT.DISTRI_SPOT'
                description='Label for distri spot'
                defaultMessage='distriSPOT'
            />
        ),
        DISTRI_SPOTS: (
            <FormattedMessage
                id='Objects.SPOT.DISTRI_SPOTS'
                description='Label for distri spots'
                defaultMessage='distriSPOTs'
            />
        ),
        DEFAULT: (
            <FormattedMessage
                id='Objects.SPOT.DEFAULT'
                description='Label for spot'
                defaultMessage='Spot'
            />
        )
    },
    MODULE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.MODULE.DEFAULT'
                description='Label for module'
                defaultMessage='Module'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.MODULE.PLURAL'
                description='Plural name for modules'
                defaultMessage='Modules'
            />
        ),
        TEMPLATE: (
            <FormattedMessage
                id='Objects.MODULE.TEMPLATE'
                description='Label for module template'
                defaultMessage='Module template'
            />
        ),
        TEMPLATE_PLURAL: (
            <FormattedMessage
                id='Objects.MODULE.TEMPLATE_PLURAL'
                description='Plural name for module templates'
                defaultMessage='Module templates'
            />
        ),
        GENERATOR: (
            <FormattedMessage
                id='Objects.MODULE.GENERATOR'
                description='Label for module generator'
                defaultMessage='Module generator'
            />
        ),
        LOCATION: (
            <FormattedMessage
                id='Objects.MODULE.LOCATION'
                description='Label for location'
                defaultMessage='Location'
            />
        )
    },
    CONSTRAINT: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.CONSTRAINT.DEFAULT'
                description='Label for constraint'
                defaultMessage='Constraint'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.CONSTRAINT.PLURAL'
                description='Plural name for constraints'
                defaultMessage='Constraints'
            />
        ),
        TYPE: (
            <FormattedMessage
                id='Objects.CONSTRAINT.TYPE'
                description='Label for constraint type'
                defaultMessage='Constraint type'
            />
        )
    },
    TRANSACTION: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.TRANSACTION.DEFAULT'
                description='Label for transaction'
                defaultMessage='Transaction'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.TRANSACTION.PLURAL'
                description='Plural name for transactions'
                defaultMessage='Transactions'
            />
        ),
        TRACKING_NUMBER: (
            <FormattedMessage
                id='Objects.TRANSACTION.TRACKING_NUMBER'
                description='Label for tracking number'
                defaultMessage='Tracking number'
            />
        ),
        SENDER: (
            <FormattedMessage
                id='Objects.TRANSACTION.SENDER'
                description='Label for sender'
                defaultMessage='Sender'
            />
        ),
        DROP_OFF_PACKAGE: (
            <FormattedMessage
                id='Objects.TRANSACTION.DROP_OFF_PACKAGE'
                description='Label for drop off package'
                defaultMessage='Drop off package'
            />
        ),
        DROP_OFF_STATE: (
            <FormattedMessage
                id='Objects.TRANSACTION.DROP_OFF_STATE'
                description='Label for drop off state'
                defaultMessage='Drop off state'
            />
        ),
        DROP_OFF_TIMESTAMP: (
            <FormattedMessage
                id='Objects.TRANSACTION.DROP_OFF_TIMESTAMP'
                description='Label for drop off at'
                defaultMessage='Drop off at'
            />
        ),
        RECEIVER: (
            <FormattedMessage
                id='Objects.TRANSACTION.RECEIVER'
                description='Label for receiver'
                defaultMessage='Receiver'
            />
        ),
        PICK_UP_PACKAGE: (
            <FormattedMessage
                id='Objects.TRANSACTION.PICK_UP_PACKAGE'
                description='Label for pick up package'
                defaultMessage='Pick up package'
            />
        ),
        PICK_UP_STATE: (
            <FormattedMessage
                id='Objects.TRANSACTION.PICK_UP_STATE'
                description='Label for pick up state'
                defaultMessage='Pick up state'
            />
        ),
        PICK_UP_TIMESTAMP: (
            <FormattedMessage
                id='Objects.TRANSACTION.PICK_UP_TIMESTAMP'
                description='Label for pick up at'
                defaultMessage='Pick up at'
            />
        ),
        NOTES: (
            <FormattedMessage
                id='Objects.TRANSACTION.NOTES'
                description='Label for notes'
                defaultMessage='Notes'
            />
        )
    },
    IMPORT_LOG: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.DEFAULT'
                description='Label for import log'
                defaultMessage='Import log'
            />
        ),
        FILE_NAME: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.FILE_NAME'
                description='Label for file name'
                defaultMessage='File name'
            />
        ),
        FILE_SIZE: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.FILE_SIZE'
                description='Label for file size'
                defaultMessage='File size'
            />
        ),
        IMPORT_TYPE: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.IMPORT_TYPE'
                description='Label for import type'
                defaultMessage='Import type'
            />
        ),
        IMPORT_ENTITY_TYPE: (
            <FormattedMessage
                id='Objects.IMPORT_LOG.IMPORT_ENTITY_TYPE'
                description='Label for import entity type'
                defaultMessage='Import entity type'
            />
        )
    },
    CERTIFICATE: {
        CODE: (
            <FormattedMessage
                id='Objects.CERTIFICATE.CODE'
                description='Label for certificate code'
                defaultMessage='Certificate code'
            />
        ),
        DEFAULT: (
            <FormattedMessage
                id='Objects.CERTIFICATE.DEFAULT'
                description='Label for certificate'
                defaultMessage='Certificate'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.CERTIFICATE.PLURAL'
                description='Plural name for certificates'
                defaultMessage='Certificates'
            />
        ),
        TYPED_CERTIFICATE: TypedCertificate
    },
    COST_CENTER: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.COST_CENTER.DEFAULT'
                description='Label for cost center'
                defaultMessage='Cost center'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.COST_CENTER.PLURAL'
                description='Plural name for cost centers'
                defaultMessage='Cost centers'
            />
        )
    },
    LICENSE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.LICENSE.DEFAULT'
                description='Label for license'
                defaultMessage='License'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.LICENSE.PLURAL'
                description='Plural name for licenses'
                defaultMessage='Licenses'
            />
        )
    },
    ADDRESS: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.ADDRESS.DEFAULT'
                description='Label for address'
                defaultMessage='Address'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.ADDRESS.PLURAL'
                description='Label for addresses'
                defaultMessage='Addresses'
            />
        ),
        STREET: (
            <FormattedMessage
                id='Objects.ADDRESS.STREET'
                description='Label for address street'
                defaultMessage='Address street'
            />
        ),
        NUMBER: (
            <FormattedMessage
                id='Objects.ADDRESS.NUMBER'
                description='Label for address number'
                defaultMessage='Address number'
            />
        ),
        APARTMENT: (
            <FormattedMessage
                id='Objects.ADDRESS.APARTMENT'
                description='Label for address apartment'
                defaultMessage='Address apartment'
            />
        ),
        CITY: (
            <FormattedMessage
                id='Objects.ADDRESS.CITY'
                description='Label for address city'
                defaultMessage='Address city'
            />
        ),
        ZIP_CODE: (
            <FormattedMessage
                id='Objects.ADDRESS.ZIP_CODE'
                description='Label for address zip code'
                defaultMessage='Address zip code'
            />
        ),
        PROVINCE: (
            <FormattedMessage
                id='Objects.ADDRESS.PROVINCE'
                description='Label for address province'
                defaultMessage='Address province'
            />
        ),
        REGION: (
            <FormattedMessage
                id='Objects.ADDRESS.REGION'
                description='Label for address region'
                defaultMessage='Address region'
            />
        ),
        COUNTY: (
            <FormattedMessage
                id='Objects.ADDRESS.COUNTY'
                description='Label for address county'
                defaultMessage='Address county'
            />
        ),
        NOTE: (
            <FormattedMessage
                id='Objects.ADDRESS.NOTE'
                description='Label for address note'
                defaultMessage='Address note'
            />
        ),
        TYPE: (
            <FormattedMessage
                id='Objects.ADDRESS.TYPE'
                description='Label for address type'
                defaultMessage='Address type'
            />
        )
    },
    PACKAGE: {
        DEFAULT: (
            <FormattedMessage
                id='Objects.PACKAGE.DEFAULT'
                description='Label for package'
                defaultMessage='Package'
            />
        ),
        PLURAL: (
            <FormattedMessage
                id='Objects.PACKAGE.PLURAL'
                description='Plural name for packages'
                defaultMessage='Packages'
            />
        )
    }
};

function PendingLinkMessage(type: string) {
    return (
        <FormattedMessage
            id='Objects.PENDING_LINK_MESSAGE'
            description='Pending link message'
            defaultMessage='Linking {type}s'
            values={{ type: type }}
        />
    );
}

function SuccessLinkMessage(type: string) {
    return (
        <FormattedMessage
            id='Objects.SUCCESS_LINK_MESSAGE'
            description='Success link message'
            defaultMessage='Successfully linked {type}s'
            values={{ type: type.replace(/_/g, ' ') }}
        />
    );
}

function ErrorLinkMessage(type: string) {
    return (
        <FormattedMessage
            id='Objects.ERROR_LINK_MESSAGE'
            description='Error link message'
            defaultMessage='Error while trying to link {type}s'
            values={{ type: type.replace(/_/g, ' ') }}
        />
    );
}

function PendingUnlinkMessage(type: string, count: number) {
    return (
        <FormattedMessage
            id='Objects.PENDING_UNLINK_MESSAGE'
            description='Pending unlink message'
            defaultMessage='Unlinking {count} {type}s'
            values={{ count: count, type: type.replace(/_/g, ' ') }}
        />
    );
}

function SuccessUnlinkMessage(type: string, count: number) {
    return (
        <FormattedMessage
            id='Objects.SUCCESS_UNLINK_MESSAGE'
            description='Success unlink message'
            defaultMessage='Successfully unlinked {count} {type}s'
            values={{ count: count, type: type.replace(/_/g, ' ') }}
        />
    );
}

function ErrorUnlinkMessage(type: string, count: number) {
    return (
        <FormattedMessage
            id='Objects.ERROR_UNLINK_MESSAGE'
            description='Error unlink message'
            defaultMessage='Error while trying to unlink {count} {type}s'
            values={{ count: count, type: type.replace(/_/g, ' ') }}
        />
    );
}

function TypedCertificate(type: EntityType) {
    return (
        <FormattedMessage
            id='Objects.CERTIFICATE.TYPED'
            description='Typed certificate'
            defaultMessage='{type} Certificate'
            values={{ type: type.charAt(0).toUpperCase() + type.slice(1) }}
        />
    );
}

function PendingOpeningMessage(type: EntityType, name: React.ReactNode) {
    return (
        <FormattedMessage
            id='Objects.SLOT.PENDING_OPENING_MESSAGE'
            description='Pending opening message'
            defaultMessage='Opening {type} {name}'
            values={{ type: type.replace(/_/g, ' '), name: name }}
        />
    );
}

function SuccessOpeningMessage(type: EntityType, name: React.ReactNode) {
    return (
        <FormattedMessage
            id='Objects.SLOT.SUCCESS_OPENING_MESSAGE'
            description='Success opening message'
            defaultMessage='Successfully opened {type} {name}'
            values={{ type: type.replace(/_/g, ' '), name: name }}
        />
    );
}

function ErrorOpeningMessage(type: EntityType, name: React.ReactNode) {
    return (
        <FormattedMessage
            id='Objects.SLOT.ERROR_OPENING_MESSAGE'
            description='Error opening message'
            defaultMessage='Error while trying to open {type} {name}'
            values={{ type: type.replace(/_/g, ' '), name: name }}
        />
    );
}

function typedVariable(type: EntityType) {
    return (
        <FormattedMessage
            id='Objects.TYPED_VARIABLE'
            description='Typed variable'
            defaultMessage='{type} Variable'
            values={{ type: type.charAt(0).toUpperCase() + type.slice(1) }}
        />
    );
}

function groupMemberAdd(count: number, singular: React.ReactNode, plural: React.ReactNode): React.ReactNode {
    return (
        <FormattedMessage
            id='Objects.GROUP_MEMBER.ADD'
            defaultMessage='Are you sure you want to add {count, plural, one {this {singular}} other {these {plural}}} to this group?'
            values={{ count: count, singular: singular, plural: plural }}
        />
    );
}
