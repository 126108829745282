import { EntityType } from "@frontend/common";
import { useDocumentRepository } from "@frontend/repository";
import React, { useEffect, useState } from "react";

interface Props<T> {
    object: T;
    objectKey: keyof T;
    entityType: EntityType;
}
export const AsyncDocumentCell = <T,>(props: Props<T>) => {
    const documentRepository = useDocumentRepository();
    const [url, changeUrl] = useState<string | null>(null);
    useEffect(() => {
        if (props.object[props.objectKey]) {
            documentRepository.resolveFile(props.entityType, props.object[props.objectKey] as string).then((service) => {
                if(service.getDocuments().length > 0) service.getUrl(service.getDocuments()[0].id).then(changeUrl);
            })
        }
    }, [])

    return (
        <td className='align-items-center'>
            {url ? (
                <img
                    src={url}
                    alt={url}
                    style={{ height: '45px' }}
                />
            ) : <span style={{ height: '45px', width: '45px' }} className="placeholder-glow"></span>}
        </td>
    );
}
