import { ImportClient } from '@frontend/import/api';
import { Job } from '@frontend/import/types';
import { useAccountRepository } from '@frontend/repository';
import { ToastUtil } from '@frontend/toast-utils';
import { Dispatch, SetStateAction, useState } from 'react';

interface ViewProps {
    file: File | undefined | null;
    changeFile: Dispatch<SetStateAction<File | undefined | null>>;
    onSubmit: () => void;
}
const useUserImport = (): ViewProps => {
    const {
        store: { currentAccount }
    } = useAccountRepository();
    const [file, changeFile] = useState<File | undefined | null>(undefined);

    const onSubmit = () => {
        if (!file || !currentAccount) return;
        ToastUtil.promise<Job>(
            () => ImportClient.importUsers(currentAccount, file),
            { title: 'Pending', message: `Uploading ${file.name}` },
            { title: 'Success', message: `Uploaded ${file.name}`, onSuccess: () => changeFile(null) },
            { title: 'Error', message: `Something went wrong while uploading ${file.name}`, onError: () => changeFile(null) }
        );
    };

    return {
        file,
        changeFile,
        onSubmit
    };
};

export default useUserImport;
