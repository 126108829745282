export enum EntityType {
    ACCESS_LOG = 'access_log',
    ACCOUNT = 'account',
    ACTUATOR = 'actuator',
    ADDRESS = 'address',
    ADDRESS_ENTITY = 'address_entity',
    ALL = '*',
    ANY = 'any',
    ANSWER = 'answer',
    ATTRIBUTE = 'attribute',
    ATTRIBUTE_LINKING = 'attribute_linking',
    BADGE = 'badge',
    BASIC_AUTH = 'basic_auth',
    AUTHORIZATION = 'authorization',
    BUDGET = 'budget',
    BUDGET_ENTITY = 'budget_entity',
    BUDGET_USE = 'budget_use',
    CATEGORY = 'category',
    CERTIFICATE = 'certificate',
    USER_CERTIFICATE = 'user_certificate',
    EQUIPMENT_CERTIFICATE = 'equipment_certificate',
    CERTIFICATE_REQUIREMENT = 'certificate_requirement',
    CHANGE = 'change',
    CONTACT = 'contact',
    CONTACT_GROUP = 'contact_group',
    CONSTRAINT = 'constraint',
    COST_CENTER = 'cost_center',
    DEVICE = 'device',
    DOCUMENT = 'document',
    DOCUMENT_ENTITY = 'document_entity',
    EQUIPMENT = 'equipment',
    GROUP = 'group',
    EQUIPMENT_GROUP = 'equipment_group',
    EQUIPMENT_VARIABLE = 'equipment_variable',
    EVENT = 'event',
    GROUPED_STOCK_AGGREGATION = 'grouped_stock_aggregation',
    IDENTIFICATION = 'identification',
    IMPEX = 'impex',
    IMPORT = 'import',
    INTEGRATION_TEST = 'integration_test',
    IOT = 'iot',
    JOB = 'job',
    LICENSE = 'license',
    LOCKER = 'locker',
    MEDIA_FILE = 'media_file',
    MEMBER = 'member',
    MODULE = 'module',
    MOVEMENT = 'movement',
    NOTIFICATION = 'notification',
    NOTIFICATION_TEMPLATE = 'notification_template',
    NOTIFICATION_CONTENT = 'notification_content',
    OCCUPANT = 'occupant',
    PACKAGE = 'package',
    PERMISSION = 'permission',
    PLAYLIST = 'playlist',
    PLAYLIST_ITEM = 'playlist_item',
    PROBLEM = 'problem',
    PROBLEM_ENTITY = 'problem_entity',
    PRODUCT = 'product',
    PRODUCT_ATTRIBUTE = 'product_attribute',
    PRODUCT_CATEGORY_ATTRIBUTE = 'product_category_attribute',
    PRODUCT_GROUP = 'product_group',
    QUESTION = 'question',
    QUESTIONNAIRE = 'questionnaire',
    RAKINDA = 'rakinda',
    ROLE = 'role',
    RULE = 'rule',
    SYNC = 'sync',
    SEED = 'seed',
    SERVER = 'server',
    SENSOR = 'sensor',
    SETTING = 'setting',
    SETTINGS = 'settings',
    SLOT = 'slot',
    TEMPLATE = 'template',
    SLOT_GROUP = 'slot_group',
    SLOT_STOCK_AGGREGATION = 'slot_stock_aggregation',
    SPOT = 'spot',
    STOCK = 'stock',
    STOCK_REQUIREMENT = 'stock_requirement',
    TAG = 'tag',
    TEST = 'test',
    TRANSACTION = 'transaction',
    TRANSLATION = 'translation',
    UNDEFINED = '',
    UNKNOWN = 'unknown',
    USER = 'user',
    USER_GROUP = 'user_group',
    USER_INTERFACE = 'user_interface',
    USER_INTERFACE_BUTTON = 'user_interface_button',
    VARIANT = 'variant',
    WORKFLOW = 'workflow',
    WORKFLOW_STEP = 'workflow_step',
    PRODUCT_CATEGORY = 'product_category',
    WORKFLOW_FIELD = 'workflow_field',
    VARIANT_LINKING = 'variant_linking',
    DEFECT_LOG = 'defect_log'
}
