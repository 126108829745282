import { Logger } from '@frontend/Logger';
import { ApiError } from '@frontend/api-utils';
import { ToastUtil } from '@frontend/toast-utils';

interface ErrorResponse {
    detail:
        | {
              loc: string[];
              msg: string;
              type: string;
          }[]
        | string;
}

export class ErrorHandler {
    private static statusMessages: { [key: number]: string } = {
        400: 'Bad request. Please check your input',
        401: 'Unauthorized. Please check your credentials',
        403: 'Forbidden. You do not have the necessary permissions',
        404: 'Not found. The requested resource could not be found',
        409: 'Conflict. Please try again',
        422: 'Unprocessable value. Please check your input',
        500: 'Internal server error. Please try again later'
    };

    public static handleError(error: Error | ApiError) {
        Logger.error(error);
        if (!error) return ToastUtil.error('Error, try again later');
        if (Object.keys(error).length === 0) return ToastUtil.error('Error, try again later');
        if (((error as ApiError).json as ErrorResponse).detail) {
            const details = ((error as ApiError).json as ErrorResponse).detail;
            if (Array.isArray(details)) {
                details.forEach((err: { loc: string[]; msg: string; type: string }) => {
                    return ToastUtil.error('Error', `${err.loc[1]}: ${err.msg}`);
                });
                return;
            } else {
                return ToastUtil.error('Error', details);
            }
        } else if ((error as ApiError).code != undefined && ErrorHandler.statusMessages[(error as ApiError).code!]) {
            return ToastUtil.error('Error', ErrorHandler.statusMessages[(error as ApiError).code!]);
        } else {
            return ToastUtil.error('Error');
        }
    }
}
