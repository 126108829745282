import { APIClient, ApiQueryParams, buildApiEndpoint } from '@frontend/api-utils';
import { EquipmentChartData, EquipmentChartQueryParams } from '@frontend/equipment/types';

const endpoint = '/equipment-api/v1';

export class EquipmentChartClient extends APIClient {
    public static async fetchAllEquipmentState(accountId: string, queryParams?: ApiQueryParams<EquipmentChartQueryParams>): Promise<EquipmentChartData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/equipment-state-all-chart`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<EquipmentChartData>(response);
    }

    public static async fetchAvailableEquipmentState(accountId: string, queryParams?: ApiQueryParams<EquipmentChartQueryParams>): Promise<EquipmentChartData> {
        const url = buildApiEndpoint(`${endpoint}/accounts/${accountId}/equipment-state-available-chart`, queryParams);
        const response = await this.fetch(url);
        return await this.handleResponse<EquipmentChartData>(response);
    }
}
