import { EntityType } from '@frontend/common';
import { getCorrectTranslation, useLocale } from '@frontend/lang';
import { productRequest } from '@frontend/product/events';
import { Product } from '@frontend/product/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const ProductBadge = (props: ObjectBadgeProps<Product>) => {
    const { locale } = useLocale();
    return (
        <ObjectBadge<Product>
            id={props.id}
            type={EntityType.PRODUCT}
            mapDisplayName={(prod: Product) => getCorrectTranslation(locale, prod, 'name') || prod.ean || prod.sku}
            resolve={productRequest}
            key={props.id}
            navigateString={(object: Product) => `/accounts/${object.account_id}/products/${object.id}`}
            {...props}
        />
    );
};
