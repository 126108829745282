import React from 'react';
import { Row } from 'react-table';

import { RowProps } from '../rows/TRow';

export interface RowElementProps<T extends object> extends RowProps {
    className?: string;
    row: Row<T>;
    onClick?: (element: T) => void;
    id: string;
}

export const RowElement = <T extends object>(props: RowElementProps<T>) => {
    return (
        <tr
            id={`${props.id}-Row`}
            onClick={() => props.onClick && props.onClick(props.row.original)}
            className={props.className || (props.onClick && 'hover cursor-pointer')}>
            {props.row.cells.map((cell) => {
                return cell.render('Cell');
            })}
        </tr>
    );
};
