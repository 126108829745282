import { FormatType, FormatTypeName } from '@frontend/impex/types';
import { useMemo } from 'react';

import { FormatSelectProps } from './format-select.component';

interface ViewProps {
    options: { value: string; label: React.ReactNode }[];
    value:
        | {
              value: FormatTypeName;
              label: React.ReactNode;
          }
        | {
              value: string;
              label: string;
          }
        | undefined;
}

const useFormatSelect = (props: FormatSelectProps): ViewProps => {
    const options = FormatType.ALL.map((format) => ({ value: format.value, label: format.displayName }));

    const value = useMemo(() => {
        if (!options || !props.value) return undefined;
        else return options.find((op) => op.value == props.value);
    }, [props.value, options]);

    return {
        options,
        value
    };
};

export default useFormatSelect;
