import { APIClient, ApiQueryParams } from '@frontend/api-utils';
import { CreateSlot, Slot, SlotListResponse, SlotQueryParams, UpdateSlot } from '@frontend/slot/types';

const endpoint = '/slot-api/v1';

export class SlotClient extends APIClient {
    public static async fetchSlots(queryParams?: ApiQueryParams<SlotQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, SlotQueryParams>(`${endpoint}/slots`, queryParams);
    }
    public static async resolveSlots(ids: string[], field: keyof Slot = 'id'): Promise<SlotListResponse> {
        const response = await this.post(`${endpoint}/slots-resolve`, { field, ids: ids });
        return await this.handleResponse<SlotListResponse>(response);
    }

    public static async deleteSlots(slotIds: string[]): Promise<void> {
        const response = await this.post(`${endpoint}/slots-delete`, { ids: slotIds });
        return await this.handleVoidResponse(response);
    }

    public static async fetchAccountSpotSlots(accountId: string, spotId: string, queryParams?: ApiQueryParams<SlotQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, SlotQueryParams>(`${endpoint}/accounts/${accountId}/spots/${spotId}/slots`, queryParams);
    }

    /**
     * @deprecated
     * @param accountId 
     * @param spotId 
     * @param moduleId 
     * @param queryParams 
     * @returns 
     */
    public static async fetchSpotModuleSlots(accountId: string, spotId: string, moduleId: string, queryParams?: ApiQueryParams<SlotQueryParams>): Promise<SlotListResponse> {
        return await this.apiPaginated<SlotListResponse, SlotQueryParams>(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots`, queryParams);
    }

    public static async postSlot(accountId: string, spotId: string, moduleId: string, slot: CreateSlot): Promise<Slot> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots`, slot);
        return await this.handleResponse<Slot>(response);
    }

    public static async fetchSlot(accountId: string, spotId: string, moduleId: string, slotId: string): Promise<Slot> {
        const response = await this.fetch(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}`);
        return await this.handleResponse<Slot>(response);
    }

    public static async patchSlot(accountId: string, spotId: string, moduleId: string, slotId: string, body: UpdateSlot): Promise<Slot> {
        const response = await this.patch(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}`, body);
        return await this.handleResponse<Slot>(response);
    }

    public static async deleteSlot(accountId: string, spotId: string, moduleId: string, slotId: string): Promise<void> {
        const response = await this.delete(`${endpoint}/accounts/${accountId}/spots/${spotId}/modules/${moduleId}/slots/${slotId}`);
        return await this.handleVoidResponse(response);
    }

    public static async postIoTSeed(accountId: string, iotId: string, body: { account_id: string }): Promise<void> {
        const response = await this.post(`${endpoint}/accounts/${accountId}/iots/${iotId}/seed`, body);
        return this.handleResponse(response);
    }
}
