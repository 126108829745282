import { IconType } from 'react-icons';
import { BsFiletypeCsv, BsFiletypeJson, BsFiletypePdf, BsFiletypeXlsx } from 'react-icons/bs';

export enum FormatTypeName {
    XLSX = 'xlsx',
    CSV = 'csv',
    JSON = 'json',
    PDF = 'pdf'
}

export class FormatType {
    static readonly XLSX = new FormatType(FormatTypeName.XLSX, 'XLSX', BsFiletypeXlsx);
    static readonly CSV = new FormatType(FormatTypeName.CSV, 'CSV', BsFiletypeCsv);
    static readonly JSON = new FormatType(FormatTypeName.JSON, 'JSON', BsFiletypeJson);
    static readonly PDF = new FormatType(FormatTypeName.PDF, 'PDF', BsFiletypePdf);

    static readonly ALL = [FormatType.XLSX, FormatType.CSV, FormatType.JSON, FormatType.PDF];

    private constructor(public readonly value: FormatTypeName, public readonly displayName: React.ReactNode, public readonly icon: IconType) {}

    static getByValue(value: FormatTypeName): FormatType | undefined {
        return this.ALL.find((a) => a.value == value);
    }
}
