import { EntityType } from '@frontend/common';
import { DeviceBadge } from '@frontend/device/badge';
import { AsyncComponent } from '@frontend/elements';
import { JobBadge } from '@frontend/import/badge';
import { IoTBadge } from '@frontend/iot/badge';
import { ProblemBadge } from '@frontend/problem/badge';
import { TransactionBadge } from '@frontend/transaction/badge';
import { WorkflowBadge } from '@frontend/workflow/badge';
import React from 'react';

const AccountBadge = React.lazy(() => import('@frontend/account/badge').then((module) => ({ default: module.AccountBadge })));
const ContactBadge = React.lazy(() => import('@frontend/contact/badge').then((module) => ({ default: module.ContactBadge })));
const SpotBadge = React.lazy(() => import('@frontend/spot/badge').then((module) => ({ default: module.SpotBadge })));
const UserBadge = React.lazy(() => import('@frontend/user/badge').then((module) => ({ default: module.UserBadge })));
const BudgetBadge = React.lazy(() => import('@frontend/budget/badge').then((module) => ({ default: module.BudgetBadge })));
const CostCenterBadge = React.lazy(() => import('@frontend/cost-center/badge').then((module) => ({ default: module.CostCenterBadge })));
const CertificateBadge = React.lazy(() => import('@frontend/certificate/badge').then((module) => ({ default: module.CertificateBadge })));
const ProductBadge = React.lazy(() => import('@frontend/product/badge').then((module) => ({ default: module.ProductBadge })));
const EquipmentBadge = React.lazy(() => import('@frontend/equipment/badge').then((module) => ({ default: module.EquipmentBadge })));
const PlaylistBadge = React.lazy(() => import('@frontend/playlist/badge').then((module) => ({ default: module.PlaylistBadge })));
const ModuleBadge = React.lazy(() => import('@frontend/module/badge').then((module) => ({ default: module.ModuleBadge })));
const ModuleTemplateBadge = React.lazy(() => import('@frontend/module/badge').then((module) => ({ default: module.ModuleTemplateBadge })));
const SlotBadge = React.lazy(() => import('@frontend/slot/badge').then((module) => ({ default: module.SlotBadge })));
const SlotTemplateBadge = React.lazy(() => import('@frontend/slot/badge').then((module) => ({ default: module.SlotTemplateBadge })));
const AttributeBadge = React.lazy(() => import('@frontend/attribute/badge').then((module) => ({ default: module.AttributeBadge })));
const GroupBadge = React.lazy(() => import('@frontend/group/badge').then((module) => ({ default: module.GroupBadge })));
const QuestionBadge = React.lazy(() => import('@frontend/question/badge').then((module) => ({ default: module.QuestionBadge })));
const AddressBadge = React.lazy(() => import('@frontend/address/badge').then((module) => ({ default: module.AddressBadge })));
const DocumentBadge = React.lazy(() => import('@frontend/document/badge').then((module) => ({ default: module.DocumentBadge })));
const PackageBadge = React.lazy(() => import('@frontend/package/common').then((module) => ({ default: module.PackageBadge })));
const VariantBadge = React.lazy(() => import('@frontend/variant/badge').then((module) => ({ default: module.VariantBadge })));
const UserInterfaceBadge = React.lazy(() => import('@frontend/user-interface/badge').then((module) => ({ default: module.UserInterfaceBadge })));

interface Props {
    id: string;
    type: string;
}

const fallback = (
    <span className='placeholder-glow'>
        <span className='placeholder'>loading</span>
    </span>
);
export const ObjectBadgeMapper = (props: Props) => {
    switch (props.type) {
        case 'account_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <AccountBadge id={props.id} />
                </AsyncComponent>
            );
        case 'contact_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ContactBadge id={props.id} />
                </AsyncComponent>
            );
        case 'spot_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <SpotBadge id={props.id} />
                </AsyncComponent>
            );
        case 'product_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ProductBadge id={props.id} />
                </AsyncComponent>
            );
        case 'user_id':
        case 'drop_off_user_id':
        case 'pick_up_user_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <UserBadge id={props.id} />
                </AsyncComponent>
            );
        case 'cost_center_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <CostCenterBadge id={props.id} />
                </AsyncComponent>
            );
        case 'budget_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <BudgetBadge id={props.id} />
                </AsyncComponent>
            );
        case 'certificate_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <CertificateBadge id={props.id} />
                </AsyncComponent>
            );
        case 'equipment_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <EquipmentBadge id={props.id} />
                </AsyncComponent>
            );
        case 'default_playlist_id':
        case 'playlist_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <PlaylistBadge id={props.id} />
                </AsyncComponent>
            );
        case 'module_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ModuleBadge id={props.id} />
                </AsyncComponent>
            );
        case 'module_template_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ModuleTemplateBadge id={props.id} />
                </AsyncComponent>
            );
        case 'slot_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <SlotBadge id={props.id} />
                </AsyncComponent>
            );
        case 'slot_template_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <SlotTemplateBadge id={props.id} />
                </AsyncComponent>
            );
        case 'attribute_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <AttributeBadge id={props.id} />
                </AsyncComponent>
            );
        case 'user_group_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupBadge id={props.id} type={EntityType.USER_GROUP} />
                </AsyncComponent>
            );
        case 'product_group_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupBadge id={props.id} type={EntityType.PRODUCT_GROUP} />
                </AsyncComponent>
            );
        case 'category_id':
        case 'default_product_category_id':
        case 'product_category_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupBadge id={props.id} type={EntityType.PRODUCT_CATEGORY} />
                </AsyncComponent>
            );
        case 'contact_group_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupBadge id={props.id} type={EntityType.CONTACT_GROUP} />
                </AsyncComponent>
            );
        case 'questionnaire_id':
        case 'consume_questionnaire_id':
        case 'return_questionnaire_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupBadge id={props.id} type={EntityType.QUESTIONNAIRE} />
                </AsyncComponent>
            );
        case 'equipment_group_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <GroupBadge id={props.id} type={EntityType.EQUIPMENT_GROUP} />
                </AsyncComponent>
            );
        case 'question_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <QuestionBadge id={props.id} />
                </AsyncComponent>
            );
        case 'document_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <DocumentBadge id={props.id} />
                </AsyncComponent>
            );
        case 'address_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <AddressBadge id={props.id} />
                </AsyncComponent>
            );
        case 'drop_off_package_id':
        case 'pick_up_package_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <PackageBadge id={props.id} />
                </AsyncComponent>
            );
        case 'variant_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <VariantBadge id={props.id} />
                </AsyncComponent>
            );
        case 'transaction_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <TransactionBadge id={props.id} />
                </AsyncComponent>
            );
        case 'iot_id':
        case 'twin_iot_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <IoTBadge id={props.id} />
                </AsyncComponent>
            );
        case 'workflow_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <WorkflowBadge id={props.id} />
                </AsyncComponent>
            );
        case 'device_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <DeviceBadge id={props.id} />
                </AsyncComponent>
            );
        case 'problem_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <ProblemBadge id={props.id} />
                </AsyncComponent>
            );
        case 'user_interface_id':
        case 'default_user_interface_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <UserInterfaceBadge id={props.id} />
                </AsyncComponent>
            );
        case 'job_id':
            return (
                <AsyncComponent fallback={fallback}>
                    <JobBadge id={props.id} />
                </AsyncComponent>
            );
    }
    return <></>;
};
