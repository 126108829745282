import { Account } from '@frontend/account/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class AccountEventListener extends EventListener<Account> {
    
    private static instance: AccountEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('account', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): AccountEventListener {
        if (this.instance == null) {
            this.instance = new AccountEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<Account> {
        return AccountEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}