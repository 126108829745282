import { EntityType } from '@frontend/common';
import { contactRequest } from '@frontend/contact/events';
import { Contact } from '@frontend/contact/types';
import { ObjectBadge, ObjectBadgeProps } from '@frontend/rendering/badge';
import React from 'react';

export const ContactBadge = (props: ObjectBadgeProps<Contact>) => {
    return (
        <ObjectBadge<Contact>
            id={props.id}
            type={EntityType.CONTACT}
            mapDisplayName={(co: Contact) => (co.alias ? co.alias : co.first_name + ' ' + (co.last_name || ''))}
            resolve={contactRequest}
            key={props.id}
            navigateString={(object: Contact) => `/accounts/${object.account_id}/contacts/${object.id}`}
            {...props}
        />
    );
};
