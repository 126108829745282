import React, { useMemo } from 'react';
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight, HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi';

import { PaginationNavigationButton } from './pagination-navigation-button';
import { PaginationNumber } from './pagination-number';

interface PaginationButtonNavigationProps {
    id?: string;

    className?: string;
    gotoPage?: (updater: ((pageIndex: number) => number) | number) => void;
    previousPage?: () => void;
    nextPage?: () => void;
    allowBack?: boolean;
    backDisabled?: boolean;
    allowAllBack?: boolean;
    allBackDisabled?: boolean;
    allowForward?: boolean;
    forwardDisabled?: boolean;
    allowAllForward?: boolean;
    allForwardDisabled?: boolean;
    paginationButtonProps?: React.ComponentProps<typeof PaginationNavigationButton>;

    pageCount: number;
    pageIndex: number;
    /**
     * @description The amount of pagination numbers to display
     * Will always be odd (if an even number is given, it will be increased by 1)
     * @default 5
     */
    paginationNumberButtonAmount?: number;
    paginationNumberProps?: React.ComponentProps<typeof PaginationNumber>;
}
export const PaginationButtonNavigationClass = 'pagination pagination-primary m-0';
export const PaginationButtonNavigation = (props: PaginationButtonNavigationProps) => {
    const pagesToDisplay = useMemo(
        () => getPagesToDisplay(props.pageCount, props.pageIndex + 1, props.paginationNumberButtonAmount),
        [props.pageCount, props.pageIndex, props.paginationNumberButtonAmount]
    );
    const bwDisabled = props.pageIndex <= 0;
    const fwDisabled = props.pageIndex + 1 >= props.pageCount;
    return (
        <ul
            id={props.id}
            className={props.className ?? PaginationButtonNavigationClass}>
            {props.allowAllBack !== false && props.gotoPage && (
                <PaginationNavigationButton
                    id={props.id}
                    icon={HiOutlineChevronDoubleLeft}
                    disabled={props.allBackDisabled ?? bwDisabled}
                    onClick={() => props.gotoPage!(0)}
                    {...props.paginationButtonProps}
                />
            )}

            {props.allowBack !== false && props.previousPage && (
                <PaginationNavigationButton
                    id={props.id}
                    icon={HiOutlineChevronLeft}
                    disabled={props.backDisabled ?? bwDisabled}
                    onClick={props.previousPage}
                    {...props.paginationButtonProps}
                />
            )}

            {props.gotoPage &&
                pagesToDisplay.map((n) => (
                    <PaginationNumber
                        id={props.id}
                        key={n}
                        index={n}
                        highlighted={props.pageIndex + 1 === n}
                        onClick={() => props.gotoPage!(n - 1)}
                        {...props.paginationNumberProps}
                    />
                ))}

            {props.allowForward !== false && props.nextPage && (
                <PaginationNavigationButton
                    id={props.id}
                    icon={HiOutlineChevronRight}
                    disabled={props.forwardDisabled ?? fwDisabled}
                    onClick={props.nextPage}
                    {...props.paginationButtonProps}
                />
            )}

            {props.allowAllForward !== false && props.gotoPage && (
                <PaginationNavigationButton
                    id={props.id}
                    icon={HiOutlineChevronDoubleRight}
                    disabled={props.allForwardDisabled ?? fwDisabled}
                    onClick={() => props.gotoPage!(props.pageCount - 1)}
                    {...props.paginationButtonProps}
                />
            )}
        </ul>
    );
};
export default PaginationButtonNavigation;

function getPagesToDisplay(pageCount: number, currentPage: number, pagesToDisplay = 5): number[] {
    const pagesOnEverySide = (pagesToDisplay - (pagesToDisplay % 2)) / 2;
    let pages: number[] = [];

    for (let i = currentPage - pagesOnEverySide; i <= currentPage + pagesOnEverySide; i++) {
        if (i < 1) continue;
        if (i > pageCount) break;
        pages.push(i);
    }

    while (pages.length < pagesToDisplay) {
        if (pages[0] > 1) {
            pages = [pages[0] - 1, ...pages];
        } else if (pages.indexOf(currentPage) !== pagesOnEverySide + 1 && pages[pages.length - 1] < pageCount) {
            pages = [...pages, pages[pages.length - 1] + 1];
        } else break;
    }
    return pages;
}
