import { BUTTON_ICONS } from './button-icons';
import { DETAIL_ICONS } from './detail-icons';
import { ENTITY_ICONS } from './entity-icons';
import { NAVIGATION_ICONS } from './navigation-icons';
import { PUDO_ICONS } from './pudo-icons';

export const ICONS = {
    ORGANIZATION: ENTITY_ICONS,
    ENTITY: ENTITY_ICONS,
    BUTTON: BUTTON_ICONS,
    NAVIGATION: NAVIGATION_ICONS,
    PUDO: PUDO_ICONS,
    DETAIL: DETAIL_ICONS
};
