import { EntityType } from '@frontend/common';
import { EquipmentClient } from '@frontend/equipment/api';
import { useResolver } from '@frontend/repository';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useEquipment:request': CustomEvent<string>;
    }
}

const REQUEST_EQUIPMENT_EVENT = 'useEquipment:request';
const STORAGE_KEY = 'EQPMNT_REQ';

export function equipmentRequest(equipmentId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_EQUIPMENT_EVENT, { detail: equipmentId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function EquipmentResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_EQUIPMENT_EVENT, EntityType.EQUIPMENT, (ids) => EquipmentClient.resolveEquipments(ids), props.dispatch);
    return <></>;
}
