import React, { useMemo } from 'react';

import { CheckboxInput, NumberInput, TextInput } from '@frontend/basic-forms';
import { ObjectSelectMapper } from '../object-select-mapper';
import { DataTypeInputProps } from './data-type-input.component';

/**
 * @param props 
 * @returns 
 */
const useDataTypeInput = (props: DataTypeInputProps) => {
    const input = useMemo(() => {
        if (props.type.endsWith('_id')) {
            return (
                <ObjectSelectMapper<any>
                    id={props.type}
                    objectKey={props.type}
                    {...props}
                    value={props.value as string | null | undefined}
                />
            );
        } else switch (props.type) {
            case 'string':
            case 'str':
            case 'color': // TODO: add color picker
                return (
                    <TextInput
                        {...props}
                        value={props.value as string | undefined}
                    />
                );
            case 'number':
            case 'int':
                return (
                    <NumberInput
                        {...props}
                        value={props.value as number | undefined}
                    />
                );
            case 'bool':
            case 'boolean':
                return (
                    <CheckboxInput
                        {...props}
                        value={props.value as boolean | undefined}
                    />
                );
            default:
                return <></>;
        }
    }, [props]);

    return { input };
};

export default useDataTypeInput;
