import { useAppSelector, useLocalStorageState } from '@frontend/common';
import { fetchProfile, userStore } from '@frontend/user/redux';
import { useCacheBusting } from '@frontend/version/hooks';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavigateFunction, useNavigate } from 'react-router-dom';

import { Props } from './header-component';

interface ViewProps {
    navbarPinned: boolean;
    username?: string;
    changeNavbarPinned: (value: boolean) => void;
    navigate: NavigateFunction;
    udpateAvailable: boolean;
    updateApplication: () => void;
}

const useHeader = (props: Props): ViewProps => {
    const { udpateAvailable, update } = useCacheBusting({ currentVersion: props.version, repository: 'platform' });
    const [username, changeUsername] = useState<string | undefined>(undefined);
    const [navbarPinned, changeNavbarPinned] = useLocalStorageState<boolean>('navbar-pinned', true);
    const navigate = useNavigate();
    const userState = useAppSelector(useSelector, userStore);

    useEffect(() => {
        props.dispatch(fetchProfile());
    }, []);

    useEffect(() => {
        if (userState.current) changeUsername(`${userState.current.first_name} ${userState.current.last_name}`);
    }, [userState.current]);

    return {
        navbarPinned,
        username,
        changeNavbarPinned,
        navigate,
        udpateAvailable,
        updateApplication: update
    };
};

export default useHeader;
