import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { EntityType } from '@frontend/common';
import { ProductAttributeClient } from '@frontend/product/api';
import { useResolver } from '@frontend/repository';
import React from 'react';

declare global {
    interface WindowEventMap {
        'useProductAttribute:request': CustomEvent<string>;
    }
}

const REQUEST_PRODUCT_ATTRIBUTE_EVENT = 'useProductAttribute:request';
const STORAGE_KEY = 'PRD_ATTR_REQ';

export function productAttributeRequest(productAttributeId: string): string {
    window.dispatchEvent(new CustomEvent(REQUEST_PRODUCT_ATTRIBUTE_EVENT, { detail: productAttributeId }));
    return STORAGE_KEY + '_loading';
}

interface Props {
    dispatch: ThunkDispatch<any, any, Action>;
}

export function ProductAttributeResolver(props: Props) {
    useResolver(STORAGE_KEY, REQUEST_PRODUCT_ATTRIBUTE_EVENT, EntityType.PRODUCT_ATTRIBUTE, (ids) => ProductAttributeClient.resolveProductAttributes(ids), props.dispatch);
    return <></>;
}
