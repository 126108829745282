import React from 'react';
import Select, { Props } from 'react-select';

import { getClassName, getInputClassMultiSelect } from '../input-class';
import useSelectInput from './select-input.controller';

const ID = 'select-input';
export interface SelectInputProps extends Props {
    label?: React.ReactNode;
    helpMessage?: React.ReactNode;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onFocusChange?: () => void;
    submitted: boolean;
    value?: any[] | any;
    useConditionedStyling?: boolean;
    errorMessage?: React.ReactNode;
    resetValue?: boolean;

    className?: string;
}

export const SelectInput = (props: SelectInputProps) => {
    const viewProps = useSelectInput(props);

    return (
        <div
            id={ID}
            className={'form-group ' + props.className}>
            <label className='text-primary'>
                {props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>} {props.label}
                {props.helpMessage && (
                    <>
                        <br />
                        <small className='form-text text-muted'>{props.helpMessage}</small>
                    </>
                )}
            </label>
            <Select
                {...props}
                options={props.options}
                value={viewProps.value}
                styles={{
                    control: (baseStyles) => ({
                        ...baseStyles,
                        boxShadow: 'none',
                        minWidth: '190px',
                        padding: '1.204px 0px 1.204px 10px',
                        borderRadius: '0.5rem'
                    }),
                    menu: (baseStyle) => ({
                        ...baseStyle,
                        zIndex: 9999
                    }),
                    valueContainer: (baseStyle) => ({
                        ...baseStyle,
                        padding: '0px'
                    })
                }}
                id={props.id}
                classNames={{
                    control: () =>
                        props.useConditionedStyling === false
                            ? 'form-control'
                            : props.isMulti
                              ? getInputClassMultiSelect(viewProps.touched, viewProps.valid, props.value)
                              : getClassName(
                                    viewProps.touched,
                                    viewProps.valid,
                                    props.value == null || props.value == undefined ? undefined : JSON.stringify(props.value)
                                )
                }}
                onChange={(newValue: any, actionMeta) => {
                    props.onChange && props.onChange(props.isMulti ? newValue.map((s: any) => s.value) : newValue, actionMeta);
                    viewProps.changeValue(newValue);
                    viewProps.isValid();
                }}
                placeholder={props.placeholder}
                isMulti={props.isMulti}
                onFocus={() => {
                    viewProps.changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                isClearable={props.isClearable}
                isDisabled={props.isDisabled}
                defaultValue={props.defaultValue}
            />
            {props.useConditionedStyling === false ? (
                <></>
            ) : viewProps.valid === false && viewProps.touched === true ? (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ? props.errorMessage : 'Please select a valid option.'}</small>
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};
