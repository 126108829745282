import { useAccountRepository } from '@frontend/repository';
import { useEffect, useMemo, useRef, useState } from 'react';
import { SetURLSearchParams, useParams, useSearchParams } from 'react-router-dom';

import { ObjectSelectInputProps } from './object-select-input.component';

interface ViewProps<T extends { id: string }> {
    options: { value: string; label: string }[] | { label: string; options: { value: string; label: string }[] }[];
    value?: T | null;
    onScrollToBottom: () => void;
    search: (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => void;
    onChange: (value: any) => void;
    overwriteDisabled?: boolean;
    hide: boolean;
}

const pageSize = '100';

const useObjectSelectInput = <T extends { id: string }, D extends string | number>(props: ObjectSelectInputProps<T, D>): ViewProps<T> => {
    const params = useParams();
    const { store } = useAccountRepository();
    const [urlOverwrite, changeUrlOverwrite] = useState<T | null>(null);
    const [cache, changeCache] = useState<T[]>([]);
    const timer = useRef<NodeJS.Timeout | null>(null);
    const [count, changeCount] = useState<number>(0);
    const [index, changeIndex] = useState<number>(0);
    const [options, changeOptions] = useState<{ value: string; label: string }[]>([]);
    const [mappedOptions, changeMappedOptions] = useState<{ label: string; options: { value: string; label: string }[] }[]>([]);
    const [searchParams, setSearchParams] = useSearchParams();

    const value: T | null = useMemo(() => {
        if (props.isClearable && props.value === null) return null;
        if (options.length === 1 && props.required) {
            const found = cache.find((o) => o.id === options[0].value);
            if (!found) return null;
            if (props.onChange && props.value?.id !== found.id) props.onChange(found);
        }
        return props.value ? (props.value.id === props.idValue ? props.value : (cache.find((o) => o.id === props.idValue) ?? null)) : null;
    }, [props.value, props.idValue, options]);

    useEffect(() => {
        const param = searchParams.get(props.useQueryParam?.param ?? '');
        if (!urlOverwrite) {
            if (!param && !props.value) return;
            onQueryParamChange(value?.id ?? null, props.useQueryParam?.param ?? '', setSearchParams);
        }
    }, [value]);

    useEffect(() => {
        if (!props.idValue) return;
        if (props.singleFetch && options && options.length > 0 && !options.find((o) => o.value == props.idValue)) {
            props.singleFetch(props.idValue).then((res) => {
                changeOptions((prevOptions) => {
                    const found = prevOptions.find((o) => o.value === res.id);
                    if (found) return prevOptions;
                    else return [...prevOptions, { value: res.id, label: props.itemLabel(res) }];
                });
                changeCache((prevCache) => [...prevCache, res]);
            });
        }
    }, [props.idValue, options]);

    const onChange = (newValue: string) => {
        const object = cache.find((o) => o.id === newValue);
        props.onChange && props.onChange(object ?? null);
    };

    useEffect(() => {
        if (props.idValue && props.value == undefined && value == null && props.onChange) {
            const found = cache.find((o) => o.id === props.idValue);
            if (found) props.onChange(found);
        }
    }, [options]);

    useEffect(() => {
        if (props.useUrlOverwrite && !props.disableFetch) {
            const param = params[props.useUrlOverwrite.param];
            if (param !== undefined)
                props.useUrlOverwrite.fetch(param).then((result) => {
                    changeUrlOverwrite(result);
                    props.onChange && props.onChange(result);
                });
        }
    }, [props.useUrlOverwrite]);

    useEffect(() => {
        const queryParam = searchParams.get(props.useQueryParam?.param ?? '');
        if (props.useQueryParam && value === null && queryParam !== null && !props.disableFetch) {
            props.useQueryParam.fetch(queryParam).then((result) => {
                props.onChange && props.onChange(result);
            });
        }
    }, [onQueryParamChange]);

    //TODO: this usePrevious is used because the useEffect triggered incorrectly (but should be investigated and fixed properly later when we have the time)
    const previous = usePrevious({ params: props.queryParams, index: index, account: store.currentAccount });
    useEffect(() => {
        if (
            ((previous == undefined ||
                JSON.stringify(previous.params) != JSON.stringify(props.queryParams) ||
                previous.index != index ||
                previous.account != store.currentAccount) &&
                !props.disableFetch &&
                store.currentAccount) ||
            !props.value
        ) {
            props.fetch({ index: index.toString(), size: pageSize, account_id: store.currentAccount, ...props.queryParams }).then((result) => {
                changeCount(result.count);
                if (index == 0) {
                    if (!props.mapOptions) {
                        changeOptions(mapResultSetToOptions(result.results, props.itemLabel));
                        changeCache(result.results);
                    } else {
                        changeMappedOptions(mapResultSetToMappedOptions(result.results, props.mapOptions, props.itemLabel));
                    }
                } else {
                    if (!props.mapOptions) {
                        changeOptions((options ?? []).concat(mapResultSetToOptions(result.results, props.itemLabel)));
                        changeCache((cache ?? []).concat(result.results));
                    } else {
                        changeMappedOptions((mappedOptions ?? []).concat(mapResultSetToMappedOptions(result.results, props.mapOptions, props.itemLabel)));
                        changeCache((cache ?? []).concat(result.results));
                    }
                }
            });
        }
    }, [index, props.queryParams, store.currentAccount]);

    useEffect(() => {
        if (props.mapOptions) {
            mapResultSetToMappedOptions(cache, props.mapOptions, props.itemLabel);
        } else {
            changeOptions(mapResultSetToOptions(cache, props.itemLabel));
        }
    }, [props.itemLabel]);

    const search = (inputValue: string, callback: (options: { value: any; label: string }[]) => void) => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        timer.current = setTimeout(() => {
            props.fetch({ search: inputValue, index: '0', size: pageSize, account_id: store.currentAccount, ...props.queryParams }).then((result) => {
                callback(mapResultSetToOptions(result.results, props.itemLabel));
                changeCache(result.results);
            });
        }, 500);
    };

    const onScrollToBottom = () => {
        if (count / parseInt(pageSize) > 1 && parseInt(pageSize) * index < count) {
            changeIndex(index + 1);
        }
    };

    const opt = useMemo(() => {
        if (props.subset) {
            if (props.subset.length === 0) return [];
            if (props.mapOptions) {
                const result: { label: string; options: { value: string; label: string }[] }[] = [];
                mappedOptions.forEach((o) => {
                    const current: { label: string; options: { value: string; label: string }[] } = { label: o.label, options: [] };
                    o.options.filter((op) => props.subset!.includes(op.value)).forEach((op) => current.options.push(op));
                    if (current.options.length > 0) result.push(current);
                });
                return result;
            } else {
                return options.filter((o) => props.subset!.includes(o.value));
            }
        } else return props.mapOptions ? mappedOptions : options;
    }, [props.mapOptions, props.subset, options, mappedOptions]);

    return {
        options: opt,
        value,
        onScrollToBottom,
        search,
        onChange,
        overwriteDisabled:
            urlOverwrite !== null || (!props.hideOnOneOption && options.length === 1 && !props.isClearable && props.required) || props.disableFetch,
        hide: props.hidden === true || (props.hideOnOneOption === true && options.length <= 1 && !props.isClearable)
    };
};

export default useObjectSelectInput;

function mapResultSetToOptions<T extends { id: string }>(result: T[], labels: (item: T) => string): { value: any; label: string }[] {
    return result.map((item) => ({
        value: item.id,
        label: labels(item) ?? item.id
    }));
}

function mapResultSetToMappedOptions<T extends { id: string }>(
    result: T[],
    mapKey: keyof T,
    labels: (item: T) => string
): { label: string; options: { value: any; label: string }[] }[] {
    const groupedItems: { label: string; options: { value: any; label: string }[] }[] = [];

    result.forEach((item) => {
        if (item[mapKey] && groupedItems.find((g) => g.label === item[mapKey])) {
            groupedItems.find((g) => g.label === item[mapKey])?.options.push({ value: item.id, label: labels(item) ?? item.id });
        } else {
            groupedItems.push({ label: item[mapKey] as string, options: [{ value: item.id, label: labels(item) ?? item.id }] });
        }
    });

    return groupedItems;
}

function usePrevious<T>(value: T) {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const onQueryParamChange = (value: string | null, key: string, setSearchParams: SetURLSearchParams) => {
    if (value && key) {
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            if (params.has(key)) {
                params.set(key, value);
            } else {
                params.append(key, value);
            }
            return params;
        });
    } else if (key) {
        setSearchParams((prev) => {
            const params = new URLSearchParams(prev);
            params.delete(key);
            return params;
        });
    }
};
