import { lazy } from 'react';
import { Route } from 'react-router-dom';

const Statistics = lazy(() => import('./statistics'));
const TotalFillRateDetail = lazy(() => import('./total-fill-rate/total-fill-rate-detail/total-fill-rate-detail.component'));
const FillRateByProductDetail = lazy(() => import('./fill-rate-by-product/fill-rate-by-product-detail/fill-rate-by-product-detail'));
const ActivityPerDayDetail = lazy(() => import('./activity-per-day/activity-per-day-detail/activity-per-day-detail.component'));
const ActivityPerHourDetail = lazy(() => import('./activity-per-hour/activity-per-hour-detail/activity-per-hour-detail.component'));
const FillRateBySpotDetail = lazy(() => import('./fill-rate-by-spot/fill-rate-by-spot-detail/fill-rate-by-spot-detail.component'));
const ConsumptionByProductDetail = lazy(() => import('./consumption-by-product/consumption-by-product-detail/consumption-by-product-detail'));
const ConsumptionByUserDetail = lazy(() => import('./consumption-by-user/consumption-by-user-detail/consumption-by-user-detail'));
const AllEquipmentStateDetail = lazy(() => import('./all-equipment-state/all-equipment-state-detail/all-equipment-state-detail'));
const AvailableEquipmentStateDetail = lazy(() => import('./available-equipment-state/available-equipment-state-detail/available-equipment-state-detail'));
const ConsumptionDetail = lazy(() => import('./consumption/consumption-detail/consumption-detail'));

export function StatisticRoutes() {
    return (
        <Route path='statistics'>
            <Route
                index
                element={<Statistics />}
            />
            <Route
                path='total-fill-rate'
                element={<TotalFillRateDetail allowBack />}
            />
            <Route
                path='fill-rate-by-product'
                element={<FillRateByProductDetail allowBack />}
            />
            <Route
                path='activity-per-day'
                element={<ActivityPerDayDetail allowBack />}
            />
            <Route
                path='activity-per-hour'
                element={<ActivityPerHourDetail allowBack />}
            />
            <Route
                path='fill-rate-by-spot'
                element={<FillRateBySpotDetail allowBack />}
            />
            <Route
                path='consumption-by-product'
                element={<ConsumptionByProductDetail allowBack />}
            />
            <Route
                path='consumption-by-user'
                element={<ConsumptionByUserDetail allowBack />}
            />
            <Route
                path='all-equipments'
                element={<AllEquipmentStateDetail allowBack />}
            />
            <Route
                path='available-equipments'
                element={<AvailableEquipmentStateDetail allowBack />}
            />
            <Route
                path='consumption'
                element={<ConsumptionDetail allowBack />}
            />
        </Route>
    );
}
