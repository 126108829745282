import { APIClient } from "@frontend/api-utils";
import { ImpexAction } from "@frontend/impex/types";

const endpoint = '/impex-api/v1'

export class ImpexEntityTypeEnumClient extends APIClient {
    public static async fetchEntityTypes(action: ImpexAction): Promise<string[]> {
        const response = await this.fetch(`${endpoint}/entity-types?action=${action}`);
        return this.handleResponse<string[]>(response);
    }
}
