import { Logger } from '@frontend/Logger';

import { AuthenticationError, IoTProvision, Token } from './models';

export class DriverAuthenticationClient {
    static async provision(provision: IoTProvision): Promise<Token> {
        Logger.debug('Sending provision request.');

        let signInUri = 'https://iot-api.' + process.env['NX_PUBLIC_API_DOMAIN'] + '/v1/provision';
        if (process.env['NX_PUBLIC_BUILD_ENV'] === 'edge') {
            signInUri = 'http://localhost:8019/v1/provision';
        }

        const response = await fetch(signInUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(provision)
        });
        Logger.debug('Login response code: ' + response.status);
        if (response.status !== 200) {
            throw new AuthenticationError('provision failed.', await response.json());
        }
        return response.json();
    }

    public static async authorizeUser(accountId: string, iotId: string, userId: string, token: Token): Promise<Token> {
        Logger.debug('Authorizing user request.');

        let signInUri = `https://iot-api.${process.env['NX_PUBLIC_API_DOMAIN']}/v1/accounts/${accountId}/iots/${iotId}/users/${userId}/authorize`;
        if (process.env['NX_PUBLIC_BUILD_ENV'] === 'edge') {
            signInUri = `http://localhost:8019/v1/accounts/${accountId}/iots/${iotId}/users/${userId}/authorize`;
        }

        const response = await fetch(signInUri, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                authorization: 'Bearer ' + token.jwt_token
            }
        });
        Logger.debug('Login response code: ' + response.status);
        if (response.status !== 200) {
            throw new AuthenticationError('provision failed.', await response.json());
        }
        return response.json();
    }
}
export default DriverAuthenticationClient;
