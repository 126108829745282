import { Account } from '@frontend/account/types';
import { useAccountRepository } from '@frontend/repository';
import { useEffect, useMemo, useState } from 'react';

interface ViewProps {
    onChangeMembership: (value: string | null) => void;
    accounts: Account[];
    selectedAccount: Account | null;
}

const useAccountMembership = (): ViewProps => {
    const accountInfo = useAccountRepository();
    const accounts = useMemo(() => accountInfo.store.availableAccountsInfo, [accountInfo.store]);
    const [selectedAccount, changeSelectedAccount] = useState<Account | null>(null);

    useEffect(() => {
        if (accountInfo.store.currentAccount) {
            changeSelectedAccount(accounts.find((acc) => acc.id === accountInfo.store.currentAccount) ?? null);
        }
    }, [accountInfo.store, accounts]);

    const onChangeMembership = (value: string | null) => {
        accountInfo.updateCurrentAccount(value);
    };

    return {
        onChangeMembership,
        selectedAccount,
        accounts
    };
};

export default useAccountMembership;
