import React, { useMemo } from 'react';

import { CheckboxInput } from '../checkbox-input/checkbox-input.component';
import { NumberInput } from '../number-input/number-input.component';
import { TextInput } from '../text-input/text-input.component';
import { DataTypeInputProps } from './data-type-input.component';

/**
 * @deprecated is moved to rendering (select)
 * @param props
 * @returns
 */
const useDataTypeInput = (props: DataTypeInputProps) => {
    const input = useMemo(() => {
        switch (props.type) {
            case 'string':
            case 'str':
            case 'color': // TODO: add color picker
                return (
                    <TextInput
                        {...props}
                        value={props.value as string}
                    />
                );
            case 'number':
            case 'int':
                return (
                    <NumberInput
                        {...props}
                        value={props.value as number}
                    />
                );
            case 'bool':
            case 'boolean':
                return (
                    <CheckboxInput
                        {...props}
                        value={props.value as boolean}
                    />
                );
            default:
                return <></>;
        }
    }, [props]);

    return { input };
};

export default useDataTypeInput;
