import { AsyncComponent } from '@frontend/elements';
import { lazy } from 'react';
import { Route } from 'react-router-dom';

const ConstraintCreate = lazy(() => import('./constraint-create/constraint-create.component'));
const ConstraintUpdate = lazy(() => import('./constraint-udpate/constraint-update.component'));

export function ConstraintRoutes() {
    return (
        <Route path='constraints'>
            <Route
                path='create'
                element={
                    <AsyncComponent>
                        <ConstraintCreate />
                    </AsyncComponent>
                }
            />
            <Route path=':constraintId'>
                <Route
                    path='update'
                    element={
                        <AsyncComponent>
                            <ConstraintUpdate />
                        </AsyncComponent>
                    }
                />
            </Route>
        </Route>
    );
}
