import { ProductAttribute } from '@frontend/product/types';
import { EventListener } from '@frontend/pub-sub';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';

export class ProductAttributeEventListener extends EventListener<ProductAttribute> {
    private static instance: ProductAttributeEventListener | null = null;
    private constructor(dispatch: ThunkDispatch<any, any, Action>) {
        super('product', dispatch);
    }

    static getInstance(dispatch: ThunkDispatch<any, any, Action>): ProductAttributeEventListener {
        if (this.instance == null) {
            this.instance = new ProductAttributeEventListener(dispatch);
        }
        return this.instance;
    }

    getInstance(dispatch: ThunkDispatch<any, any, Action>): EventListener<ProductAttribute> {
        return ProductAttributeEventListener.getInstance(dispatch);
    }

    protected onUpdate() {}
    protected onDelete() {}
    protected onCreate() {}
}
