import React from 'react';

import useTextInput from './text-input.controller';

const ID = 'text-input';
export interface TextInputProps {
    useConditionedStyling?: boolean;
    required?: boolean;
    valid?: boolean;
    isValidCallback?: (valid: boolean) => void;
    onChange?: (value: string) => void;
    onFocusChange?: () => void;
    value?: string | null;
    errorMessage?: React.ReactNode;
    label?: React.ReactNode;
    helpText?: React.ReactNode;
    autoFocus?: boolean;
    disabled?: boolean;
    submitted: boolean;
    id?: string;
    placeholder?: string;

    className?: string;
}

export const TextInput = (props: TextInputProps) => {
    const viewProps = useTextInput(props);

    function getErrorMessage(): React.ReactNode {
        if (props.required) {
            return <>{props.label} is required</>;
        }
        return <>An error occurred with field {props.label}</>;
    }

    return (
        <div
            id={ID}
            className={'form-group ' + props.className}>
            {props.label && (
                <label>
                    {props.label && props.required ? <span className='text-danger me-1'>&#9679;</span> : <></>}
                    {props.label}
                </label>
            )}
            <input
                className={viewProps.inputClass}
                autoFocus={props.autoFocus}
                id={props.id}
                value={props.value ?? undefined}
                type='text'
                placeholder={props.placeholder}
                onFocus={() => {
                    viewProps.changeTouched(true);
                    props.onFocusChange && props.onFocusChange();
                }}
                required={props.required}
                onChange={(event) => {
                    if (props.onChange) props.onChange(event.target.value);
                    viewProps.isValid();
                }}
                disabled={props.disabled}
            />
            {viewProps.valid === false && viewProps.touched === true ? (
                <span className='badge bg-gradient-danger mt-2'>
                    <small>{props.errorMessage ?? getErrorMessage()}</small>
                </span>
            ) : (
                <></>
            )}
        </div>
    );
};
